import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import * as FiIcons from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";

import Swal from "sweetalert2";
import { Button, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";

const VendorProductAdd = () => {
  const title = useRef();
  const price = useRef();
  const image = useRef();
  const thumbnail = useRef();
  const mainFile = useRef();
  const description = useRef();
  const keywords = useRef();
  const parentCategory = useRef();
  const subCategory = useRef();
  const fileType_id = useRef();
  const dimension = useRef();
  const length = useRef();
  const workMultipleImage = useRef();

  const [addInfo, setAddInfo] = useState([]);
  // Get Add Info
  const renderAddInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}vendor/product/add-info`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "vendor_access_token"
          )}`,
        },
      })
      .then((res) => {
        setAddInfo(res?.data);
      });
  };

  // console.log("add", addInfo);

  const [subCategoryValue, setSubcategoryValue] = useState([]);
  const childCategory = (e) => {
    for (let i = 0; i < addInfo?.data?.length; i++) {
      if (addInfo?.data[i]?.id == e.target.value) {
        setSubcategoryValue(addInfo?.data[i]);
      }
    }
  };
  // console.log("ddata", subCategoryValue);
  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  // thumbnail Preview
  const [files3, setFile3] = useState([]);
  const handleImgPreview3 = (e) => {
    let allfiles3 = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles3.push(e.target.files[i]);
    }
    if (allfiles3.length > 0) {
      setFile3(allfiles3);
    }
  };
  // file Preview
  const [files4, setFile4] = useState([]);
  const handleImgPreview4 = (e) => {
    let allfiles4 = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles4.push(e.target.files[i]);
    }
    if (allfiles4.length > 0) {
      setFile3(allfiles4);
    }
  };
  // Image Preview
  const [files2, setFile2] = useState([]);
  const handleImgPreview2 = (e) => {
    let allfiles2 = [];
    //console.log("allfiles2", allfiles2);
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles2.push(e.target.files[i]);
    }
    if (allfiles2.length > 0) {
      setFile2(allfiles2);
    }
  };

  // form submit to backend
  const storeData = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("title", title.current.value);

    formdata.append(
      "contentType_id",
      sessionStorage.getItem("setSelectedContentTypeId")
    );

    formdata.append("price", price.current.value);
    formdata.append("image", image.current.files[0]);
    formdata.append("thumbnail", thumbnail.current.files[0]);
    formdata.append("desc", description.current.value);
    formdata.append("keywords", keywords.current.value);
    formdata.append("cat_id", parentCategory.current.value);
    formdata.append("fileType_id", fileType_id.current.value);
    formdata.append("dimension", dimension.current.value);
    formdata.append("length", length.current.value);
    formdata.append("file", mainFile.current.files[0]);
    {
      subCategoryValue?.children?.length > 0 &&
        formdata.append("subcat_id", subCategory.current.value);
    }
    files2.forEach((item) => {
      formdata.append("images[]", item);
    });
    axios
      .post(`${BACKEND_BASE_URL}vendor/product/store`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem(
            "vendor_access_token"
          )}`,
        },
      })

      .then((response) => {
        setFile([]);
        setFile2([]);
        setFile3([]);
        setFile4([]);

        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
        } else {
          Swal.fire({
            icon: "warning",
            //text: response.data.errors.sku[0],
            confirmButtonColor: "#5eba86",
          });
        }
      });
  };

  useEffect(() => {
    renderAddInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header text-center">Upload an Item</div>
                  {/* <Link to="/admin/product">
                    <Button variant="success" size="sm">
                      View All Products &nbsp;
                    </Button>
                  </Link> */}
                </div>

                <Form onSubmit={storeData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* title */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Title
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Name"
                              ref={title}
                            />
                          </Form.Group>
                          {/* price */}
                          <Form.Group
                            as={Col}
                            md="3"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Price
                            </Form.Label>
                            <Form.Control
                              required
                              type="number"
                              placeholder="Price"
                              ref={price}
                            />
                          </Form.Group>
                          <p className="col-md-3 mt-4">+ $1 platform Fees</p>
                          {/* Keywords */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Keywords
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="keywords"
                              ref={keywords}
                            />
                          </Form.Group>

                          {/* Parent Category */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Parent Category
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              ref={parentCategory}
                              onChange={childCategory}
                            >
                              {addInfo?.data?.map((data) => (
                                <option value={data?.id} key={data.id}>
                                  {data?.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          {/* Sub Category */}
                          {subCategoryValue?.children?.length > 0 && (
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Sub Category
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                ref={subCategory}
                              >
                                <option value="0">Select Sub Category</option>
                                {subCategoryValue?.children?.map((data) => (
                                  <option value={data?.id} key={data.id}>
                                    {data?.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          )}
                          {/* image */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Image Preview &nbsp;{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Control
                              required
                              type="file"
                              ref={image}
                              onChange={handleImgPreview}
                            />

                            {files.map((file, key) => {
                              return (
                                <div key={key} className="Row">
                                  <span className="Filename">
                                    <img
                                      width={80}
                                      height={50}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                    />
                                  </span>
                                </div>
                              );
                            })}

                            <Form.Control.Feedback type="invalid">
                              Please choose an image
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* thumbnail */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Thumbnail &nbsp;{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Control
                              required
                              type="file"
                              ref={thumbnail}
                              onChange={handleImgPreview3}
                            />

                            {files3.map((file, key) => {
                              return (
                                <div key={key} className="Row">
                                  <span className="Filename">
                                    <img
                                      width={80}
                                      height={50}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                    />
                                  </span>
                                </div>
                              );
                            })}

                            <Form.Control.Feedback type="invalid">
                              Please choose an image
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* Description */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Description &nbsp;{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={6}
                              ref={description}
                            />
                          </Form.Group>

                          {/* Main File */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Main File &nbsp;{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Control
                              required
                              type="file"
                              ref={mainFile}
                              //onChange={handleImgPreview}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please choose main file
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* product gallery image */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Image Gallery
                            </Form.Label>

                            <Form.Control
                              // required
                              multiple
                              type="file"
                              ref={workMultipleImage}
                              onChange={handleImgPreview2}
                            />
                            <div className="row">
                              {files2.map((file, key) => {
                                return (
                                  <div key={key} className="col-md-3 ps-2 py-1">
                                    <span className="Filename">
                                      <img
                                        className="rounded-1"
                                        width={80}
                                        height={50}
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                      />
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Please choose an image
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* File type */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              File Type
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              ref={fileType_id}
                            >
                              <option value="0">Select FileType</option>
                              {addInfo?.fileType?.map((data) => (
                                <option value={data?.id} key={data?.id}>
                                  {data.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          {/* Dimension */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom02"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Dimension
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Dimension"
                              ref={dimension}
                            />
                          </Form.Group>
                          {/* Length */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom02"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Length
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Length"
                              ref={length}
                            />
                          </Form.Group>

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiIcons.FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VendorProductAdd;
