import React, { useState } from "react";
import "./AllSubCategory.css";
import horizontal from "../../Assets/Home/horizontal.png";
import { Accordion, Col, Form, Pagination, Row } from "react-bootstrap";
import Header from "../../Components/Common/Header/Header";
import Footer from "../../Components/Common/Footer/Footer";
import Slider from "react-slick";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { MdTune } from "react-icons/md";
import Header2 from "../../Components/Common/Header/Header2";
import { Link, useParams } from "react-router-dom";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../Utility/GlobalVariable/GlobalVariables";
import { useEffect } from "react";
import axios from "axios";
import {
  DotLoader,
  PulseLoader,
} from "../../Components/Custom Loader/CustomLoader";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FaChevronRight
      className={className}
      style={{
        ...style,
        // display: "block",
        // background: "#ffffff59",
        // color: "black",
        // width: 35,
        // height: 50,
        // zIndex: 9,
        // right: -15,
        // borderRadius: "10px",
        // padding: 12,
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FaChevronLeft
      className={className}
      style={{
        ...style,
        // display: "block",
        // background: "#ffffff59",
        // color: "black",
        // width: 35,
        // height: 50,
        // zIndex: 9,
        // left: -15,
        // borderRadius: "10px",
        // padding: 12,
      }}
      onClick={onClick}
    />
  );
}

const AllSubCategory = () => {
  // const { slug } = useParams();
  // console.log("slug", slug);

  const [active, setActive] = useState(1);
  let items = [];
  for (let number = 1; number <= 8; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={() => setActive(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  //all sub cat data
  const [allSubCAt, setAllSubCatData] = useState([]);
  // Get All graphics Info
  const renderAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}content-subcategory/all`)
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setAllSubCatData(res.data?.data);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    renderAllInfo();
  }, []);

  return (
    <>
      <Header2 />
      <div className="allSub">
        <div className="product">
          {/* <div className="filter py-4">
            <div className="custom_container ">
              <Row className="justify-content-between align-items-center container">
                <Col xl={3} lg={4} md={4} xs={6} className="">
                  <div
                    className="filter_icon pointer"
                    onClick={() => setFliterActive(!fliterActive)}
                  >
                    <MdTune />
                  </div>
                </Col>
                <Col xl={2} lg={4} md={4} xs={6} className="">
                  <div className="select_filter">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={filterData}
                    >
                      <option value="" disabled selected>
                        Sort by
                      </option>
                      <option value="0">Popular</option>
                      <option value="1">Most Recents</option>
                      <option value="2">Most Downloads</option>
                      <option value="3">Featured</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
            </div>
          </div> */}
          {/* {fliterActive && (
            <div className="custom_container">
              <div className="content_filter_wrapper py-3">
                <div className="content">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Price Range</Accordion.Header>
                      <Accordion.Body>
                        <ul className="ls_none ps-0">
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label="$2.00 - $19.00"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label="$2.00 - $19.00"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label="$2.00 - $19.00"
                            />
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="content">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>File Type</Accordion.Header>
                      <Accordion.Body>
                        <ul className="ls_none ps-0">
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label=".PSD"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label=".EPS"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label=".JPG"
                            />
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="content">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Software Type</Accordion.Header>
                      <Accordion.Body>
                        <ul className="ls_none ps-0">
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label="Active Campaign"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label="Adobe Muse"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label="Adobe XD"
                            />
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="content">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Properties</Accordion.Header>
                      <Accordion.Body>
                        <ul className="ls_none ps-0">
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label="Layered"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label="Vector"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label="Tileable"
                            />
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="content">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Price Range</Accordion.Header>
                      <Accordion.Body>
                        <ul className="ls_none ps-0">
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label="$2.00 - $19.00"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label="$2.00 - $19.00"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label="$2.00 - $19.00"
                            />
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          )} */}
          <div className="sesction_pt  sesction_pb">
            <div className="custom_container ">
              <div className="grid ">
                {/* jodi data pay */}
                {!isLoading &&
                  !error &&
                  allSubCAt?.length > 0 &&
                  allSubCAt?.map((allSubCAt, i) => (
                    <div className="image" key={allSubCAt?.id}>
                      <Link to={`/content/${allSubCAt.slug}`}>
                        {files.length == 0 &&
                          (allSubCAt != null && allSubCAt?.image != null ? (
                            <div>
                              <img
                                style={{
                                  // height: "162.5px",
                                  // width: "530px",
                                  objectFit: "cover",
                                }}
                                // className="img-fluid"
                                src={`${fileUrl}${allSubCAt?.image}`}
                                alt=""
                              />
                            </div>
                          ) : (
                            <img
                              className="me-2 cl_medium_grey"
                              // style={{ width: "300px", height: "200px" }}
                              src=""
                              alt=""
                            />
                          ))}
                        <h6 className="font_size_25 font_montserrat cl_dark_hover underline_none pt-2">
                          <strong>{allSubCAt?.name}</strong>
                        </h6>
                      </Link>
                    </div>
                  ))}
              </div>
              {/* jodi data na pay */}
              {isLoading && !error && (
                <>
                  <div className="d-flex justify-content-center pb-5 pe-5 me-5">
                    <PulseLoader />
                    {/* <DotLoader /> */}
                  </div>
                </>
              )}

              {!isLoading && !error && allSubCAt?.length < 1 && (
                <>
                  <div className="text-center text-danger">
                    <h1>No data Found</h1>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AllSubCategory;
