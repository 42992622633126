import React from "react";
// import VendorContent from "./VendorContent";
import "./Vendor.css";
import VendorContent from "./VendorContent";

const Vendor = () => {
  return (
    <div className="position-relative">
      <VendorContent />
    </div>
  );
};

export default Vendor;
