import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { ToastSuccess } from "../../../Components/ToastAlert";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../../Utility/GlobalVariable/GlobalVariables";
// import { BACKEND_BASE_URL } from "../Component/GlobalVariables";
// import { PassInputFieldFormControl } from "../Component/PassInputField";
// import { ToastSuccess } from "../Component/ToastAlert";

const UserProfileEdit = () => {
  const name = useRef();
  // const email = useRef();
  const phone = useRef();
  // const current_password = useRef();
  // const new_password = useRef();
  // const confirm_password = useRef();
  const address = useRef();
  const profile_photo = useRef();

  // Fetch all User Info
  // const [userInfo, setUserInfo] = useState();

  const [error, setError] = useState([]);
  const [message, setMessage] = useState([]);

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  const [profileInfo, setProfileInfo] = useState([]);
  // Get user Info
  const renderUserInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}user/profile`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "user_access_token"
          )}`,
        },
      })
      .then((res) => {
        setProfileInfo(res.data?.data);
      });
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();

    formdata.append("name", name.current.value);
    formdata.append("phone", phone.current.value);
    // formdata.append("current_password", current_password.current.value);
    // formdata.append("new_password", new_password.current.value);
    // formdata.append("confirm_password", confirm_password.current.value);
    formdata.append("address", address.current.value);

    if (profile_photo.current.files[0]) {
      formdata.append("profile_photo", profile_photo.current.files[0]);
    }

    //user profile update api
    axios
      .post(`${BACKEND_BASE_URL}user/profile-update`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem(
            "user_access_token"
          )}`,
        },
      })

      .then((response) => {
        if (response.data?.status === 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();
          renderUserInfo();
          // setModalShow(false);
        } else {
          setError(response.data?.errors);
          setMessage(response.data?.message);
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    renderUserInfo();
  }, []);

  return (
    <div className="user_dashboard_content p-4 p-lg-5">
      <h1 className="mb-5 ms-3 ms-lg-0">Edit Profile</h1>

      <div className="edit_profile">
        <Form autoComplete="off" onSubmit={storeData}>
          <Form.Group className="user_dashboard_content mb-4">
            <Form.Label className="">Username</Form.Label>
            <Form.Control
              className=""
              autoComplete="off"
              defaultValue={profileInfo?.name}
              ref={name}
            />
          </Form.Group>

          <Form.Group className="user_dashboard_content mb-4">
            <Form.Label className="mb-2">Email</Form.Label>
            <Form.Control
              readOnly
              className=""
              type="email"
              placeholder="Email"
              // disabled
              defaultValue={profileInfo?.email}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label className="mb-2">Profile Photo</Form.Label>
            <Form.Control
              type="file"
              ref={profile_photo}
              onChange={handleImgPreview}
            />
            {files.map((file, key) => {
              return (
                <div key={key} className="Row">
                  <span className="Filename">
                    <img
                      width={80}
                      height={50}
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                    />
                  </span>
                </div>
              );
            })}
            {files.length === 0 &&
            profileInfo != null &&
            profileInfo?.profile_photo != null ? (
              <img
                width={80}
                src={`${fileUrl}${profileInfo?.profile_photo}`}
                alt={profileInfo.name}
                className="img-thumbnail me-2 my-2"
              />
            ) : (
              <img
                // src={require("../../../Assets/avatar.png")}
                alt=""
                className="img-fluid"
                width={80}
              />
            )}
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label className="mb-2">
              Address: <span className="text-danger">*</span>
            </Form.Label>

            <Form.Control
              as="textarea"
              type="textarea"
              rows={3}
              placeholder="Enter your address"
              className=""
              defaultValue={profileInfo?.address}
              ref={address}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label className="mb-2">
              Phone Number: <span className="text-danger">*</span>
            </Form.Label>

            <Form.Control
              placeholder="Phone"
              aria-label="Phone"
              className=""
              defaultValue={profileInfo?.phone}
              ref={phone}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label className=" mb-2">Country or region</Form.Label>
            <Form.Control
              className=""
              type="text"
              placeholder="Bangladesh"
              defaultValue="Bangladesh"
            />
          </Form.Group>

          <div className="save_profile_btn mt-4 mb-5 flex_end">
            {/* <button className="me-3">Cancel</button> */}
            {/* <button onClick={() => {}}>Save Profile</button> */}
            <button type="submit" className="me-3">
              Save Profile
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UserProfileEdit;
