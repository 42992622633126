import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import * as FiIcons from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";

import Swal from "sweetalert2";
import { Button, Col, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../../Utility/GlobalVariable/GlobalVariables";

const AdminProductEdit = () => {
  const { content_id } = useParams();
  const title = useRef();
  const price = useRef();
  const image = useRef();
  const description = useRef();
  const keywords = useRef();
  const parentCategory = useRef();
  const subCategory = useRef();
  const workMultipleImage = useRef();

  var selectedId;

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };
  // Image Preview
  const [files2, setFile2] = useState([]);
  const handleImgPreview2 = (e) => {
    let allfiles2 = [];
    console.log("allfiles2", allfiles2);
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles2.push(e.target.files[i]);
    }
    if (allfiles2.length > 0) {
      setFile2(allfiles2);
    }
  };

  const [addInfo, setAddInfo] = useState([]);
  // Get Add Info
  const renderAddInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/product/add-info`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setAddInfo(res?.data);
      });
  };
  // Initial table data
  const [singleData, setSingleData] = useState([]);
  // Get Single Info
  const renderSingleInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/product/show-single/${content_id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setSingleData(res?.data?.data);
        setEditSingleData(res?.data?.data);
      });
  };
  const [subCategoryValue, setSubcategoryValue] = useState([]);
  const childCategory = (e) => {
    for (let i = 0; i < addInfo?.data?.length; i++) {
      if (addInfo?.data[i]?.id == e.target.value) {
        setSubcategoryValue(addInfo?.data[i]);
      }
    }
  };

  // Dynamic input fields edit

  const [editInputFields, setEditInputFields] = useState([]);
  const [editSingleData, setEditSingleData] = useState([]);

  const [inputFields, setInputFields] = useState([
    { fileType: "", dimension: "", length: "", file: "" },
  ]);

  const handleFormChangeEdit = (index, event) => {
    let data = [...editInputFields];
    data[index][event.target.name] = event.target.value;
    setEditInputFields(data);
    setInputFields(data);
  };

  const handleFileChangeEdit = (index, event) => {
    let data = [...inputFields];
    data[index][event?.target?.name] = event?.target?.files[0];
    setEditInputFields(data);
    setInputFields(data);
  };

  const addFieldsEdit = () => {
    let addnewfield = { descTitle: "", description: "" };
    setEditInputFields([...editInputFields, addnewfield]);
    setInputFields([...editInputFields, addnewfield]);
  };

  const removeFieldsEdit = (index) => {
    let data = [...editInputFields];
    data.splice(index, 1);
    setEditInputFields(data);
    setInputFields(data);
  };

  // Updated data to backend
  const updatedData = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("title", title.current.value);
    formdata.append("price", price.current.value);
    if (image.current.files[0]) {
      formdata.append("image", image.current.files[0]);
    }
    formdata.append("desc", description.current.value);
    formdata.append("keywords", keywords.current.value);
    formdata.append("cat_id", parentCategory.current.value);
    {
      subCategoryValue?.children?.length > 0 &&
        formdata.append("subcat_id", subCategory.current.value);
    }
    files2.forEach((item) => {
      formdata.append("images[]", item);
    });

    inputFields.forEach((item) => {
      formdata.append("fileType_id[]", item.fileType);
      formdata.append("dimension[]", item.dimension);
      formdata.append("length[]", item.length);
      formdata.append("file[]", item.file);
    });

    await axios
      .post(
        `${BACKEND_BASE_URL}admin/product/update/${singleData?.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${sessionStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )

      .then((response) => {
        setFile([]);
        setFile2([]);

        if (response.data?.status == 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
          renderSingleInfo();
        } else {
          Swal.fire({
            icon: "warning",
            text: response.data.errors.sku[0],
            confirmButtonColor: "#5eba86",
          });
        }
      });
    e.preventDefault();
  };
  let editnewfield = [];
  useEffect(() => {
    editSingleData?.attribute?.forEach((data) => {
      editnewfield.push({
        dimension: data?.dimension,
        length: data?.length,
        fileType: data?.file_type.name,
        file: data?.file,
        // file_type: data?.file_type,
      });
    });
    setEditInputFields(...editInputFields, editnewfield);
  }, [editSingleData]);

  useEffect(() => {
    renderAddInfo();
    renderSingleInfo();
    // childCategory();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">Update Product</div>
                  <Link to="/admin/product">
                    <Button variant="success" size="sm">
                      View All Products &nbsp;
                    </Button>
                  </Link>
                </div>

                <Form onSubmit={updatedData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* title */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Title
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Name"
                              ref={title}
                              defaultValue={singleData?.title}
                            />
                          </Form.Group>
                          {/* price */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Price
                            </Form.Label>
                            <Form.Control
                              required
                              type="number"
                              placeholder="Price"
                              ref={price}
                              defaultValue={singleData?.price}
                            />
                          </Form.Group>
                          {/* Keywords */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Keywords
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="keywords"
                              ref={keywords}
                              defaultValue={singleData?.keywords}
                            />
                          </Form.Group>

                          {/* Parent Category */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Parent Category
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              ref={parentCategory}
                              onChange={childCategory}
                            >
                              {addInfo?.data?.map((data) => (
                                <option
                                  value={data?.id}
                                  key={data.id}
                                  selected={
                                    data?.id == singleData?.categories?.id
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {data?.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          {/* Sub Category */}
                          {subCategoryValue?.children?.length > 0 && (
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Sub Category
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                ref={subCategory}
                              >
                                <option value="0">Select Sub Category</option>
                                {subCategoryValue?.children?.map((data) => (
                                  <option
                                    value={data?.id}
                                    key={data.id}
                                    selected={
                                      data?.id == singleData?.sub_categories?.id
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {" "}
                                    {data?.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          )}
                          {/* image */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Image &nbsp;{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Control
                              required
                              type="file"
                              ref={image}
                              onChange={handleImgPreview}
                            />

                            {files.map((file, key) => {
                              return (
                                <div key={key} className="Row">
                                  <span className="Filename">
                                    <img
                                      width={80}
                                      height={50}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                    />
                                  </span>
                                </div>
                              );
                            })}
                            {files.length == 0 && (
                              <img
                                className="img-thumbnail mt-1"
                                width={80}
                                height={50}
                                src={`${fileUrl}${singleData?.image}`}
                                alt=""
                                name="img"
                              />
                            )}

                            <Form.Control.Feedback type="invalid">
                              Please choose an image
                            </Form.Control.Feedback>
                          </Form.Group>
                          {/* Description */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Description &nbsp;{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={6}
                              ref={description}
                              defaultValue={singleData?.desc}
                            />
                          </Form.Group>

                          {/* product gallery image */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Image Gallery
                            </Form.Label>

                            <Form.Control
                              // required
                              multiple
                              type="file"
                              ref={workMultipleImage}
                              onChange={handleImgPreview2}
                            />
                            <div className="row">
                              {files2.map((file, key) => {
                                return (
                                  <div key={key} className="col-md-3 ps-2 py-1">
                                    <span className="Filename">
                                      <img
                                        className="rounded-1"
                                        width={80}
                                        height={50}
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                      />
                                    </span>
                                  </div>
                                );
                              })}
                              <div style={{ height: "50", width: "80" }}>
                                {files2.length == 0 &&
                                  singleData?.gallery?.map((data, i) => (
                                    <img
                                      key={i}
                                      className=" mt-2 me-2"
                                      width={80}
                                      height={50}
                                      src={`${fileUrl}${data?.images}`}
                                      alt=""
                                      name="img"
                                    />
                                  ))}
                              </div>
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Please choose an image
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* Add more button */}
                          {editInputFields?.map((input, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className="p-3 mb-2"
                                  style={{
                                    border: "1px solid #d1d1d1",
                                    backgroundColor: "#f3f3f3",
                                  }}
                                >
                                  {/* file type */}
                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="validationCustom01"
                                    className="mb-3"
                                  >
                                    <Form.Label className="label fw-bold">
                                      FileType
                                    </Form.Label>
                                    <Form.Select
                                      aria-label="Default select example"
                                      name="fileType"
                                      value={input.fileType}
                                      onChange={(event) =>
                                        handleFormChangeEdit(index, event)
                                      }
                                    >
                                      <option value="0">Select FileType</option>
                                      {addInfo?.data_fileType?.map(
                                        (data_fileType) => (
                                          <>
                                            {editSingleData?.attribute?.map(
                                              (attribute) => {
                                                if (
                                                  attribute.fileType_id ==
                                                  data_fileType?.id
                                                ) {
                                                  selectedId =
                                                    attribute.fileType_id;
                                                }
                                                return (
                                                  attribute.fileType_id ==
                                                    data_fileType?.id && (
                                                    <option>
                                                      {data_fileType.name}
                                                    </option>
                                                  )
                                                );
                                              }
                                            )}
                                            {selectedId !=
                                              data_fileType?.id && (
                                              <option value={data_fileType?.id}>
                                                {data_fileType?.name}
                                              </option>
                                            )}
                                          </>
                                        )
                                      )}
                                    </Form.Select>
                                  </Form.Group>

                                  {/* Description */}
                                  <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId="validationCustom02"
                                    className="mb-3"
                                  >
                                    <Form.Label className="label fw-bold">
                                      Dimension
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Dimension"
                                      name="dimension"
                                      value={input.dimension}
                                      onChange={(event) =>
                                        handleFormChangeEdit(index, event)
                                      }
                                    />
                                  </Form.Group>
                                  {/* length */}
                                  <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId="validationCustom02"
                                    className="mb-3"
                                  >
                                    <Form.Label className="label fw-bold">
                                      Length
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Length"
                                      name="length"
                                      value={input.length}
                                      onChange={(event) =>
                                        handleFormChangeEdit(index, event)
                                      }
                                    />
                                  </Form.Group>
                                  {/* file */}
                                  <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId="validationCustom01"
                                    className="mb-3"
                                  >
                                    <Form.Label className="label fw-bold">
                                      File &nbsp;{" "}
                                      <span className="text-danger">*</span>
                                    </Form.Label>

                                    <Form.Control
                                      required
                                      type="file"
                                      name="file"
                                      onChange={(event) =>
                                        handleFileChangeEdit(index, event)
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please choose a File
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <div>Old File: {input.file}</div>

                                  <button
                                    onClick={() => removeFieldsEdit(index)}
                                    className="remove_btn"
                                  >
                                    <AiOutlineClose size="1.5rem" />
                                  </button>
                                </div>
                              </>
                            );
                          })}
                          <div className="flex_end">
                            <button
                              type="button"
                              onClick={addFieldsEdit}
                              className="add_more_btn"
                            >
                              Add More
                            </button>
                          </div>
                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiIcons.FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminProductEdit;
