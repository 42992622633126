import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Nav,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Header2.css";
import logo_long from "../../../Assets/Common/logo_long1.png";
import logo_short from "../../../Assets/Common/logo_short1.png";

import { FaHeart } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import axios from "axios";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../../Utility/GlobalVariable/GlobalVariables";
import { BiSearch } from "react-icons/bi";

const Header2 = () => {
  const search = useRef();
  const navigate = useNavigate();
  //get vendor info
  const renderVendor = async () => {
    if (sessionStorage.getItem("vendor_access_token")) {
      await axios
        .get(`${BACKEND_BASE_URL}vendor/profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(
              "vendor_access_token"
            )}`,
          },
        })
        .then((res) => {
          navigate("/vendor");
        });
    } else {
      navigate("/vendor/login");
    }
  };
  // Get profile Info
  const renderProfileInfo = async () => {
    if (sessionStorage.getItem("user_access_token")) {
      await axios
        .get(`${BACKEND_BASE_URL}user/profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(
              "user_access_token"
            )}`,
          },
        })
        .then((res) => {
          navigate("/user");
        });
    } else if (sessionStorage.getItem("admin_access_token")) {
      await axios
        .get(`${BACKEND_BASE_URL}admin/profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(
              "admin_access_token"
            )}`,
          },
        })
        .then((res) => {
          navigate("/admin");
        });
    } else if (sessionStorage.getItem("vendor_access_token")) {
      await axios
        .get(`${BACKEND_BASE_URL}vendor/profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(
              "vendor_access_token"
            )}`,
          },
        })
        .then((res) => {
          navigate("/vendor");
        });
    } else {
      navigate("/user/login");
    }
  };
  // Initial wishlist count data
  const [wishList_count, setWishListCount] = useState();
  // Get wishlist Count Info
  const renderWishListInfo = async () => {
    // CHECK IF USER IS LOGGED IN OR NOT
    if (!sessionStorage.getItem("LOGGED_IN_USER_ID")) {
      await axios
        .get(
          `${BACKEND_BASE_URL}wish-list/view-items/${sessionStorage.getItem(
            "USER_TEMP_ID"
          )}`
        )
        .then((res) => {
          setWishListCount(res?.data?.wishList_count);
        });
    } else {
      await axios
        .get(
          `${BACKEND_BASE_URL}wish-list/view-items/${sessionStorage.getItem(
            "LOGGED_IN_USER_ID"
          )}`
        )
        .then((res) => {
          setWishListCount(res?.data?.wishList_count);
        });
    }
  };
  // Initial nav bar data
  const [navData, setNavData] = useState([]);
  // Get All Info
  const renderNavInfo = async () => {
    await axios.get(`${BACKEND_BASE_URL}navbar-items`).then((res) => {
      setNavData(res?.data?.data);
    });
  };

  const [contentCatId, setContentCatId] = useState([0]);
  const contentCatName = async (e) => {
    setContentCatId(e.target.value);
    // console.log("val", e.target.value);
  };
  // console.log("cc", contentCatId);

  // Initial content type data
  const [contentCat, setContentCat] = useState([]);
  // Get content type Info
  const renderContentType = async () => {
    await axios.get(`${BACKEND_BASE_URL}content-category`).then((res) => {
      setContentCat(res?.data?.data);
    });
  };
  const [searchData, setSearchedData] = useState([]);
  const searchedData = (e) => {
    const formdata = new FormData();
    formdata.append("search", search.current.value);
    axios
      .post(`${BACKEND_BASE_URL}search-product/${contentCatId}`, formdata, {})
      .then((response) => {
        if (response.data?.status == 200) {
          setSearchedData(response?.data?.data);

          // data transfer with navigation
          navigate("/searched-product", { state: response?.data?.data });

          // navigate("/searched-product", { searchedData: response?.data?.data });
          e.target.reset();
          //  renderAllInfo();
          // setModalShow(false);
        }
      });
    e.preventDefault();
  };

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };
  useEffect(() => {
    renderNavInfo();
    renderWishListInfo();
    renderContentType();
  }, [sessionStorage.getItem("wishList_count")]);
  return (
    <>
      <div className="bg_light_dark d-none d-lg-block p-2">
        <div className="container d-flex justify-content-between">
          {/* flex-grow-1 */}
          <Nav className="head">
            {navData?.map((navData, i) => (
              <Nav.Link
                href={navData?.link}
                className="ps-0 pe-4"
                key={navData.id}
              >
                {files.length == 0 &&
                  (navData != null && navData?.image != null ? (
                    <img
                      className="me-2 cl_medium_grey"
                      src={`${fileUrl}${navData?.image}`}
                      alt=""
                      style={{ width: "21px", height: "20px" }}
                    />
                  ) : (
                    <img className="me-2 cl_medium_grey" src="" alt="Logo" />
                  ))}

                <span className="underline_none cl_medium_grey_header2 fw_500 ">
                  {navData?.name}
                </span>
              </Nav.Link>
            ))}
          </Nav>
          {/* flex-grow-1 */}
          <Nav className="">
            <Nav.Link href="/wishlist" className="ps-0 pe-0">
              <FaHeart className="me-2 cl_medium_grey_header2" size={20} />
              <span className="underline_none cl_medium_grey_header2 fw_500">
                ({wishList_count}) Favorites
              </span>
            </Nav.Link>
            {/* <Nav.Link href="#action2" className="px-2">
              <FaShoppingCart
                className="me-2 cl_medium_grey_header2"
                size={20}
              />
              <span className="underline_none cl_medium_grey_header2 fw_500">
                Cart
              </span>
            </Nav.Link> */}

            <InputGroup className="d-lg-none">
              <DropdownButton
                variant="outline-secondary"
                title="All Products"
                id="input-group-dropdown-1"
                className="border-0"
              >
                <Dropdown.Item href="#">Action</Dropdown.Item>
                <Dropdown.Item href="#">Another action</Dropdown.Item>
                <Dropdown.Item href="#">Something else here</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#">Separated link</Dropdown.Item>
              </DropdownButton>
              <Form.Control
                aria-label="Text input with dropdown button"
                placeholder="Search all templates"
              />
            </InputGroup>
          </Nav>
        </div>
      </div>
      <Navbar
        expand="lg"
        variant="dark"
        className=" header2 bg-light"
        sticky="top"
      >
        <Container className="custom-container flex-column align-items-start Header2">
          {/* main nav */}

          <div className=" d-flex w-100 align-items-center order-2 pt-2 Header2_down_part">
            <Navbar.Toggle
              aria-controls="offcanvasNavbar-expand-lg"
              className=""
            />
            <Navbar.Brand
              href="#"
              className="cl_white logo order-2 order-lg-1 ms-auto m-lg-0"
              as={Link}
              to="/"
            >
              <picture>
                <source media="(min-width:1350px)" srcSet={logo_long} />
                {/* <source media="(min-width:465px)" srcset="https://images.unsplash.com/photo-1661956602116-aa6865609028?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80" /> */}
                <img src={logo_short} alt="mySvgImage" />
              </picture>
            </Navbar.Brand>
            <ul className="justify-content-end p-0 m-0 ls_none ms-auto order-1 order-lg-2 header_desktop_list  ">
              <Form onSubmit={searchedData} className="search_dropdown">
                <InputGroup className="text-dark">
                  <Form.Select
                    aria-label="Default select example"
                    onChange={contentCatName}
                    style={{
                      backgroundColor: "transparent",
                      maxWidth: "210px",
                      fontWeight: "700",
                      color: "#2c2d2e",
                      boxShadow: "none",
                      borderTop: 0,
                      borderBottom: 0,
                      borderLeft: 0,
                      borderRight: 0,
                    }}
                  >
                    <option value="0">All Contents</option>
                    {contentCat?.map((contentCat, i) => (
                      <option value={contentCat?.id} key={contentCat?.id}>
                        {contentCat.name}
                      </option>
                    ))}
                  </Form.Select>
                  <InputGroup.Text className="border_none">
                    <BiSearch size="25px" />
                  </InputGroup.Text>

                  {/* <DropdownButton
                  variant="outline-secondary"
                  title="All Products"
                  id="input-group-dropdown-1"
                  className=""
                >
                  <Dropdown.Item href="#">Action</Dropdown.Item>
                  <Dropdown.Item href="#">Another action</Dropdown.Item>
                  <Dropdown.Item href="#">Something else here</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#">Separated link</Dropdown.Item>
                </DropdownButton> */}

                  <Form.Control
                    aria-label="Text input with dropdown button"
                    placeholder="Search all templates"
                    ref={search}
                  />
                </InputGroup>
              </Form>
            </ul>
          </div>

          {/* mobile offnavs */}
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
                Code Pixel
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="justify-content-between">
              {/* <Nav className="">
                {navData?.map((navData, i) => (
                  <Nav.Link
                    href={navData?.link}
                    className="ps-0 pe-4"
                    key={navData.id}
                  >
                    {files.length == 0 &&
                      (navData != null && navData?.image != null ? (
                        <img
                          className="me-2 cl_medium_grey"
                          src={`${fileUrl}${navData?.image}`}
                          alt=""
                          style={{ width: "21px", height: "20px" }}
                        />
                      ) : (
                        <img
                          className="me-2 cl_medium_grey"
                          src=""
                          alt="Logo"
                        />
                      ))}

                    <span className="underline_none cl_medium_grey fw_500">
                      {navData?.name}
                    </span>
                  </Nav.Link>
                ))}
               
              </Nav>
             
              <Nav className="">
                <Nav.Link href="/wishlist" className="ps-0 pe-4">
                  <FaHeart className="me-2 cl_medium_grey" size={20} />
                  <span className="underline_none cl_medium_grey fw_500">
                    ({wishList_count}) Favorites
                  </span>
                </Nav.Link>
                <Nav.Link href="#action2" className="ps-0">
                  <FaShoppingCart className="me-2 cl_medium_grey" size={20} />
                  <span className="underline_none cl_medium_grey fw_500">
                    Cart
                  </span>
                </Nav.Link>

                <InputGroup className="d-lg-none">
                  <DropdownButton
                    variant="outline-secondary"
                    title="All Products"
                    id="input-group-dropdown-1"
                    className="border-0"
                  >
                    <Dropdown.Item href="#">Action</Dropdown.Item>
                    <Dropdown.Item href="#">Another action</Dropdown.Item>
                    <Dropdown.Item href="#">Something else here</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#">Separated link</Dropdown.Item>
                  </DropdownButton>
                  <Form.Control
                    aria-label="Text input with dropdown button"
                    placeholder="Search all templates"
                  />
                </InputGroup>
              </Nav> */}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default Header2;
