import axios from "axios";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { Form } from "react-bootstrap";
import { PassInputFieldFormControl } from "../../../Components/PassInputField";
import { ToastSuccess } from "../../../Components/ToastAlert";
import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";
// import { BACKEND_BASE_URL } from "../Component/GlobalVariables";
// import { PassInputFieldFormControl } from "../Component/PassInputField";
// import { ToastSuccess } from "../Component/ToastAlert";

const UserPassChange = () => {
  const current_password = useRef();
  const new_password = useRef();
  const confirm_password = useRef();

  const [error, setError] = useState([]);

  const storeData = (e) => {
    const formdata = new FormData();

    if (current_password.current.value) {
      formdata.append("current_password", current_password.current.value);
      formdata.append("new_password", new_password.current.value);
      formdata.append("confirm_password", confirm_password.current.value);
    }

    axios
      .post(`${BACKEND_BASE_URL}user/change-password`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem(
            "user_access_token"
          )}`,
        },
      })

      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();
        } else {
          setError(response.data?.errors);
        }
      });
    e.preventDefault();
  };

  return (
    <div className="user_dashboard_content p-4 p-lg-5">
      <h1 className="mb-5 ms-3 ms-lg-0">Change password</h1>

      <div className="edit_password">
        <Form onSubmit={storeData} autoComplete="off">
          <Form.Group className="user_dashboard_content mb-4">
            <Form.Label className="mb-2">
              Current Password: <span className="text-danger">*</span>
            </Form.Label>
            <PassInputFieldFormControl
              inputRef={current_password}
              placeholderValue="Current Password"
              eyeIconStyle="mb-1"
            />
            <div className="mb-2">
              <small className="text-danger">{error?.current_password}</small>
            </div>
          </Form.Group>

          <Form.Group className="user_dashboard_content mb-4">
            <Form.Label className="mb-2">
              New Password: <span className="text-danger">*</span>
            </Form.Label>
            <PassInputFieldFormControl
              inputRef={new_password}
              placeholderValue="New Password"
              className=""
              eyeIconStyle="mb-1"
            />
            <div className="mb-2">
              <small className="text-danger">{error?.new_password}</small>
            </div>
          </Form.Group>

          <Form.Group className="user_dashboard_content mb-4">
            <Form.Label className="mb-2">
              Confirm Password: <span className="text-danger">*</span>
            </Form.Label>

            <PassInputFieldFormControl
              inputRef={confirm_password}
              placeholderValue="Confirm Password"
              eyeIconStyle="mb-1"
            />

            <div className="mb-2">
              <small className="text-danger">{error?.confirm_password}</small>
            </div>
          </Form.Group>

          <div className="save_profile_btn mt-4 mb-5 d-flex justify-content-center justify-content-lg-end">
            {/* <button className="me-3">Cancel</button> */}
            {/* <button onClick={() => {}}>Set new Password</button> */}
            <button type="submit">Set new Password</button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UserPassChange;
