import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import logo_long from "../../../Assets/Common/logo_long.png";
import logo_short from "../../../Assets/Common/logo_short.png";
import { FaVideo } from "react-icons/fa";
import { FaMusic } from "react-icons/fa";
import { BiUser } from "react-icons/bi";
import { FaChevronCircleDown } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { FaFileImage } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { SiAdobeaftereffects } from "react-icons/si";
import { SiAdobepremierepro } from "react-icons/si";
import { SiKinopoisk } from "react-icons/si";
import { MdMotionPhotosOn } from "react-icons/md";
import getCookie from "../../Cookie/Get.Cookie";
import axios from "axios";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../../Utility/GlobalVariable/GlobalVariables";

const Header = () => {
  const navigate = useNavigate();
  //get vendor info
  const renderVendor = async () => {
    if (sessionStorage.getItem("vendor_access_token")) {
      await axios
        .get(`${BACKEND_BASE_URL}vendor/profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(
              "vendor_access_token"
            )}`,
          },
        })
        .then((res) => {
          navigate("/vendor");
        });
    } else {
      navigate("/vendor/login");
    }
  };
  // Get profile Info
  const renderProfileInfo = async () => {

    if (getCookie("user_access_token")) {
      await axios
        .get(`${BACKEND_BASE_URL}user/profile`, {
          headers: {
            Authorization: `Bearer ${getCookie("user_access_token")}`,
          },
        })
        .then((res) => {
          navigate("/user");
        });
    } else if (getCookie("admin_access_token")) {
      await axios
        .get(`${BACKEND_BASE_URL}admin/profile`, {
          headers: {
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        })
        .then((res) => {
          navigate("/admin");
        });
    } else if (getCookie("vendor_access_token")) {
      await axios
        .get(`${BACKEND_BASE_URL}vendor/profile`, {
          headers: {
            Authorization: `Bearer ${getCookie("vendor_access_token")}`,
          },
        })
        .then((res) => {
          navigate("/vendor");
        });
    // if (sessionStorage.getItem("user_access_token")) {
    //   await axios
    //     .get(`${BACKEND_BASE_URL}user/profile`, {
    //       headers: {
    //         Authorization: `Bearer ${sessionStorage.getItem(
    //           "user_access_token"
    //         )}`,
    //       },
    //     })
    //     .then((res) => {
    //       navigate("/user");
    //     });
    // } else if (sessionStorage.getItem("admin_access_token")) {
    //   await axios
    //     .get(`${BACKEND_BASE_URL}admin/profile`, {
    //       headers: {
    //         Authorization: `Bearer ${sessionStorage.getItem(
    //           "admin_access_token"
    //         )}`,
    //       },
    //     })
    //     .then((res) => {
    //       navigate("/admin");
    //     });
    // } else if (sessionStorage.getItem("vendor_access_token")) {
    //   await axios
    //     .get(`${BACKEND_BASE_URL}vendor/profile`, {
    //       headers: {
    //         Authorization: `Bearer ${sessionStorage.getItem(
    //           "vendor_access_token"
    //         )}`,
    //       },
    //     })
    //     .then((res) => {
    //       navigate("/vendor");
    //     });
    } else {
      navigate("/user/login");
    }
  };
  // Initial wishlist count data
  const [wishList_count, setWishListCount] = useState();
  // Get wishlist Count Info
  const renderWishListInfo = async () => {
    // CHECK IF USER IS LOGGED IN OR NOT
    if (!sessionStorage.getItem("LOGGED_IN_USER_ID")) {
      await axios
        .get(
          `${BACKEND_BASE_URL}wish-list/view-items/${sessionStorage.getItem(
            "USER_TEMP_ID"
          )}`
        )
        .then((res) => {
          setWishListCount(res?.data?.wishList_count);
        });
    } else {
      await axios
        .get(
          `${BACKEND_BASE_URL}wish-list/view-items/${sessionStorage.getItem(
            "LOGGED_IN_USER_ID"
          )}`
        )
        .then((res) => {
          setWishListCount(res?.data?.wishList_count);
        });
    }
  };

  // Initial nav bar data
  const [navData, setNavData] = useState([]);
  // Get All Info
  const renderNavInfo = async () => {
    await axios.get(`${BACKEND_BASE_URL}navbar-items`).then((res) => {
      setNavData(res?.data?.data);
    });
  };
  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };
  useEffect(() => {
    renderNavInfo();
    renderWishListInfo();
  }, [sessionStorage.getItem("wishList_count")]);
  return (
    <>
      <Navbar
        expand="lg"
        variant="dark"
        className="bg_light_dark header"
        sticky="top"
      >
        <Container className="custom_container flex-column align-items-start ">
          {/* main nav */}
          <div className="d-flex w-100 align-items-center">
            <Navbar.Toggle
              aria-controls="offcanvasNavbar-expand-lg"
              className=""
            />
            <Navbar.Brand
              href="#"
              className="cl_white logo order-2 order-lg-1 ms-auto m-lg-0"
              as={Link}
              to="/"
            >
              <picture>
                <source media="(min-width:1350px)" srcSet={logo_long} />
                <img src={logo_short} alt="mySvgImage" />
              </picture>
            </Navbar.Brand>
            <ul className="justify-content-end p-0 m-0 ls_none ms-auto order-1 order-lg-2 header_desktop_list">
              <li className=" me-4">
                <Link
                  to=""
                  className="underline_none cl_medium_grey_header1 fw_500"
                >
                  Pricing
                </Link>
              </li>
              <li className=" me-4">
                <Link
                  to=""
                  className="underline_none cl_medium_grey_header1 fw_500"
                >
                  User Guide
                </Link>
              </li>
              {/* <li className=" me-4" onClick={renderVendor}> */}
              <li className=" me-4">
                <Link
                  to=""
                  className="underline_none cl_medium_grey_header1 fw_500"
                >
                  Start Selling
                </Link>
              </li>
              <li className="" onClick={renderProfileInfo}>
                <Link
                  to=""
                  className="underline_none cl_medium_grey_header1 fw_500"
                >
                  <BiUser className="me-2 cl_medium_grey_header1" size={20} />
                  {getCookie("LOGGED_IN_USER_NAME") ? (
                    <span>{getCookie("LOGGED_IN_USER_NAME")}</span>
                  ) : (
                    <span>Sign In</span>
                  )}
                </Link>
              </li>
            </ul>
          </div>

          {/* mobile offnavs */}
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
                Code Pixel
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="justify-content-between">
              {/* flex-grow-1 */}
              <Nav className="">
                {/* NavbarItems */}

                {navData?.map((navData, i) => (
                  <Nav.Link
                    href={navData?.link}
                    className="ps-0 pe-4"
                    key={navData.id}
                  >
                    {files.length == 0 &&
                      (navData != null && navData?.image != null ? (
                        <img
                          className="me-2 cl_medium_grey"
                          src={`${fileUrl}${navData?.image}`}
                          alt=""
                          style={{ width: "21px", height: "20px" }}
                        />
                      ) : (
                        <img
                          className="me-2 cl_medium_grey"
                          src=""
                          alt="Logo"
                        />
                      ))}

                    <span className="underline_none cl_medium_grey_header1 fw_500">
                      {navData?.name}
                    </span>
                  </Nav.Link>
                ))}
                {/* <FaVideo className="me-2 cl_medium_grey" size={20} /> */}

                {/* <Nav.Link href="#action1" className="ps-0 pe-4">
                  <FaVideo className="me-2 cl_medium_grey" size={20} />
                  <span className="underline_none cl_medium_grey fw_500">
                    Video
                  </span>
                </Nav.Link>

                <Nav.Link href="#action2" className="ps-0 pe-4">
                  <FaMusic className="me-2 cl_medium_grey" size={20} />
                  <span className="underline_none cl_medium_grey fw_500">
                    Music
                  </span>
                </Nav.Link>

                <Nav.Link href="#action2" className="ps-0 pe-4">
                  <SiAdobeaftereffects
                    className="me-2 cl_medium_grey"
                    size={20}
                  />
                  <span className="underline_none cl_medium_grey fw_500">
                    After Effects
                  </span>
                </Nav.Link>

                <Nav.Link href="#action2" className="ps-0 pe-4">
                  <SiAdobepremierepro
                    className="me-2 cl_medium_grey"
                    size={20}
                  />
                  <span className="underline_none cl_medium_grey fw_500">
                    Premiere Pro
                  </span>
                </Nav.Link>

                <Nav.Link href="#action2" className="ps-0 pe-4">
                  <MdMotionPhotosOn className="me-2 cl_medium_grey" size={20} />
                  <span className="underline_none cl_medium_grey fw_500">
                    Motion
                  </span>
                </Nav.Link>

                <Nav.Link href="#action2" className="ps-0 pe-4">
                  <SiKinopoisk className="me-2 cl_medium_grey" size={20} />
                  <span className="underline_none cl_medium_grey fw_500">
                    Resolve
                  </span>
                </Nav.Link>

                <Nav.Link href="#action2" className="ps-0 pe-4">
                  <FaFileImage className="me-2 cl_medium_grey" size={20} />
                  <span className="underline_none cl_medium_grey fw_500">
                    Image & GIF
                  </span>
                </Nav.Link>

                <Nav.Link href="#action2" className="ps-0 pe-4">
                  <FaChevronCircleDown
                    className="me-2 cl_medium_grey"
                    size={20}
                  />
                  <span className="underline_none cl_medium_grey fw_500">
                    More
                  </span>
                </Nav.Link> */}
              </Nav>
              {/* flex-grow-1 */}
              <Nav className="">
                <Nav.Link href="/wishlist" className="ps-0 ">
                  <FaHeart className="me-2 cl_medium_grey_header1" size={20} />
                  <span className="underline_none cl_medium_grey_header1 fw_500">
                    ({wishList_count}) Favorites
                  </span>
                </Nav.Link>
                {/* <Nav.Link href="#action2" className="ps-0">
                  <FaShoppingCart className="me-2 cl_medium_grey_header1" size={20} />
                  <span className="underline_none cl_medium_grey_header1 fw_500">
                    Cart
                  </span>
                </Nav.Link> */}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
