import axios from "axios";
import React, { useRef } from "react";
import * as FiIcons from "react-icons/fi";

import Swal from "sweetalert2";
import { Button, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";

const AdminFileTypeAdd = () => {
  const name = useRef();
  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("name", name.current.value);
    axios
      .post(`${BACKEND_BASE_URL}admin/file-type/store`, formdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((response) => {
        if (response.data?.status == 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
          //  renderAllInfo();
          // setModalShow(false);
        }
      });
    e.preventDefault();
  };
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">New FileType Add</div>
                  <Link to="/admin/file-type">
                    <Button variant="success" size="sm">
                      View All &nbsp;
                    </Button>
                  </Link>
                </div>

                <Form onSubmit={storeData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* add file type */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              FileType Name
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Name"
                              ref={name}
                            />
                          </Form.Group>
                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiIcons.FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminFileTypeAdd;
