import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Form,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import "./AdminLogin.css";
import Swal from "sweetalert2";

import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";

const AdminLogin = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const AdminEmail = useRef();
  const AdminPassword = useRef();

  let from = location.state?.from?.pathname || "/admin";

  const handleSubmit = (e) => {
    const formdata = new FormData();
    formdata.append("email", AdminEmail.current.value);
    formdata.append("password", AdminPassword.current.value);

    axios
      .post(`${BACKEND_BASE_URL}admin-login`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response.status == 200) {
          sessionStorage.setItem(
            "admin_access_token",
            response.data?.admin_access_token
          );
          sessionStorage.setItem("LOGGED_IN_USER_ID", response.data?.data?.id);
          sessionStorage.setItem(
            "LOGGED_IN_USER_NAME",
            response.data?.data?.name
          );
          navigate(from, { replace: true });
          Swal.fire({
            icon: "success",
            text: response.data.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
        }
      })
      .catch((error) => {
        setMessage(error.response?.data?.message);
        setShow(true);
      });

    e.preventDefault();
  };

  const [passType, setPassType] = useState("password");
  const handlePasswordType = () => {
    if (passType == "password") {
      setPassType("text");
    }
    if (passType == "text") {
      setPassType("password");
    }
  };

  return (
    <div className="form_wrapper" style={{ backgroundColor: "#f9fafb" }}>
      <Container className="container">
        <ToastContainer position="top-center" className="p-3 toast_msg">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            bg="danger"
            autohide
          >
            <Toast.Body>
              <strong className="ms-3">{message ? message : ""}</strong>
            </Toast.Body>
          </Toast>
        </ToastContainer>

        <Form id="form" className="form" onSubmit={handleSubmit}>
          <h1>Admin Login</h1>

          {/* ================== Email =================== */}
          <Form.Group className="form_group">
            <Form.Label>
              Email <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="email"
              id="email"
              placeholder="Enter email"
              name="email"
              ref={AdminEmail}
            />
          </Form.Group>

          {/* ============== Password ===================== */}
          <Form.Group className="form_group position-relative">
            <Form.Label>
              Password <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              id="password"
              placeholder="Enter password"
              name="password"
              ref={AdminPassword}
            />
            <div className="eye_icon" onClick={handlePasswordType}>
              <AiIcons.AiFillEye size="1.2em" />
            </div>
          </Form.Group>

          <Button type="submit" className="w-100 login_btn">
            Log In
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default AdminLogin;
