import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";
import { FiSave } from "react-icons/fi";
import { ToastSuccess } from "../../../Components/ToastAlert";
import RichTextEditor from "../../../Components/RichTextEditor";

const AdminBlogAdd = () => {
  const title = useRef();
  const image = useRef();
  const desc = useRef();
  const contentCat_id = useRef();

  // Image Preview
  const [files, setFile] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };
  // description
  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  const [addInfo, setAddInfo] = useState([]);
  // Get Add Info
  const renderAddInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/blogs/store-info`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setAddInfo(res?.data?.data);
      });
  };
  console.log("ad info", addInfo);

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();

    formdata.append("title", title.current.value);
    formdata.append("desc", desc.current.value);
    formdata.append("image", image.current.files[0]);
    formdata.append("contentCat_id", contentCat_id.current.value);

    axios
      .post(`${BACKEND_BASE_URL}admin/blogs/store`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })

      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();
          setFile([]);
          setValue("", "html");
          //renderAllInfo();
          // setModalShow(false);
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    renderAddInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">Add Blog</div>
                  <Link to="/admin/blogs">
                    <Button variant="success" size="sm">
                      View All Blogs &nbsp;
                    </Button>
                  </Link>
                </div>

                <Form onSubmit={storeData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* add Color */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Title
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Title"
                              ref={title}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Category
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              required
                              ref={contentCat_id}
                              // onChange={getValue}
                            >
                              <option value="">Select Category</option>
                              {addInfo?.map((data) => (
                                <option value={data?.id} key={data?.id}>
                                  {data.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          {/* Description */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Description &nbsp;{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            {/* <Form.Control as="textarea" rows={6} ref={desc} /> */}

                            <RichTextEditor
                              joditRef={desc}
                              getValue={getValue}
                              value={value}
                            />
                          </Form.Group>
                          {/* image */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Image &nbsp;{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Control
                              required
                              type="file"
                              ref={image}
                              onChange={handleImgPreview}
                            />

                            {files.map((file, key) => {
                              return (
                                <div key={key} className="Row">
                                  <span className="Filename">
                                    <img
                                      width={80}
                                      height={50}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                    />
                                  </span>
                                </div>
                              );
                            })}

                            <Form.Control.Feedback type="invalid">
                              Please choose an image
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminBlogAdd;
