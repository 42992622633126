import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../../Utility/GlobalVariable/GlobalVariables";
import { ToastSuccess } from "../../../Components/ToastAlert";
import {
  AiOutlineCheckSquare,
  AiOutlineCloseSquare,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import {
  DotLoader,
  PulseLoader,
} from "../../../Components/Custom Loader/CustomLoader";

const AllContent = () => {
  const title = useRef();
  const price = useRef();
  const image = useRef();
  const description = useRef();
  const sku = useRef();
  const parentCategory = useRef();
  const subCategory = useRef();
  const brand = useRef();
  const color = useRef();
  const size = useRef();
  const quantity = useRef();
  const stock_unit = useRef();

  const workMultipleImage = useRef();

  // Initial table data
  const [tableData, setTableData] = useState([]);
  const [addInfo, setAddInfo] = useState([]);
  // const [childCategory, setChildCategory] = useState([]);
  const [subCategoryValue, setSubcategoryValue] = useState([]);

  const childCategory = (e) => {
    for (let i = 0; i < addInfo?.data?.length; i++) {
      if (addInfo?.data[i]?.id == e.target.value) {
        setSubcategoryValue(addInfo?.data[i]);
      }
    }
  };

  // Image Preview
  const [files, setFile] = useState([]);

  const [files2, setFile2] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  const handleImgPreview2 = (e) => {
    let allfiles2 = [];
    // console.log("allfiles2", allfiles2);
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles2.push(e.target.files[i]);
    }
    if (allfiles2.length > 0) {
      setFile2(allfiles2);
    }
  };

  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/content`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.data);
      });
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}admin/content/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(
              "admin_access_token"
            )}`,
          },
        })
        .then((response) => {
          if (response.data?.status == 200) {
            ToastSuccess.fire({
              icon: "success",
              title: response.data?.message,
            });
            renderAllInfo();
          } else {
            ToastSuccess.fire({
              icon: "warning",
              title: response.data?.message,
            });
          }
        });
    }
  };

  const is_home_update = (id) => {
    axios
      .get(`${BACKEND_BASE_URL}admin/content/is-home-update/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((response) => {
        ToastSuccess.fire({
          icon: "success",
          title: response.data?.message,
        });
        renderAllInfo();
      });
  };
  const is_featured_update = (id) => {
    axios
      .get(`${BACKEND_BASE_URL}admin/content/is-featured-update/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((response) => {
        ToastSuccess.fire({
          icon: "success",
          title: response.data?.message,
        });
        renderAllInfo();
      });
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="card-header">
                  <Link to="/admin/content/category">
                    <Button
                      variant="success"
                      size="sm"
                      // onClick={() => addNewData("Add")}
                    >
                      Add New Content &nbsp;
                      <span>
                        <AiOutlinePlusCircle />
                      </span>
                    </Button>
                  </Link>
                </div>

                <div className="table-responsive py-5">
                  {tableData?.length > 0 ? (
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th className="text-center font_size_15" scope="col">
                            Image
                          </th>
                          <th className="text-center font_size_15" scope="col">
                            Title
                          </th>
                          <th className="text-center font_size_15" scope="col">
                            Price $
                          </th>
                          <th className="text-center font_size_15" scope="col">
                            Category
                          </th>
                          <th className="text-center font_size_15" scope="col">
                            Sub Category
                          </th>
                          <th className="text-center font_size_15" scope="col">
                            Child Category
                          </th>
                          <th className="text-center font_size_15" scope="col">
                            isHome
                          </th>
                          <th className="text-center font_size_15" scope="col">
                            isFeatured
                          </th>
                          <th className="text-center font_size_15" scope="col">
                            Handle
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.map((data, i) => (
                          <tr key={data.id}>
                            <td> {i + 1}</td>
                            <td>
                              {data?.thumbnail != null ? (
                                <img
                                  className="img-thumbnail"
                                  style={{
                                    width: "80px",
                                    height: "60px",
                                    objectFit: "cover",
                                  }}
                                  src={`${fileUrl}${data.thumbnail}`}
                                  alt={data.title}
                                />
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="font_size_15">{data?.title}</td>
                            <td className="text-center font_size_15">
                              {data?.price}
                            </td>
                            <td className="text-center font_size_15">
                              {data?.categories?.name}
                            </td>
                            <td className="text-center font_size_15">
                              {data?.sub_categories?.name}
                            </td>
                            <td className="text-center font_size_15">
                              {data?.child_categories?.name}
                            </td>

                            <td
                              className="text-center"
                              onClick={() => is_home_update(data.id)}
                            >
                              {data?.is_home == 1 ? (
                                <AiOutlineCheckSquare
                                  className="text-success pointer"
                                  size={24}
                                />
                              ) : (
                                <AiOutlineCloseSquare
                                  className="text-danger pointer"
                                  size={24}
                                />
                              )}
                            </td>
                            <td
                              className="text-center"
                              onClick={() => is_featured_update(data.id)}
                            >
                              {data?.is_featured == 1 ? (
                                <AiOutlineCheckSquare
                                  className="text-success pointer"
                                  size={24}
                                />
                              ) : (
                                <AiOutlineCloseSquare
                                  className="text-danger pointer"
                                  size={24}
                                />
                              )}
                            </td>
                            <td>
                              {/* view button */}
                              {/* <Link to={`/admin/product/view/${data?.id}`}>
                              <button
                                onClick={() => showSingleData("View", data)}
                                className="py-1 px-2 bg-info border-0 rounded-3 me-1 mb-1"
                              >
                                <FaIcons.FaEye
                                  style={{
                                    color: "white",
                                  }}
                                  title="View"
                                  size="1.5em"
                                />
                              </button>
                            </Link> */}

                              {/* graphics template edit button  */}
                              {data?.contentCat_id == 1 && (
                                <Link
                                  to={`/admin/content/graphics-template/update/${data?.id}/${data?.contentCat_id}`}
                                >
                                  <button
                                    // onClick={() => fetchDataForEdit("Edit", data)}
                                    className="py-1 px-2 bg-warning border-0 rounded-3 me-1 mb-1"
                                  >
                                    <BiEdit
                                      style={{
                                        color: "white",
                                      }}
                                      title="Edit"
                                      size="1.5em"
                                    />
                                  </button>
                                </Link>
                              )}
                              {/* web Template edit button */}
                              {data?.contentCat_id == 2 && (
                                <Link
                                  to={`/admin/content/web-template/update/${data?.id}/${data?.contentCat_id}`}
                                >
                                  <button
                                    // onClick={() => fetchDataForEdit("Edit", data)}
                                    className="py-1 px-2 bg-warning border-0 rounded-3 me-1 mb-1"
                                  >
                                    <BiEdit
                                      style={{
                                        color: "white",
                                      }}
                                      title="Edit"
                                      size="1.5em"
                                    />
                                  </button>
                                </Link>
                              )}
                              {/* web Template edit button */}
                              {data?.contentCat_id == 3 && (
                                <Link
                                  to={`/admin/content/video-template/update/${data?.id}/${data?.contentCat_id}`}
                                >
                                  <button
                                    // onClick={() => fetchDataForEdit("Edit", data)}
                                    className="py-1 px-2 bg-warning border-0 rounded-3 me-1 mb-1"
                                  >
                                    <BiEdit
                                      style={{
                                        color: "white",
                                      }}
                                      title="Edit"
                                      size="1.5em"
                                    />
                                  </button>
                                </Link>
                              )}

                              {/* edit button */}
                              {/* <Link to={`/admin/product/edit/${data?.id}`}>
                              <button
                                // onClick={() => fetchDataForEdit("Edit", data)}
                                className="py-1 px-2 bg-warning border-0 rounded-3 me-1 mb-1"
                              >
                                <BiIcons.BiEdit
                                  style={{
                                    color: "white",
                                  }}
                                  title="Edit"
                                  size="1.5em"
                                />
                              </button>
                            </Link> */}

                              {/* delete button */}
                              <button
                                onClick={() => deleteData(data.id)}
                                className="py-1 px-2 bg-danger border-0 rounded-3 me-1 mb-1"
                              >
                                <MdDeleteForever
                                  style={{
                                    color: "white",
                                  }}
                                  title="Delete"
                                  size="1.5em"
                                />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="d-flex justify-content-center pt-5 pb-5 pe-5 me-5">
                      {/* <PulseLoader /> */}
                      <DotLoader />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllContent;
