import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Form,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io5";
import * as HiIcons from "react-icons/hi";
import * as BsIcons from "react-icons/bs";
import * as HiIcon from "react-icons/hi";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import axios from "axios";
import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";

const VendorRegistration = () => {
  const Name = useRef();
  const Email = useRef();
  const Password = useRef();
  const confirmPassword = useRef();

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState();
  const [userNameError, setUserNameError] = useState("");
  const [userEmailError, setUserEmailError] = useState("");
  const [userPassError, setUserPassError] = useState("");
  const [userConfirmPassError, setUserConfirmPassError] = useState("");
  const [eyeIcon, setEyeIcon] = useState(
    <AiIcons.AiFillEyeInvisible size="1.2em" />
  );
  const navigate = useNavigate();
  const location = useLocation();
  let from = location.state?.from?.pathname || "/vendor";

  const handleSignUp = (e) => {
    const formdata = new FormData();
    formdata.append("name", Name.current.value);
    formdata.append("email", Email.current.value);
    formdata.append("password", Password.current.value);
    formdata.append("confirm_password", confirmPassword.current.value);

    axios
      .post(`${BACKEND_BASE_URL}vendor-registration`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        // console.log("response", response.data);
        setUserNameError("");
        setUserEmailError("");
        setUserPassError("");
        if (response.data?.status == 400) {
          // console.log("error response");

          if (response.data.errors) {
            setUserNameError(response.data.errors.name);
            setUserEmailError(response.data.errors.email);
            setUserPassError(response.data.errors.password);
            setUserConfirmPassError(response.data.errors.confirm_password);
          }
        } else {
          sessionStorage.setItem("vendor_access_token", response.data?.token);
          //sessionStorage.setItem("vendor_userId", response?.data?.vendor?.id);
          sessionStorage.setItem("vendorId", response?.data?.data?.id);
          navigate(from, { replace: true });
          Swal.fire({
            icon: "success",
            text: response?.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
        }
      });
    e.preventDefault();
  };

  // Password Show/Hide Toggle
  const [passType, setPassType] = useState("password");
  const [confirmPassType, setConfirmPassType] = useState("password");

  const handlePasswordType = () => {
    if (passType == "password") {
      setPassType("text");
      setEyeIcon(<AiIcons.AiFillEye size="1.2em" />);
    }
    if (passType == "text") {
      setPassType("password");
      setEyeIcon(<AiIcons.AiFillEyeInvisible size="1.2em" />);
    }
  };
  const handleConfirmPasswordType = () => {
    if (confirmPassType == "password") {
      setConfirmPassType("text");
      setEyeIcon(<AiIcons.AiFillEye size="1.2em" />);
    }
    if (confirmPassType == "text") {
      setConfirmPassType("password");
      setEyeIcon(<AiIcons.AiFillEyeInvisible size="1.2em" />);
    }
  };

  return (
    <>
      {/* <Header /> */}
      <div className="form_wrapper" style={{ backgroundColor: "#f9fafb" }}>
        <Container className="container">
          <ToastContainer position="top-center" className="p-3 toast_msg">
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              bg="success"
              autohide
            >
              <Toast.Body>
                <strong className="ms-3">{message ? message : ""}</strong>
              </Toast.Body>
            </Toast>
          </ToastContainer>
          {/* <div className="w-100 text-center">
          <img
            src={require("../../Assets/logo.png")}
            alt=""
            className="img-fluid "
          />

        </div> */}
          <Form id="form" className="form" onSubmit={handleSignUp}>
            {/* onSubmit={handleSubmit} */}
            <h1 className="mt-3 mb-4">Sign Up as Vendor</h1>
            {/* <h1> {message ? message : "Log In or create an account"}</h1> */}

            {/* ================== Name =================== */}
            <Form.Group className="form_group">
              <Form.Label>
                Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                ref={Name}
                required
              />
              <small className="small_msg">{userNameError}</small>
            </Form.Group>

            {/* ================== Email =================== */}
            <Form.Group className="form_group">
              <Form.Label>
                Email Address<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                id="email"
                placeholder="Enter email"
                name="email"
                ref={Email}
                required
              />
              <small className="small_msg">{userEmailError}</small>
            </Form.Group>

            {/* ============== Password ===================== */}
            <Form.Group className="form_group">
              <Form.Label>
                Password <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type={passType}
                id="password1"
                placeholder="Enter password"
                name="password"
                ref={Password}
                required
              />
              <div className="eye_icon" onClick={handlePasswordType}>
                {eyeIcon}
              </div>
              <small className="small_msg">{userPassError}</small>
            </Form.Group>

            {/* ============== Confirm Password ===================== */}
            <Form.Group className="form_group">
              <Form.Label>
                Confirm Password <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type={confirmPassType}
                id="password"
                placeholder="Retype password"
                name="password"
                ref={confirmPassword}
                required
              />
              <div className="eye_icon" onClick={handleConfirmPasswordType}>
                {eyeIcon}
              </div>
              <small className="small_msg">{userConfirmPassError}</small>
            </Form.Group>

            <Button type="submit" className="w-100 login_btn">
              Sign Up
            </Button>

            {/* <Form.Check
              className="my-2"
              type="checkbox"
              label="By continuing, you agree to the Terms of Service and

              acknowledge our Privacy Policy."
            /> */}

            <h6 className="text-center my-3">
              <Link to="/vendor/login" className={{ fontSize: "12px" }}>
                Already have an account? Log In!
              </Link>
            </h6>
          </Form>
        </Container>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default VendorRegistration;
