import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
// import * as FiIcons from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { MDBCheckbox } from "mdb-react-ui-kit";

import Swal from "sweetalert2";
import { Button, Col, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";
import { ToastSuccess } from "../../../Components/ToastAlert";
import { FiSave } from "react-icons/fi";
// import RichTextEditor from "../../../Components/RichTextEditor";
import "./keywords.css";
import RichTextEditor from "../../../Components/RichTextEditor";

const WebTemplateAdd = () => {
  const { content_cat_id } = useParams();
  const title = useRef();
  const price = useRef();

  const sl_no = useRef();
  const contentCat_id = useRef();
  const contentSubCat_id = useRef();
  const contentChildCat_id = useRef();
  const thumbnail = useRef();
  const previewFile = useRef();
  const keywords = useRef();
  const mainFile = useRef();
  const mainFile_size = useRef();
  const fileType_id = useRef();
  const desc = useRef();

  const [show, setShow] = useState(false);
  const [slNoError, setSlNoErrorMessage] = useState("");
  // const resolution = useRef();
  // const responsive = useRef();
  const liveLink = useRef();
  const workMultipleImage = useRef();
  const dimension = useRef();
  const length = useRef();
  // const browser_id = useRef([]);

  const [responsive, setResponsive] = useState([]);
  const handleResponsive = (e) => {
    if (e.target.checked) {
      setResponsive(e.target.value);
    } else {
      setResponsive("");
    }
  };
  // const [resolution, setResolution] = useState([]);
  // const handleResolution = (e) => {
  //   if (e.target.checked) {
  //     setResolution(e.target.value);
  //   } else {
  //     setResolution("");
  //   }
  // };

  const [supportedBrowser, setSupportedBrowser] = useState([]);
  const handleSupportedBrowser = (e) => {
    if (e.target.checked) {
      setSupportedBrowser([...supportedBrowser, e.target.value]);
    } else {
      setSupportedBrowser(
        supportedBrowser.filter((id) => id !== e.target.value)
      );
    }
  };

  const [addInfo, setAddInfo] = useState([]);
  //   const [options, setOptions] = useState([]);
  // Get Add Info
  const renderAddInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/content/web/add-info/${content_cat_id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setAddInfo(res?.data);
        // setOptions(res?.data?.fileType);
      });
  };
  const [subCategoryValue, setSubcategoryValue] = useState([]);
  const childCategory = (e) => {
    for (let i = 0; i < addInfo?.subCat?.length; i++) {
      if (addInfo?.subCat[i]?.id == e.target.value) {
        setSubcategoryValue(addInfo?.subCat[i]);
      }
    }
  };
  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };
  // thumbnail Preview
  const [files3, setFile3] = useState([]);
  const handleImgPreview3 = (e) => {
    let allfiles3 = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles3.push(e.target.files[i]);
    }
    if (allfiles3.length > 0) {
      setFile3(allfiles3);
    }
  };
  // Image Preview
  const [files2, setFile2] = useState([]);
  const handleImgPreview2 = (e) => {
    let allfiles2 = [];
    //console.log("allfiles2", allfiles2);
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles2.push(e.target.files[i]);
    }
    if (allfiles2.length > 0) {
      setFile2(allfiles2);
    }
  };

  // description
  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  //keyword multi
  const [tags, setTags] = useState([]);
  const TagInput = ({ limit, tags, setTags }) => {
    const [newTag, setNewTag] = React.useState("");
    const handleChange = (e) => {
      if (e.target.value !== ",") {
        setNewTag(e.target.value);
      }
    };

    const handleKeyDown = (e) => {
      if (e.keyCode == 13) {
        setTags([...tags, newTag]);
        setNewTag("");
      }
    };
    const handleDelete = (e) => {
      let indexToDelete = parseInt(e.target.id);
      setTags([
        ...tags.slice(0, indexToDelete),
        ...tags.slice(indexToDelete + 1),
      ]);
    };
    return (
      <div className="TagInput">
        <div className="Tags">
          {tags.map((tag, index) => (
            <div className="Tag" key={index}>
              <span>{tag}</span>
              <button className="Delete" onClick={handleDelete} id={index}>
                ✕
              </button>
            </div>
          ))}
          <input
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={newTag}
            disabled={tags.length == limit ? true : false}
            placeholder="Separate keywords by pressing Enter"
            ref={keywords}
          />
        </div>
      </div>
    );
  };

  // form submit to backend
  const storeData = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("title", title.current.value);
    formdata.append("price", price.current.value);

    formdata.append("contentCat_id", content_cat_id);
    formdata.append("contentSubCat_id", contentSubCat_id.current.value);

    formdata.append("sl_no", sl_no.current.value);
    {
      subCategoryValue?.content_child_categories?.length > 0 &&
        formdata.append("contentChildCat_id", contentChildCat_id.current.value);
    }
    formdata.append("thumbnail", thumbnail.current.files[0]);
    formdata.append("previewFile", previewFile.current.files[0]);

    formdata.append("keywords[]", tags);

    formdata.append("mainFile", mainFile.current.files[0]);
    formdata.append("mainFile_size", mainFile_size.current.value);
    formdata.append("fileType_id", fileType_id.current.value);
    formdata.append("desc", desc.current.value);

    // formdata.append("resolution", resolution);
    formdata.append("responsive", responsive);
    // formdata.append("browser_id", supportedBrowser);

    supportedBrowser.forEach((item) => {
      formdata.append("browser[]", item);
    });

    formdata.append("liveLink", liveLink.current.value);
    files2.forEach((item) => {
      formdata.append("images[]", item);
    });

    axios
      .post(`${BACKEND_BASE_URL}admin/content/web/store`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })

      .then((response) => {
        e.target.reset();
        setFile([]);
        setFile2([]);
        setFile3([]);
        // setFile4([]);

        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();
          setValue("", "html");
          setTags([]);
        } else {
          setSlNoErrorMessage(response?.data?.errors?.sl_no);
          setShow(true);
          // ToastSuccess.fire({
          //   icon: "warning",
          //   text: response.data.errors.name[0],
          //   title: response.data?.message,
          // });
        }
      });
  };

  useEffect(() => {
    renderAddInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header text-center">Upload an Item</div>
                </div>

                <Form onSubmit={storeData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* title */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Title&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Name"
                              ref={title}
                            />
                          </Form.Group>
                          {/* price */}
                          <Form.Group
                            as={Col}
                            md="3"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Price&nbsp;{""}
                              <span className="text-danger">*</span>
                              &nbsp;{""}
                              <span className="text-danger ps-3">
                                + $1 platform Fee
                              </span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="number"
                              placeholder="Price"
                              ref={price}
                            />
                          </Form.Group>

                          {/* Serial Number */}
                          <Form.Group
                            as={Col}
                            md="3"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Serial Number
                            </Form.Label>
                            <Form.Control
                              required
                              type="number"
                              placeholder="Serial number"
                              ref={sl_no}
                            />
                            <p className="text-danger">{slNoError}</p>
                          </Form.Group>

                          {/* Sub Category */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Sub Category&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              ref={contentSubCat_id}
                              onChange={childCategory}
                            >
                              {addInfo?.subCat?.map((data) => (
                                <option value={data?.id} key={data.id}>
                                  {data?.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          {/* child Category */}
                          {subCategoryValue?.content_child_categories?.length >
                            0 && (
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Child Category&nbsp;{""}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                ref={contentChildCat_id}
                              >
                                <option value="">Select Child Category</option>
                                {subCategoryValue?.content_child_categories?.map(
                                  (data) => (
                                    <option value={data?.id} key={data.id}>
                                      {data?.name}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </Form.Group>
                          )}
                          {/* thumbnail */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Thumbnail&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Control
                              type="file"
                              ref={thumbnail}
                              onChange={handleImgPreview3}
                            />

                            {files3.map((file, key) => {
                              return (
                                <div key={key} className="Row">
                                  <span className="Filename">
                                    <img
                                      width={80}
                                      height={50}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                    />
                                  </span>
                                </div>
                              );
                            })}

                            <Form.Control.Feedback type="invalid">
                              Please choose an image
                            </Form.Control.Feedback>
                          </Form.Group>
                          {/* Preview image */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Preview Image&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Control
                              type="file"
                              ref={previewFile}
                              onChange={handleImgPreview}
                            />

                            {files.map((file, key) => {
                              return (
                                <div key={key} className="Row">
                                  <span className="Filename">
                                    <img
                                      width={80}
                                      height={50}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                    />
                                  </span>
                                </div>
                              );
                            })}

                            <Form.Control.Feedback type="invalid">
                              Please choose an image
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* Keywords */}
                          {/* <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Keywords
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="keywords"
                              ref={keywords}
                            />
                          </Form.Group> */}

                          {/* Main File */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Main File&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Control
                              type="file"
                              ref={mainFile}
                              //onChange={handleImgPreview}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please choose main file
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* mainFile Size */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              File Size (mb)
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="File Size"
                              ref={mainFile_size}
                            />
                          </Form.Group>

                          {/* File type id */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              File Type
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              ref={fileType_id}
                            >
                              <option value="">Select FileType</option>
                              {addInfo?.fileType?.map((data) => (
                                <option value={data?.id} key={data?.id}>
                                  {data.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>

                          {/* Live link */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Live Link&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Live Link"
                              ref={liveLink}
                            />
                          </Form.Group>

                          {/* Description */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Description
                            </Form.Label>
                            {/* <Form.Control as="textarea" rows={3} ref={desc} /> */}
                            <RichTextEditor
                              joditRef={desc}
                              getValue={getValue}
                              value={value}
                            />
                          </Form.Group>

                          {/* product gallery image */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Image Gallery
                            </Form.Label>

                            <Form.Control
                              // required
                              multiple
                              type="file"
                              ref={workMultipleImage}
                              onChange={handleImgPreview2}
                            />
                            <div className="row">
                              {files2.map((file, key) => {
                                return (
                                  <div key={key} className="col-md-3 ps-2 py-1">
                                    <span className="Filename">
                                      <img
                                        className="rounded-1"
                                        width={80}
                                        height={50}
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                      />
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Please choose an image
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* Resulation */}
                          {/* <Form.Group
                            as={Col}
                            md="3"
                            controlId="validationCustom02"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Resolution
                            </Form.Label>
                            <Form.Check
                              onChange={handleResolution}
                              name="resolution"
                              type="radio"
                              className="ms-4"
                              label="High"
                              value="High"
                            />
                            <Form.Check
                              onChange={handleResolution}
                              name="resolution"
                              type="radio"
                              className="ms-4"
                              label="Low"
                              value="Low"
                            />
                            <Form.Check
                              onChange={handleResolution}
                              name="resolution"
                              type="radio"
                              className="ms-4"
                              label="Medium"
                              value="Medium"
                            />
                          </Form.Group> */}

                          {/* Keywords */}
                          <div className="col-md-6">
                            <p className="label fw-bold">
                              Kewords &nbsp;{""}
                              <span className="text-danger">*</span>
                            </p>
                            <TagInput
                              limit={20}
                              tags={tags}
                              setTags={setTags}
                            />
                          </div>

                          {/* Responsive */}
                          <Form.Group
                            as={Col}
                            md="3"
                            controlId="validationCustom02"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Responsive &nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Check
                              required
                              onChange={handleResponsive}
                              name="responsive"
                              type="radio"
                              value="1"
                              className="ms-4"
                              label="Yes"
                              // ref={responsive}
                            />
                            <Form.Check
                              onChange={handleResponsive}
                              name="responsive"
                              type="radio"
                              value="0"
                              className="ms-4"
                              label="No"
                              // ref={responsive}
                            />
                          </Form.Group>

                          {/* supported browser */}
                          <Form.Group
                            as={Col}
                            md="3"
                            controlId="validationCustom02"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Supported Browser &nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            {addInfo?.supported_browser?.map((data, i) => (
                              <Form.Check
                                key={i}
                                onChange={handleSupportedBrowser}
                                name="browser"
                                className="ms-4"
                                label={data.name}
                                value={data.name}
                              />
                            ))}
                          </Form.Group>

                          {/* supported browser */}
                          {/* <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Supported Browser
                            </Form.Label>

                            {addInfo?.supported_browser?.map((data, i) => (
                              <div key={i}>
                                <MDBCheckbox
                                  inputRef={browser_id}
                                  onChange={handleSupportedSoftware}
                                  className="ms-2"
                                  name="inlineCheck"
                                  id="inlineCheckbox1"
                                  value={data?.id}
                                  label={data?.name}
                                />
                              </div>
                            ))}
                          </Form.Group> */}
                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WebTemplateAdd;
