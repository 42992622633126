import { Navigate, useLocation } from "react-router-dom";

export default function AdminPrivateRoute({ children }) {
  const admin = sessionStorage.getItem("admin_access_token");

  const location = useLocation();
  return admin ? (
    children
  ) : (
    <Navigate to="/admin/login" replace state={{ from: location }} />
  );
}
