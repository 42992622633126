import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./userProfileDashboard.css";
import "./UserFavoriteContents.css";

import "./userProfileDashboard.css";
import { Button, Dropdown, Nav, Navbar } from "react-bootstrap";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../../Utility/GlobalVariable/GlobalVariables";
import axios from "axios";
import { ToastSuccess } from "../../../Components/ToastAlert";
import { HiDownload } from "react-icons/hi";
import { Pagination } from "@mui/material";
import { DotLoader } from "../../../Components/Custom Loader/CustomLoader";
import moment from "moment/moment";
import getCookie from "../../../Components/Cookie/Get.Cookie";
// import { BACKEND_BASE_URL } from "../Component/GlobalVariables";

const UsersDownloads = () => {
  const [toggleNav, setToggleNav] = useState(false);
  const [profileInfo, setProfileInfo] = useState([]);
  // Get user Info
  const renderUserInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}user/profile`, {
        headers: {
          Authorization: `Bearer ${getCookie(
            "user_access_token"
          )}`,
        },
      })
      .then((res) => {
        setProfileInfo(res.data?.data);
      });
  };
  const navigate = useNavigate();
  const logoutUser = () => {
    axios
      .get(`${BACKEND_BASE_URL}user/logout`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(
            "user_access_token"
          )}`,
        },
      })
      .then((res) => {
        sessionStorage.removeItem("user_access_token");
        sessionStorage.removeItem("LOGGED_IN_USER_ID");
        sessionStorage.removeItem("LOGGED_IN_USER_NAME");
        ToastSuccess.fire({
          icon: "success",
          title: res.data?.message,
        });
        navigate("/user/login");
      });
  };
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [firstPage, setFirstPage] = useState([]);
  const [lastPage, setLastPage] = useState([]);
  const [page, setPage] = useState(1);

  const [downloaded_items, setDownloadedItems] = useState([]);
  const renderDownloadsInfo = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${BACKEND_BASE_URL}user/downloaded-contents/${sessionStorage.getItem(
          "LOGGED_IN_USER_ID"
        )}?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(
              "user_access_token"
            )}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setDownloadedItems(res.data?.data?.data);
        setFirstPage(res.data?.data?.from);
        setLastPage(res.data?.data?.last_page);
      });
  };

  // console.log("ddd", downloaded_items);

  useEffect(() => {
    renderUserInfo();
    renderDownloadsInfo();
  }, [page]);

  return (
    <div style={{ backgroundColor: "#f9f9fb", height: "100vh" }}>
      <div className="user_profile_navbar ">
        <Navbar expand="lg" className="custom_container">
          <Navbar.Brand href="/">
            <img
              src={require("../../../Assets/Common/logo_long.png")}
              alt=""
              className="img-fluid ms-2"
              width={185}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto text-white">
              <Nav.Link href="#home" className="me-3">
                Support
              </Nav.Link>
              <Nav.Link href="/user/downloads" className="me-3">
                <span>Downloads</span>
              </Nav.Link>
              <div className="user_profile_text logo_img_wrapper me-3">
                {profileInfo != null && profileInfo?.profile_photo != null ? (
                  <img
                    src={`${fileUrl}${profileInfo?.profile_photo}`}
                    alt="Profile"
                    className="img-fluid"
                  />
                ) : (
                  <img
                    src={require("../../../Assets/avatar.png")}
                    alt=""
                    className="w-100 h-100"
                  />
                )}
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="bg-transparent border-0"
                >
                  Profile
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item href="/user/favorites"> */}
                  <Dropdown.Item href="/user/">Profile</Dropdown.Item>
                  <Dropdown.Item href="/user/favorite-contents">
                    Favorites
                  </Dropdown.Item>
                  <Dropdown.Item href="/user/downloads">
                    Downloads
                  </Dropdown.Item>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <Dropdown.Item href="" onClick={logoutUser}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className=" my_download">
        <div className="custom_container my_download_header">
          <h2 className="py-3">My Downloads</h2>
        </div>
        <hr />
        <div
          className="custom_container"
          style={{ height: "65vh", overflow: "auto" }}
        >
          {/* header  */}

          {!isLoading && !error && downloaded_items?.length > 0 && (
            <div className="row p-3">
              <div className="col-md-2 p-0" />
              <div className="col-md-4">
                <p>
                  <strong>Item Downloaded</strong>
                </p>
              </div>
              <div className="col-md-3">
                <p>
                  <strong>Category</strong>
                </p>
              </div>
              <div className="col-md-4 p-0" />
            </div>
          )}

          {/* Contents  */}
          {/* jodi data pay */}
          {!isLoading &&
            !error &&
            downloaded_items?.length > 0 &&
            downloaded_items?.map((downloaded_items, i) => (
              <div className="row p-3  download_box" key={downloaded_items?.id}>
                <div className="col-md-2 p-0">
                  <div className="d-flex">
                    <div
                      className="img_wrapper me-4"
                      style={{
                        width: "160px",
                        height: "107px",
                        objectFit: "cover",
                      }}
                    >
                      <img
                        src={`${fileUrl}${downloaded_items?.contents?.thumbnail}`}
                        alt=""
                        style={{
                          width: "160px",
                          height: "107px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pt-3">
                  <p className="mb-0">{downloaded_items?.contents?.title}</p>
                </div>
                <div className="col-md-3 pt-3">
                  {downloaded_items?.contents?.categories?.name}
                </div>
                <div className="col-md-3 p-0 pt-3">
                  <div className="d-flex justify-content-end">
                    <span className="me-3">
                      {moment(downloaded_items?.updated_at).format("ll")}
                    </span>
                    {/* <span className="me-3">Jan 14, 2023</span>| */}
                    {/* <span className="ms-3 me-5">Download license</span> */}
                    <div className="d-flex flex-column">
                      <Button
                        className="mb-2"
                        href={`${fileUrl}${downloaded_items?.contents?.mainFile}`}
                      >
                        <HiDownload className="pb-1" /> &nbsp;Download
                      </Button>
                      {/* <p className="mb-0">ADD A LICENSE</p> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {isLoading && !error && (
            <>
              <div className="d-flex justify-content-center pb-5 pt-5">
                {/* <PulseLoader /> */}
                <DotLoader />
              </div>
            </>
          )}

          {!isLoading && !error && downloaded_items?.length < 1 && (
            <>
              <div className="text-center text-danger pb-5 pt-5">
                <h1>No data Found</h1>
              </div>
            </>
          )}
        </div>
        {/* paginate */}
        {!isLoading && !error && downloaded_items?.length > 0 && (
          <>
            <div className="pagination_box d-flex justify-content-center mt-5">
              <Pagination
                className="paggination  py-3 px-5 flex-wrap"
                count={lastPage}
                defaultPage={page}
                selected={page}
                siblingCount={1}
                onChange={(e, value) => setPage(value)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UsersDownloads;
