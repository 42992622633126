import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import Slider from "react-slick";
import nid_banner from "../../Assets/Common/mid_banner.jpg";

// import horizontal from "../../Assets/Gallery/g3.jpg";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import Header from "../../Components/Common/Header/Header";
import Footer from "../../Components/Common/Footer/Footer";
import { Link, useParams } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import "./ProductDetails.css";
import card from "../../Assets/Common/card.png";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../Utility/GlobalVariable/GlobalVariables";
import { v4 as uuid } from "uuid";
import { ToastSuccess } from "../../Components/ToastAlert";

import Parse from "html-react-parser";
import { AiFillEye } from "react-icons/ai";
import {
  DotLoader,
  PulseLoader,
} from "../../Components/Custom Loader/CustomLoader";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <FaChevronRight
      className={className}
      // style={{
      //   ...style,
      //   display: "block",
      //   background: "white",
      //   color: "black",
      //   width: 35,
      //   height: 35,
      //   zIndex: 9,
      //   right: -15,
      //   top: "43%",
      //   borderRadius: "50%",
      //   padding: 7,
      // }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FaChevronLeft
      className={className}
      // style={{
      //   ...style,
      //   display: "block",
      //   background: "white",
      //   color: "black",
      //   width: 35,
      //   height: 35,
      //   zIndex: 9,
      //   left: -15,
      //   top: "43%",
      //   borderRadius: "50%",
      //   padding: 7,
      // }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow2(props) {
  const { className, style, onClick } = props;
  return (
    <FaChevronRight
      className={className}
      // style={{
      //   ...style,
      //   display: "block",
      //   background: "white",
      //   color: "black",
      //   width: 30,
      //   height: 30,
      //   zIndex: 9,
      //   right: -15,
      //   top: "26%",
      //   borderRadius: "50%",
      //   padding: 7,
      // }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow2(props) {
  const { className, style, onClick } = props;
  return (
    <FaChevronLeft
      className={className}
      // style={{
      //   ...style,
      //   display: "block",
      //   background: "white",
      //   color: "black",
      //   width: 30,
      //   height: 30,
      //   zIndex: 9,
      //   left: -15,
      //   top: "26%",
      //   borderRadius: "50%",
      //   padding: 7,
      // }}
      onClick={onClick}
    />
  );
}

const ProductDetails = () => {
  document.title = "Content Details | Code Pixel";
  const { content_id } = useParams();
  const [demoJson, setDemoJson] = useState("");
  const UserEmail = useRef();
  const UserPassword = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [LOGGED_IN_USER_ID, setLOGGED_IN_USER_ID] = useState("");
  // const [LoginErrorMassege, setLoginErrorMassege] = useState("");

  // console.log(demoJson);

  //set single data
  const [singleData, setSingleData] = useState("");
  const [similarData, setSimilarData] = useState([]);
  const [availableData, setAvailableData] = useState([]);
  const [keywords, setKeywords] = useState("");
  // Get All graphics Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}product/show-single/${content_id}`)
      .then((res) => {
        setIsLoading(false);
        setError(false);

        setSingleData(res?.data?.single_data);

        setKeywords(res?.data?.content_keywords?.[0]);
        setDemoJson(res?.data?.data?.supported_software_or_browser?.[0]);
        setSimilarData(res?.data?.similar_design);
        setAvailableData(res?.data?.also_available);
      });
  };

  // console.log("sing d", singleData.length);
  // console.log("simi d", similarData?.length);
  // console.log("avail d", availableData?.length);

  const [wishList_count, setWishListCount] = useState();
  const renderWishListInfo = async () => {
    // CHECK IF USER IS LOGGED IN OR NOT
    if (!sessionStorage.getItem("LOGGED_IN_USER_ID")) {
      await axios
        .get(
          `${BACKEND_BASE_URL}wish-list/view-items/${sessionStorage.getItem(
            "USER_TEMP_ID"
          )}`
        )
        .then((res) => {
          setWishListCount(res?.data?.wishList_count);
          if (res.data.wishlist_count) {
            sessionStorage.setItem("wishList_count", res.data.wishlist_count);
          }
        });
    } else {
      await axios
        .get(
          `${BACKEND_BASE_URL}wish-list/view-items/${sessionStorage.getItem(
            "LOGGED_IN_USER_ID"
          )}`
        )
        .then((res) => {
          setWishListCount(res?.data?.wishList_count);
          if (res.data.wishlist_count) {
            sessionStorage.setItem("wishList_count", res?.data?.wishlist_count);
          }
        });
    }
  };

  let result = keywords?.split(",");

  const addToWishlist = (content_id) => {
    let userId;
    let USER_TEMP_ID;
    let userType;
    // CHECK IF USER IS LOGGED IN OR NOT
    if (!sessionStorage.getItem("LOGGED_IN_USER_ID")) {
      // Guest
      userType = "Not-Reg";
      if (!sessionStorage.getItem("USER_TEMP_ID")) {
        // First Time
        USER_TEMP_ID = uuid();
        // set into local storage
        sessionStorage.setItem("USER_TEMP_ID", USER_TEMP_ID);
        userId = USER_TEMP_ID;
      } else {
        userId = sessionStorage.getItem("USER_TEMP_ID");
      }
    } else {
      userType = "Reg";
      userId = sessionStorage.getItem("LOGGED_IN_USER_ID");
    }
    // sent data to backend
    const formdata = new FormData();
    formdata.append("content_id", content_id);
    formdata.append("userId", userId);
    formdata.append("userType", userType);
    axios
      .post(`${BACKEND_BASE_URL}wish-list/add`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        // console.log(response.data.message);
        sessionStorage.setItem("wishList_count", response.data.wishlist_count);
        if (response.data.status === 201) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          renderWishListInfo();
        } else {
          ToastSuccess.fire({
            icon: "warning",
            title: response.data?.message,
          });
        }
      });
  };

  const AddTODownloadedContent = (content_id) => {
    let userId;
    userId = sessionStorage.getItem("LOGGED_IN_USER_ID");
    // sent data to backend
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("userId", userId);
    formdata.append("content_id", content_id);

    axios
      .post(
        `${BACKEND_BASE_URL}product/download-count/${content_id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )

      .then((response) => {});
  };

  const [passType, setPassType] = useState("password");
  const handlePasswordType = () => {
    if (passType === "password") {
      setPassType("text");
    }
    if (passType === "text") {
      setPassType("password");
    }
  };

  // Modal Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");
  // Add new
  const addNewData = (modalValue) => {
    setModalSize("medium");
    setModalData(modalValue);
    setModalShow(true);
  };

  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("email", UserEmail.current.value);
    formdata.append("password", UserPassword.current.value);

    axios
      .post(`${BACKEND_BASE_URL}user-login`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            sessionStorage.setItem(
              "LOGGED_IN_USER_ID",
              response.data?.data?.id
            );
            sessionStorage.setItem(
              "user_access_token",
              response.data?.user_access_token
            );
            sessionStorage.setItem(
              "LOGGED_IN_USER_NAME",
              response.data?.data?.name
            );
            setLOGGED_IN_USER_ID(response.data?.data?.id);
          }, 1000);
        }
        ToastSuccess.fire({
          icon: "success",
          title: response.data?.message,
        });
        e.target.reset();
        renderAllInfo();
        setModalShow(false);
      })
      .catch((error) => {
        setMessage(error.response?.data?.message);
        setShow(true);
      });
    e.preventDefault();
  };
  // console.log("message", message);

  useEffect(() => {
    window.scrollTo(0, 0);
    renderAllInfo();
    renderWishListInfo();
    // renderWishListInfo();
  }, [content_id]);

  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img
            className="img-fluid"
            src={`${fileUrl}${singleData?.gallery[i]?.images}`}
            alt="hi"
          />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const settings1 = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipe: false,
    dots: false,
    arrows: true,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          swipe: true,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipe: false,
    dots: false,
    arrows: true,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          swipe: true,
        },
      },
    ],
    nextArrow: <SampleNextArrow2 />,
    prevArrow: <SamplePrevArrow2 className="hello" />,
  };

  return (
    <>
      <Header />
      <div className="productDetails sesction_mt">
        {/* top sec product thumb and description */}
        <div className="custom_container container_fluid_md product_thumb">
          {!isLoading && !error && singleData != null && (
            <Row className="">
              {/* left side product thumb & overview */}
              <Col md={8} className="mb-4">
                {/* product_thumb */}
                <Row className="main_image sesction_mb px-3">
                  {singleData?.previewFile_type === "video" ? (
                    singleData != null && singleData?.previewFile != null ? (
                      <video controls autoPlay>
                        <source
                          src={`${fileUrl}${singleData?.previewFile}`}
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      ""
                    )
                  ) : singleData?.gallery?.length > 0 ? (
                    <Slider {...settings}>
                      {singleData?.gallery?.map((slider) => (
                        <div
                          className="border-0 rounded-0 w-100 single_team"
                          key={singleData?.id}
                        >
                          <div className="team_img ">
                            <img
                              className="w-100 border-0 rounded-1 mb-3"
                              src={`${fileUrl}${slider?.images}`}
                              alt=""
                            />
                          </div>
                        </div>
                      ))}
                    </Slider>
                  ) : singleData != null && singleData?.previewFile != null ? (
                    <img
                      className="w-100 border-0 rounded-1 mb-3"
                      src={`${fileUrl}${singleData?.previewFile}`}
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="overview sesction_pt">
                  <h2 className="overview_title position-relative text-uppercase  font_size_30 fw_700 mb-5 ">
                    Overview
                  </h2>

                  {singleData != null && singleData?.desc != null ? (
                    <div className="mt-2 overvie w_short_dec font_size_15 fw_500 mb-md-5 mb-4 text-muted">
                      {Parse(`${singleData.desc}`)}
                    </div>
                  ) : (
                    ""
                  )}

                  <Col md={4} xs={6} className="mb-md-5 mb-4">
                    <p className="text-uppercase heading font_size_20 mb-0 fw_700 cl_medium_grey">
                      Directed By
                    </p>
                    <p className="body font_size_20 mb-0 fw_700 ">
                      Directed By
                    </p>
                  </Col>
                  <Col md={4} xs={6} className="mb-md-5 mb-4">
                    <p className="text-uppercase heading font_size_20 mb-0 fw_700 cl_medium_grey">
                      Directed By
                    </p>
                    <p className="body font_size_20 mb-0 fw_700 ">
                      Directed By
                    </p>
                  </Col>
                  <Col md={4} xs={6} className="mb-md-5 mb-4">
                    <p className="text-uppercase heading font_size_20 mb-0 fw_700 cl_medium_grey">
                      Directed By
                    </p>
                    <p className="body font_size_20 mb-0 fw_700 ">
                      Directed By
                    </p>
                  </Col>
                  <Col md={4} xs={6} className="mb-md-5 mb-4">
                    <p className="text-uppercase heading font_size_20 mb-0 fw_700 cl_medium_grey">
                      Directed By
                    </p>
                    <p className="body font_size_20 mb-0 fw_700 ">
                      Directed By
                    </p>
                  </Col>
                  <Col md={4} xs={6} className="mb-md-5 mb-4">
                    <p className="text-uppercase heading font_size_20 mb-0 fw_700 cl_medium_grey">
                      Directed By
                    </p>
                    <p className="body font_size_20 mb-0 fw_700 ">
                      Directed By
                    </p>
                  </Col>
                  <Col md={4} xs={6} className="mb-md-5 mb-4">
                    <p className="text-uppercase heading font_size_20 mb-0 fw_700 cl_medium_grey">
                      Directed By
                    </p>
                    <p className="body font_size_20 mb-0 fw_700 ">
                      Directed By
                    </p>
                  </Col>
                  {/* <Col md={4} xs={6} className="mb-md-5 mb-4">
                  <p className="text-uppercase heading font_size_20 mb-0 fw_700 cl_medium_grey">
                    Directed By
                  </p>
                  <p className="body font_size_20 mb-0 fw_700 ">Directed By</p>
                </Col> */}
                </Row>
              </Col>

              {/* right side product description */}
              <Col md={4}>
                <div className="product_description">
                  {/* product title */}
                  <div className="product_name">
                    <h2 className="font_size_24 fw_600">{singleData?.title}</h2>

                    {singleData?.uploader?.name && (
                      <p className="font_size_18 fw_500">
                        Designed and sold by{" "}
                        <strong>{singleData?.uploader?.name}</strong>
                      </p>
                    )}
                    <p className="font_size_18 fw_500">
                      Price <strong>{singleData?.price} $</strong>
                    </p>
                  </div>

                  {/* product buy */}

                  <div className="product_buy sesction_mb">
                    {/* button */}
                    <div className="d-grid gap-3 mb-4">
                      {singleData?.liveLink?.length > 0 && (
                        <Button
                          // href={`${fileUrl}${singleData?.file}`}
                          href={singleData?.liveLink}
                          variant="primary"
                          size="lg"
                          className="rounded-1"
                        >
                          Live Preview
                        </Button>
                      )}
                      {/* {LOGGED_IN_USER_ID && */}
                      {sessionStorage.getItem("LOGGED_IN_USER_ID") ? (
                        <Button
                          // download
                          href={`${fileUrl}${singleData?.mainFile}`}
                          variant="primary"
                          size="lg"
                          className="rounded-1"
                          onClick={() => {
                            AddTODownloadedContent(singleData.id);
                          }}
                        >
                          Download
                          {/* <a href={`${fileUrl}${singleData?.mainFile}`} download>
                        
                        </a> */}
                        </Button>
                      ) : (
                        <Button
                          // href="/sign-in-for-download-file"
                          variant="primary"
                          size="lg"
                          className="rounded-1"
                          onClick={() => addNewData("Sign in")}
                        >
                          Download
                        </Button>
                      )}
                      <div className="d-flex justify-content-between align-items-center">
                        <Button size="lg" className="rounded-1 w80 me-3 ">
                          Hire Us Now
                        </Button>

                        <button
                          className="icon rounded-1"
                          onClick={() => addToWishlist(singleData.id)}
                        >
                          <FaRegHeart className="light_blue" size={26} />
                        </button>
                      </div>
                    </div>
                    {/* payment info */}
                    <div className="d-flex flex-wrap justify-content-between">
                      <span className="cl_medium_grey fw_500">
                        Secure checkout :{" "}
                      </span>
                      <img
                        className="mb-3 img-fluid pointer card_logo"
                        src={card}
                        alt=""
                      />
                    </div>
                  </div>
                  {/* product specs */}
                  <div className="product_specs mb-5 position-relative">
                    <h2 className="font_size_20 fw_600 mb-3">Product Specs</h2>

                    <div>
                      {/* icon & text */}
                      {singleData?.categories?.name?.length > 0 && (
                        <div className="d-flex align-items-center cl_grey font_size_18">
                          <div className="icon me-3">
                            <FaRegCalendarAlt />
                          </div>
                          <p className="txt mb-0">
                            {" "}
                            <strong>{singleData?.categories?.name}</strong>
                          </p>
                        </div>
                      )}
                      {singleData?.sub_categories?.name?.length > 0 && (
                        <div className="d-flex align-items-center cl_grey font_size_18">
                          <div className="icon me-3">
                            <FaRegCalendarAlt />
                          </div>
                          <p className="txt  mb-0">
                            <strong>
                              {singleData?.sub_categories?.name}{" "}
                              {singleData?.child_categories?.name}
                            </strong>
                          </p>
                        </div>
                      )}
                      {singleData?.attribute?.[0].dimension?.length > 0 && (
                        <div className="d-flex align-items-center cl_grey font_size_18">
                          <div className="icon me-3">
                            <FaRegCalendarAlt />
                          </div>
                          <p className="txt  mb-0">
                            <strong>
                              {singleData?.attribute?.[0].dimension} pixel
                            </strong>
                          </p>
                        </div>
                      )}
                      {singleData?.attribute?.[0].resolution?.length > 0 && (
                        <div className="d-flex align-items-center cl_grey font_size_18">
                          <div className="icon me-3">
                            <FaRegCalendarAlt />
                          </div>
                          <p className="txt  mb-0">
                            <strong>
                              {singleData?.attribute?.[0].resolution} pixel
                            </strong>
                          </p>
                        </div>
                      )}
                      {singleData?.attribute?.[0].length?.length > 0 && (
                        <div className="d-flex align-items-center cl_grey font_size_18">
                          <div className="icon me-3">
                            <FaRegCalendarAlt />
                          </div>
                          <p className="txt  mb-0">
                            <strong>
                              {singleData?.attribute?.[0].length} Sec
                            </strong>
                          </p>
                        </div>
                      )}
                      {singleData?.file_type?.name?.length > 0 && (
                        <div className="d-flex align-items-center cl_grey font_size_18">
                          <div className="icon me-3">
                            <FaRegCalendarAlt />
                          </div>
                          <p className="txt  mb-0">
                            {" "}
                            <strong>{singleData?.file_type?.name} File </strong>
                          </p>
                        </div>
                      )}
                      {singleData?.mainFile_size?.length > 0 && (
                        <div className="d-flex align-items-center cl_grey font_size_18">
                          <div className="icon me-3">
                            <FaRegCalendarAlt />
                          </div>
                          <p className="txt  mb-0">
                            <strong>
                              File Size: {singleData?.mainFile_size}mb{" "}
                            </strong>
                          </p>
                        </div>
                      )}
                      {demoJson?.software?.length > 0 && (
                        <div className="d-flex align-items-center cl_grey font_size_18">
                          <div className="icon me-3">
                            <FaRegCalendarAlt />
                          </div>
                          <p className="txt  mb-0">
                            <strong>
                              {demoJson?.software
                                ?.split(/[\s"["]+/)
                                .join(" ")
                                .split("]")}
                            </strong>
                          </p>
                        </div>
                      )}
                      {demoJson?.browser?.length > 0 && (
                        <div className="d-flex align-items-center cl_grey font_size_18">
                          <div className="icon me-3">
                            <FaRegCalendarAlt />
                          </div>
                          <p className="txt  mb-0">
                            <strong>
                              {demoJson?.browser
                                ?.split(/[\s"["]+/)
                                .join(" ")
                                .split("]")}
                            </strong>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* keyword */}
                  <div className="related_keyword mb-5">
                    <h2 className="font_size_18 fw_600 mb-3">
                      Related Keywords
                    </h2>

                    <div className="d-flex flex-wrap cl_grey font_size_18">
                      {result?.map((data) => (
                        <div
                          className="border rounded-1 px-2 py-1 me-2 mb-2"
                          key={data}
                        >
                          {data}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}

          {/* jodi data na pay */}
          {isLoading && !error && (
            <>
              <div className="d-flex justify-content-center pb-5 ">
                {/* <PulseLoader /> */}
                <DotLoader />
              </div>
            </>
          )}
          {!isLoading && !error && singleData?.length < 1 && (
            <>
              <div className="text-center text-danger pb-5">
                <h1>No data Found</h1>
              </div>
            </>
          )}
        </div>
        <Modal
          show={modalShow}
          size={modalSize}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title className="" id="contained-modal-title-vcenter">
              {modalData}
            </Modal.Title>
            <Button
              className="p-0 border-0 bg-danger"
              onClick={() => setModalShow(false)}
            >
              <CgClose
                style={{
                  fontSize: "26px",
                }}
              />
            </Button>
          </Modal.Header>
          <Modal.Body>
            {/* Add Modal section */}
            {modalData === "Sign in" && (
              <div className="">
                <Form onSubmit={storeData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3 ">
                          {message && (
                            <p className="text-danger text-center">{message}</p>
                          )}
                          <div className="login_modal ">
                            {/* ================== Email =================== */}
                            <Form.Group className="form_group">
                              <Form.Label>
                                Email <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type="email"
                                id="email"
                                placeholder="Enter email"
                                name="email"
                                ref={UserEmail}
                                // style={{ width: "500px" }}
                              />
                            </Form.Group>

                            {/* ============== Password ===================== */}
                            <Form.Group className="form_group position-relative">
                              <Form.Label>
                                Password <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type={passType}
                                id="password"
                                placeholder="Enter password"
                                name="password"
                                ref={UserPassword}
                                // style={{ width: "500px" }}
                              />
                              <div
                                className="eye_icon"
                                onClick={handlePasswordType}
                              >
                                <AiFillEye size="1.2em" />
                              </div>
                            </Form.Group>

                            <Button type="submit" className="w-100 login_btn">
                              Log In
                            </Button>

                            <h6 className="text-center my-3">
                              <Link
                                to="/user-registration"
                                className={{ fontSize: "12px" }}
                              >
                                Create New Account
                              </Link>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalShow(false)} className="btn-danger">
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* also available on gallery */}
        <div className="gallery1 sesction_pt sesction_pb px-2 also_available">
          <div className="custom_container container_fluid_md">
            <div className="header mb-3">
              {availableData?.length > 0 ? (
                <h2 className="font_size_30 fw_600">Also available on</h2>
              ) : (
                ""
              )}
            </div>
            <div className="content explore ">
              <Slider {...settings1}>
                {availableData?.map((availableContent, i) => (
                  <div
                    className="border-0 rounded-0 w-100 single_team"
                    key={availableContent.id}
                  >
                    <div className="team_img ">
                      {availableContent != null &&
                      availableContent?.thumbnail != null ? (
                        <Link
                          to={`/content-details/${availableContent.id}`}
                          onClick={() =>
                            setTimeout(() => {
                              window.location.reload();
                            }, 100)
                          }
                        >
                          <img
                            style={{
                              // height: "162.5px",
                              height: "244px",
                              width: "244px",
                              objectFit: "cover",
                            }}
                            className="w-100 border-0 rounded-1 mb-3"
                            src={`${fileUrl}${availableContent?.thumbnail}`}
                            alt=""
                          />
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                    <Card.Body className="">
                      <Link
                        to=""
                        className="font_size_21 font_oswald cl_dark_hover underline_none"
                      >
                        <h6 className="mb-0">{availableContent?.title}</h6>
                      </Link>
                    </Card.Body>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>

        {/* hire us banner */}
        <div className="middle_banner">
          <div className="custom_container container_fluid_md">
            <Row>
              <Col lg={6}>
                <img
                  className="img-fluid d-block mx-auto"
                  src={nid_banner}
                  alt=""
                />
              </Col>
              <Col lg={6}>
                <div className="rightbar position-relative">
                  <div className="content ">
                    <h5 className="cl_dark_red font_size_16 ls_space3 text-uppercase fw_700">
                      {" "}
                      Asian Elements{" "}
                    </h5>
                    <h2 className="font_size_30 cl_white fw_700">
                      {" "}
                      Do Yourself Or Hire Us{" "}
                    </h2>
                    <p className="font_size_16 cl_white fw_500">
                      {" "}
                      Make your life easy, Just get in touch{" "}
                    </p>

                    <div className="d-flex flex-wrap justify-content-center justify-content-md-between">
                      <div className="btnn cl_white me-md-3 mb-md-0 mb-3">
                        <span className="innerfill">
                          <span>Do yourself</span>
                        </span>
                      </div>
                      <div className="btnn cl_white">
                        <span className="innerfill">
                          <span>hire us</span>
                        </span>
                      </div>
                    </div>

                    <div className="cl_white font_size_12 legal_info position-absolute start-50 translate-middle-x">
                      Terms and Conditions Apply.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {/* similar design gallery */}
        <div className="gallery2 sesction_pt sesction_pb px-2 similar_design">
          <div className="custom_container ">
            <div className="header mb-3">
              {similarData?.length > 0 ? (
                <>
                  <h2 className="font_size_30 fw_600">Similar Design</h2>
                  <Link to="" className="underline_none">
                    <p className="font_size_18 fw_700 mb-0 link_cl">
                      Explore Similar design from over 700,000 independent
                      artists. <FaChevronRight className="ms-2" size={16} />
                    </p>
                  </Link>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="content explore ">
              <Slider {...settings2}>
                {similarData?.map((similarContent, i) => (
                  <div
                    className="border-0 rounded-0 w-100 single_team"
                    key={similarContent.id}
                  >
                    <div className="team_img ">
                      {similarContent != null &&
                      similarContent?.thumbnail != null ? (
                        <Link
                          to={`/content-details/${similarContent.id}`}
                          onClick={() =>
                            setTimeout(() => {
                              window.location.reload();
                            }, 800)
                          }
                        >
                          <img
                            style={{
                              height: "162.5px",
                              width: "244px",
                              objectFit: "cover",
                            }}
                            className="w-100 border-0 rounded-1 mb-3"
                            src={`${fileUrl}${similarContent?.thumbnail}`}
                            alt=""
                          />{" "}
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                    <Card.Body className="">
                      <Link
                        to=""
                        className="font_size_21 font_oswald cl_dark_hover underline_none"
                      >
                        <h6 className="mb-0">{similarContent?.title}</h6>
                        {/* <p className="font_size_18 p_cl mb-2">By deniart</p> */}
                        <p className="font_size_15 p_cl fw_600">
                          {similarContent?.price}$
                        </p>
                      </Link>
                    </Card.Body>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductDetails;
