import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
// import * as AiIcons from "react-icons/ai";

import { Button, Col, Form, Modal } from "react-bootstrap";
// import { BACKEND_BASE_URL, fileUrl } from "../../../Utils/GlobalVariables";
import { Link, useParams } from "react-router-dom";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../../Utility/GlobalVariable/GlobalVariables";

const AdminProductView = () => {
  const { content_id } = useParams();
  // Vew single value
  //   const [singleData, setSingleData] = useState([]);

  //   const [isColorShow, setisColorShow] = useState(false);

  // Image Preview
  const [files, setFile] = useState([]);
  const [files2, setFile2] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };
  const handleImgPreview2 = (e) => {
    let allfiles2 = [];
    console.log("allfiles2", allfiles2);
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles2.push(e.target.files[i]);
    }
    if (allfiles2.length > 0) {
      setFile2(allfiles2);
    }
  };
  // Initial table data
  const [singleData, setSingleData] = useState([]);

  // Get Single Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/product/show-single/${content_id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setSingleData(res.data?.data);
      });
  };

  useEffect(() => {
    renderAllInfo();
    // renderAddInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>

                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">Product Information</div>
                  <Link to="/admin/product">
                    <Button variant="success" size="sm">
                      View All Products &nbsp;
                    </Button>
                  </Link>
                </div>

                <>
                  <h6>
                    <strong>Name :</strong> {singleData?.title}
                  </h6>
                  {/* if no description found */}
                  {singleData?.desc && (
                    <h6>
                      <strong>Description :</strong> {singleData?.desc}
                    </h6>
                  )}
                  <h6>
                    <strong>Price :</strong> {singleData?.price}
                  </h6>
                  <h6>
                    <strong>Category :</strong> {singleData?.categories?.name}
                  </h6>
                  {singleData?.sub_categories?.name && (
                    <h6>
                      <strong>Sub category :</strong>{" "}
                      {singleData?.sub_categories?.name}
                    </h6>
                  )}
                  <h6>
                    <strong>Product Image :</strong>
                    <div>
                      {files.length == 0 && (
                        <img
                          className="img-thumbnail mt-1"
                          width={80}
                          height={50}
                          src={`${fileUrl}${singleData?.image}`}
                          alt=""
                          name="img"
                        />
                      )}
                    </div>
                  </h6>
                  <h6>
                    <strong>Keywords :</strong> {singleData?.keywords}
                  </h6>

                  {/* if color available check */}
                  {singleData?.attribute?.map(
                    (data, i) =>
                      data.products_color?.name && (
                        <h6>
                          <strong>Color :</strong>{" "}
                          {singleData?.attribute?.map(
                            (data, i) => data.products_color?.name
                          )}
                        </h6>
                      )
                  )}
                  <h6>
                    <strong>Product Gallery :</strong>
                    <div>
                      {files2.length == 0 &&
                        singleData?.gallery?.map((data, i) => (
                          <img
                            key={data.id}
                            className=" mt-2 me-2"
                            width={200}
                            height={200}
                            src={`${fileUrl}${data?.images}`}
                            alt=""
                            name="img"
                          />
                        ))}
                    </div>
                  </h6>
                  <h6>Attributes</h6>
                  <div>
                    {singleData?.attribute?.map((data, i) => (
                      <h2>
                        <strong>Dimension :</strong>
                        {data?.dimension}
                      </h2>
                    ))}
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProductView;
