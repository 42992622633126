/*===================
    wishlist  INDEX  
=====================
1. variable 
2. main function
  a. Header component
  b. wishlist
    i.  wishlist_table
  c. footer 
================*/

// variable

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { AiOutlineClear, AiOutlineCloseCircle } from "react-icons/ai";
import Swal from "sweetalert2";
import Footer from "../../Components/Common/Footer/Footer";
import Header from "../../Components/Common/Header/Header";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../Utility/GlobalVariable/GlobalVariables";
// import test from "../../Assets/test.jpg";

const Wishlist = () => {
  //const [wishList_count, setWishListCount] = useState();
  const [wishList_items, setWishListItems] = useState();
  const [wishList_count, setWishListCount] = useState();
  const renderWishListInfo = async () => {
    // CHECK IF USER IS LOGGED IN OR NOT
    if (!sessionStorage.getItem("LOGGED_IN_USER_ID")) {
      await axios
        .get(
          `${BACKEND_BASE_URL}wish-list/view-items/${sessionStorage.getItem(
            "USER_TEMP_ID"
          )}`
        )
        .then((res) => {
          setWishListItems(res?.data?.wishList);
          setWishListCount(res?.data?.wishList_count);
          if (res.data.wishlist_count) {
            sessionStorage.setItem("wishList_count", res.data.wishlist_count);
          }
        });
    } else {
      await axios
        .get(
          `${BACKEND_BASE_URL}wish-list/view-items/${sessionStorage.getItem(
            "LOGGED_IN_USER_ID"
          )}`
        )
        .then((res) => {
          setWishListItems(res?.data?.wishList);
          setWishListCount(res?.data?.wishList_count);
          if (res.data.wishlist_count) {
            sessionStorage.setItem("wishList_count", res?.data?.wishlist_count);
          }
        });
    }
  };
  //remove single items
  const renderWishListRemoveItems = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      return result.isConfirmed;
    });
    if (!isConfirm) {
      return;
    }
    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}wish-list/remove-items/${id}`, {})
        .then((response) => {
          if (response?.data?.status == 200) {
            sessionStorage.setItem(
              "wishList_count",
              response?.data?.wishList_count
            );
            Swal.fire({
              icon: "success",
              text: response.data?.message,
              confirmButtonColor: "#5eba86",
            });
            renderWishListInfo();
          }
        });
    }
  };
  //   remove all items
  const renderWishListClear = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, Clear all!",
    }).then((result) => {
      return result.isConfirmed;
    });
    if (!isConfirm) {
      return;
    }
    if (isConfirm) {
      if (!sessionStorage.getItem("LOGGED_IN_USER_ID")) {
        await axios
          .delete(
            `${BACKEND_BASE_URL}wish-list/clear-wishlist/${sessionStorage.getItem(
              "USER_TEMP_ID"
            )}`
          )
          .then((response) => {
            if (response?.data?.status == 200) {
              sessionStorage.setItem(
                "wishList_count",
                response?.data?.wishList_count
              );
              Swal.fire({
                icon: "success",
                text: response.data?.message,
                confirmButtonColor: "#5eba86",
              });
              renderWishListInfo();
            }
          });
      } else {
        await axios
          .delete(
            `${BACKEND_BASE_URL}wish-list/clear-wishlist/${sessionStorage.getItem(
              "LOGGED_IN_USER_ID"
            )}`
          )
          .then((response) => {
            if (response?.data?.status == 200) {
              sessionStorage.setItem(
                "wishList_count",
                response?.data?.wishList_count
              );
              Swal.fire({
                icon: "success",
                text: response.data?.message,
                confirmButtonColor: "#5eba86",
              });
              renderWishListInfo();
            }
          });
      }
    }
  };
  useEffect(() => {
    renderWishListInfo();
  }, []);
  return (
    <>
      <Header />
      {/* wishlist */}
      <div className="wishlist sesction_pt sesction_pb">
        <div className="custom_container">
          {wishList_items?.length < 1 && (
            <>
              <hr />
              <div className="text-center text-secondary">
                <h1>Favorites (0 Items)</h1>
              </div>
              <hr />
            </>
          )}

          <></>
          {wishList_items?.length > 0 && (
            <>
              <Button
                onClick={() => renderWishListClear()}
                type=""
                className="btn btn-danger mt-5 rounded-3 border-0"
              >
                <AiOutlineClear /> &nbsp; Clear All
              </Button>

              <div className="wishlist_table">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-right" style={{ width: "150px" }}>
                        Items
                      </th>
                      <th></th>
                      <th className="text-center">FileType</th>
                      <th className="text-center">PRICE $</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {wishList_items?.map((items, i) => (
                      <tr key={items.id}>
                        <td className="table_image text-right">
                          <img
                            style={{ height: "80px", width: "142.22px" }}
                            className="img-fluid"
                            src={`${fileUrl}${items?.contents?.thumbnail}`}
                            alt=""
                          />
                        </td>
                        <td>
                          <h5 className="font_size_14 fw_700 ">
                            {items.contents?.title}
                          </h5>
                          {/* <p className="mb-1 font_size_14 fw_700 ">
                        <FaChevronRight /> Color:
                      </p>
                      <p className="font_size_14 fw_700">
                        <FaChevronRight /> Size: M
                      </p> */}
                        </td>
                        <td className="font_size_14 fw_600 text-center ">
                          {items?.contents?.categories?.name}
                        </td>
                        <td className="font_size_14 fw_600 text-center ">
                          {items.contents?.price} $
                        </td>
                        <td className="font_size_14 fw_600 cl_lightGrey text-center ">
                          <AiOutlineCloseCircle
                            onClick={() => renderWishListRemoveItems(items.id)}
                            size={30}
                            className="pointer"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Wishlist;
