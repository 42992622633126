import { Link } from "react-router-dom";

// import "./userdashboard.css";
import "../../User/Dashboard/userdashboard.css";

const VandorDashboard = () => {
  return (
    <div className="">
      <div className="body-wrapper">
        <div className="main_section">
          <div className="row py-3">
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-content">
                    {/* <h6 className="text-uppercase text widget-title">
                      <strong>Orders</strong>
                    </h6> */}
                    <p className="">
                      Start uploading your digital items and working with our
                      review team to make sure they are in great shape to start
                      selling.
                    </p>
                    <p>
                      <Link to="/vendor/content/type" className="btn">
                        Upload Item
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VandorDashboard;
