import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../../Utility/GlobalVariable/GlobalVariables";
import { FiSave } from "react-icons/fi";
import { ToastSuccess } from "../../../Components/ToastAlert";
// import RichTextEditor from "../../../Components/RichTextEditor";
import "./keywords.css";
import RichTextEditor from "../../../Components/RichTextEditor";

const VideoTemplateEdit = () => {
  const { contentId, content_cat_id } = useParams();
  var selected_software;

  const title = useRef();
  const price = useRef();
  const sl_no = useRef();
  // const contentCat_id = useRef();
  const contentSubCat_id = useRef();
  const contentChildCat_id = useRef();
  const thumbnail = useRef();
  const previewFile = useRef();
  const keywords = useRef();
  const mainFile = useRef();
  const mainFile_size = useRef();
  const fileType_id = useRef();
  const desc = useRef();
  const workMultipleImage = useRef();
  const resolution = useRef();
  const length = useRef();

  const [show, setShow] = useState(false);
  const [slNoError, setSlNoErrorMessage] = useState("");

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };
  // Image Preview
  const [files2, setFile2] = useState([]);
  const handleImgPreview2 = (e) => {
    let allfiles2 = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles2.push(e.target.files[i]);
    }
    if (allfiles2.length > 0) {
      setFile2(allfiles2);
    }
  };
  // thumbnail Preview
  const [files3, setFile3] = useState([]);
  const handleImgPreview3 = (e) => {
    let allfiles3 = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles3.push(e.target.files[i]);
    }
    if (allfiles3.length > 0) {
      setFile3(allfiles3);
    }
  };

  // Image Preview
  const [files4, setFile4] = useState([]);
  const handleMainFile = (e) => {
    let mainFile = [];
    //console.log("mainFile", mainFile);
    for (let i = 0; i < e.target.files.length; i++) {
      mainFile.push(e.target.files[i]);
    }
    if (mainFile.length > 0) {
      setFile4(mainFile);
    }
  };

  const [subCategoryValue, setSubcategoryValue] = useState([]);
  const childCategory = (e) => {
    for (let i = 0; i < addInfo?.subCat?.length; i++) {
      if (addInfo?.subCat[i]?.id == e.target.value) {
        setSubcategoryValue(addInfo?.subCat[i]);
      }
    }
  };

  const [supportedSoftware, setSupportedSoftware] = useState([]);
  const handleSupportedSoftware = (e) => {
    if (e.target.checked) {
      setSupportedSoftware([...supportedSoftware, e.target.value]);
    } else {
      setSupportedSoftware(
        supportedSoftware.filter((id) => id !== e.target.value)
      );
    }
  };

  // Initial single data
  const [singleData, setSingleData] = useState([null]);
  const [categoryinfo, setCategory] = useState([]);
  const [subCategoryinfo, setSubCategory] = useState([]);
  const [childCategoryInfo, setChildCategory] = useState([]);
  const [demoJson, setDemoJson] = useState("");
  const [software, setSoftware] = useState([]);
  // Get Single Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/content/video/show-single/${contentId}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setSingleData(res.data?.data);
        setCategory(res.data?.cat);
        setSubCategory(res.data?.subCat);
        setChildCategory(res.data?.childCat);
        setDemoJson(res?.data?.data?.supported_software_or_browser?.[0]);
        setSoftware(res.data?.json_decoded_supported_software);
      });
  };

  const [addInfo, setAddInfo] = useState([]);
  // Get Add Info
  const renderAddInfo = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}admin/content/video/add-info/${content_cat_id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((res) => {
        setAddInfo(res?.data);
        // setOptions(res?.data?.fileType);
      });
  };

  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  //keyword multi
  const [tags, setTags] = useState([]);
  const TagInput = ({ limit, tags, setTags }) => {
    const [newTag, setNewTag] = React.useState("");
    const handleChange = (e) => {
      if (e.target.value !== ",") {
        setNewTag(e.target.value);
      }
    };

    const handleKeyDown = (e) => {
      if (e.keyCode == 13) {
        setTags([...tags, newTag]);
        setNewTag("");
      }
    };
    const handleDelete = (e) => {
      let indexToDelete = parseInt(e.target.id);
      setTags([
        ...tags.slice(0, indexToDelete),
        ...tags.slice(indexToDelete + 1),
      ]);
    };
    return (
      <div className="TagInput">
        <div className="Tags">
          {tags.map((tag, index) => (
            <div className="Tag" key={index}>
              <span>{tag}</span>
              <button className="Delete" onClick={handleDelete} id={index}>
                ✕
              </button>
            </div>
          ))}
          <input
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={newTag}
            disabled={tags.length == limit ? true : false}
            placeholder="Separate keywords by pressing Enter"
            ref={keywords}
          />
        </div>
      </div>
    );
  };

  // Updated data to backend
  const updatedData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("title", title.current.value);
    formdata.append("price", price.current.value);

    formdata.append("contentCat_id", content_cat_id);
    formdata.append("contentSubCat_id", contentSubCat_id.current.value);

    formdata.append("sl_no", sl_no.current.value);

    formdata.append("thumbnail", thumbnail.current.files[0]);
    formdata.append("previewFile", previewFile.current.files[0]);

    formdata.append("keywords[]", tags);

    formdata.append("mainFile", mainFile.current.files[0]);
    formdata.append("mainFile_size", mainFile_size.current.value);
    formdata.append("fileType_id", fileType_id.current.value);
    formdata.append("desc", desc.current.value);

    supportedSoftware.forEach((item) => {
      formdata.append("software[]", item);
    });
    files2.forEach((item) => {
      formdata.append("images[]", item);
    });
    formdata.append("resolution", resolution.current.value);
    formdata.append("length", length.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}admin/content/video/update/${contentId}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${sessionStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();
          setFile([]);
          setFile2([]);
          setFile3([]);
          setFile4([]);
          renderAllInfo();
          setValue("", "html");
          setTags([]);
        } else {
          setSlNoErrorMessage(response?.data?.errors?.sl_no);
          setShow(true);

          // ToastSuccess.fire({
          //   icon: "warning",
          //   text: response.data.errors.name[0],
          //   title: response.data?.message,
          // });
        }
      });
    e.preventDefault();
  };
  useEffect(() => {
    renderAllInfo();
    renderAddInfo();
  }, []);

  useEffect(() => {
    let a = [];
    software.forEach((item) => {
      a.push(item);
    });

    setSupportedSoftware(a);
  }, [software]);
  // console.log("support software", supportedSoftware);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">Video Template Update</div>
                  <Link to="/admin/all-contents">
                    <Button variant="success" size="sm">
                      View All &nbsp;
                    </Button>
                  </Link>
                </div>

                <div className="">
                  <Form onSubmit={updatedData}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/* title */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3">
                              <Form.Label className="label fw-bold">
                                Title
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Name"
                                ref={title}
                                defaultValue={singleData.title}
                              />
                            </Form.Group>
                            {/* price */}
                            <Form.Group
                              as={Col}
                              md="3"
                              controlId="validationCustom01"
                              className="mb-3">
                              <Form.Label className="label fw-bold">
                                Price $ &nbsp;{" "}
                                <span className="text-danger">
                                  + $1 platform Fee
                                </span>
                              </Form.Label>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Price"
                                ref={price}
                                defaultValue={singleData.price}
                              />
                            </Form.Group>
                            {/* Serial Number */}
                            <Form.Group
                              as={Col}
                              md="3"
                              controlId="validationCustom01"
                              className="mb-3">
                              <Form.Label className="label fw-bold">
                                Serial Number
                              </Form.Label>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Serial number"
                                ref={sl_no}
                                defaultValue={singleData.sl_no}
                              />
                              <p className="text-danger">{slNoError}</p>
                            </Form.Group>

                            {/* Sub Category */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3">
                              <Form.Label className="label fw-bold">
                                Sub Category
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                ref={contentSubCat_id}
                                onChange={childCategory}>
                                {addInfo?.subCat?.map((data) => (
                                  <option
                                    value={data?.id}
                                    key={data.id}
                                    selected={
                                      data?.id == singleData?.sub_categories?.id
                                        ? "selected"
                                        : ""
                                    }>
                                    {data?.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>

                            {/* child Category */}

                            {/* {subCategoryValue?.content_child_categories
                              ?.length ? (
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Child Category
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  ref={contentChildCat_id}
                                >
                                  <option value="">
                                    Select Child Category
                                  </option>
                                  {subCategoryValue?.content_child_categories?.map(
                                    (data) => (
                                      <option
                                        value={data?.id}
                                        key={data.id}
                                        selected={
                                          data?.id ==
                                          singleData?.contentChildCat_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {data?.name}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                              </Form.Group>
                            ) : (
                              subCategoryValue?.name != "Uncategorized" && (
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="validationCustom01"
                                  className="mb-3"
                                >
                                  <Form.Label className="label fw-bold">
                                    Child Category
                                  </Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    ref={contentChildCat_id}
                                  >
                                    <option value="">
                                      Select Child Category
                                    </option>
                                    {childCategoryInfo?.map((data) => (
                                      <option
                                        value={data?.id}
                                        key={data?.id}
                                        selected={
                                          data?.id ==
                                          singleData?.contentChildCat_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {data?.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              )
                            )} */}
                            {/* thumbnail */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3">
                              <Form.Label className="label fw-bold">
                                Thumbnail &nbsp;{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>

                              <Form.Control
                                type="file"
                                ref={thumbnail}
                                onChange={handleImgPreview3}
                              />
                              {files3.map((file, key) => {
                                return (
                                  <div key={key} className="Row">
                                    <span className="Filename">
                                      <img
                                        width={80}
                                        height={50}
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                      />
                                    </span>
                                  </div>
                                );
                              })}
                              {files3.length == 0 &&
                                (singleData?.thumbnail != null ? (
                                  <img
                                    className="img-thumbnail mt-1"
                                    width={80}
                                    height={50}
                                    src={`${fileUrl}${singleData?.thumbnail}`}
                                    alt=""
                                    name="img"
                                  />
                                ) : (
                                  ""
                                ))}

                              <Form.Control.Feedback type="invalid">
                                Please choose an image
                              </Form.Control.Feedback>
                            </Form.Group>
                            {/* Preview file */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3">
                              <Form.Label className="label fw-bold">
                                Preview File &nbsp;{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>

                              <Form.Control
                                type="file"
                                ref={previewFile}
                                onChange={handleImgPreview}
                              />
                              {files.length == 0 &&
                                (singleData?.previewFile != null ? (
                                  <p>{`${singleData?.previewFile}`}</p>
                                ) : (
                                  ""
                                ))}
                              <Form.Control.Feedback type="invalid">
                                Please choose an image
                              </Form.Control.Feedback>
                            </Form.Group>
                            {/* Main File */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3">
                              <Form.Label className="label fw-bold">
                                Main File &nbsp;{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>

                              <Form.Control
                                type="file"
                                ref={mainFile}
                                onChange={handleMainFile}
                              />
                              <div>
                                {files4.length == 0 &&
                                  (singleData?.mainFile != null ? (
                                    <p>{`${singleData?.mainFile}`}</p>
                                  ) : (
                                    ""
                                  ))}
                              </div>
                              <Form.Control.Feedback type="invalid">
                                Please choose main file
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* mainFile Size */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3">
                              <Form.Label className="label fw-bold">
                                File Size (mb)
                              </Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="File Size"
                                ref={mainFile_size}
                                defaultValue={singleData?.mainFile_size}
                              />
                            </Form.Group>

                            {/* File type id */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3">
                              <Form.Label className="label fw-bold">
                                File Type
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                ref={fileType_id}>
                                <option value="">Select FileType</option>
                                {addInfo?.fileType?.map((data) => (
                                  <option
                                    value={data?.id}
                                    key={data?.id}
                                    selected={
                                      data?.id == singleData?.fileType_id
                                        ? "selected"
                                        : ""
                                    }>
                                    {data?.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>

                            {/* Resolution */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom02"
                              className="mb-3">
                              <Form.Label className="label fw-bold">
                                Resolution&nbsp; <span>e.g: 1280x720</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="resolution"
                                ref={resolution}
                                defaultValue={
                                  singleData?.attribute?.[0].resolution
                                }
                              />
                            </Form.Group>

                            {/* Length */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom02"
                              className="mb-3">
                              <Form.Label className="label fw-bold">
                                Length&nbsp; <span>e.g: 10 sec</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="resolution"
                                ref={length}
                                defaultValue={singleData?.attribute?.[0].length}
                              />
                            </Form.Group>

                            {/* Description */}
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom01"
                              className="mb-3">
                              <Form.Label className="label fw-bold">
                                Description &nbsp;{" "}
                                {/* <span className="text-danger">*</span> */}
                              </Form.Label>
                              {/* <Form.Control as="textarea" rows={3} ref={desc} /> */}
                              <RichTextEditor
                                joditRef={desc}
                                getValue={getValue}
                                value={singleData?.desc}
                              />
                            </Form.Group>

                            {/* product gallery image */}
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom01"
                              className="mb-3">
                              <Form.Label className="label fw-bold">
                                Image Gallery
                              </Form.Label>

                              <Form.Control
                                // required
                                multiple
                                type="file"
                                ref={workMultipleImage}
                                onChange={handleImgPreview2}
                              />
                              <div className="row">
                                {files2.map((file, key) => {
                                  return (
                                    <div
                                      key={key}
                                      className="col-md-3 ps-2 py-1">
                                      <span className="Filename">
                                        <img
                                          className="rounded-1"
                                          width={80}
                                          height={50}
                                          src={URL.createObjectURL(file)}
                                          alt={file.name}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}
                                <div style={{ height: "50", width: "80" }}>
                                  {files2.length == 0 &&
                                    (singleData?.gallery != null
                                      ? singleData?.gallery?.map((data, i) => (
                                          <img
                                            key={i}
                                            className=" mt-2 me-2"
                                            width={80}
                                            height={50}
                                            src={`${fileUrl}${data?.images}`}
                                            alt=""
                                            name="img"
                                          />
                                        ))
                                      : "")}
                                </div>
                              </div>
                              <Form.Control.Feedback type="invalid">
                                Please choose an image
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* supported Software */}
                            {/* <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom02"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Supported Software
                              </Form.Label>
                              {addInfo?.supported_software?.map((data, i) => (
                                <Form.Check
                                  key={i}
                                  onChange={handleSupportedSoftware}
                                  name="software"
                                  className="ms-4"
                                  label={data.name}
                                  value={data.name}
                                  checked={
                                    data?.name == software?.[0] ? "checked" : ""
                                  }
                                />
                              ))}
                            </Form.Group> */}

                            {/* Keywords */}
                            <div className="col-md-6">
                              <p className="label fw-bold">
                                Kewords &nbsp;{""}
                                <span className="text-danger">*</span>
                              </p>
                              <TagInput
                                limit={20}
                                tags={tags}
                                setTags={setTags}
                              />
                            </div>

                            {/* supported software */}
                            {/* <div className="row"> */}
                            <Form.Group
                              //key={i}
                              className="mb-3"
                              as={Col}
                              md="6">
                              <Form.Label className="label fw-bold">
                                Supported Software
                              </Form.Label>
                              {addInfo?.supported_software?.map(
                                (all_software, i) => (
                                  <div key={i}>
                                    {software.map((d, index) => {
                                      if (all_software?.name == d) {
                                        selected_software = d;
                                      }

                                      return (
                                        all_software?.name == d && (
                                          <Form.Check
                                            key={index}
                                            onChange={handleSupportedSoftware}
                                            type="checkbox"
                                            label={all_software?.name}
                                            value={all_software?.name}
                                            defaultChecked="checked"
                                          />
                                        )
                                      );
                                    })}
                                    {selected_software != all_software.name && (
                                      <Form.Check
                                        onChange={handleSupportedSoftware}
                                        type="checkbox"
                                        label={all_software?.name}
                                        value={all_software?.name}
                                      />
                                    )}
                                  </div>
                                )
                              )}
                            </Form.Group>
                            {/* </div> */}
                            <Button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0">
                              <FiSave /> &nbsp; Save
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideoTemplateEdit;
