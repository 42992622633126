import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import * as FiIcons from "react-icons/fi";

import Swal from "sweetalert2";
import { Button, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";

const AdminCategoryAdd = () => {
  const categoryName = useRef();
  const parentName = useRef();
  const [isactiveParentCategory, setIsactiveParentCategory] = useState(false);
  // Initial table data
  const [catTableData, setCatTableData] = useState([]);
  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/category`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setCatTableData(res.data?.data);
      });
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();

    formdata.append("name", categoryName.current.value);

    {
      isactiveParentCategory &&
        formdata.append("parent_id", parentName.current.value);
    }

    axios
      .post(`${BACKEND_BASE_URL}admin/category/store`, formdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })

      .then((response) => {
        if (response.data?.status == 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
          renderAllInfo();
        }
      });
    e.preventDefault();
  };
  useEffect(() => {
    renderAllInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">Add New Category</div>
                  <Link to="/admin/category">
                    <Button variant="success" size="sm">
                      View All Categories &nbsp;
                    </Button>
                  </Link>
                </div>

                <Form onSubmit={storeData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Check
                              onClick={() =>
                                setIsactiveParentCategory(
                                  !isactiveParentCategory
                                )
                              }
                              type="switch"
                              id="custom-switch"
                              label="Sub Category ?"
                            />
                          </Form.Group>

                          {/* add category */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Name
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Name"
                              ref={categoryName}
                            />
                          </Form.Group>

                          {isactiveParentCategory && (
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Parent Category
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                ref={parentName}
                              >
                                <option value="0">
                                  Select Parent Category
                                </option>
                                {catTableData.map(
                                  (data) =>
                                    !data.parent && (
                                      <option value={data?.id} key={data.id}>
                                        {data.name}
                                      </option>
                                    )
                                )}
                              </Form.Select>
                            </Form.Group>
                          )}

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiIcons.FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminCategoryAdd;
