import React, { useState } from "react";
import * as BiIcons from "react-icons/bi";
import * as AiIcons from "react-icons/ai";
import * as TbIcons from "react-icons/tb";
import * as MdIcons from "react-icons/md";
import * as IoIcons from "react-icons/io";
import * as SiIcons from "react-icons/si";
import * as BsIcons from "react-icons/bs";

import { Link, Outlet, useNavigate } from "react-router-dom";
import "./VendorContent.css";
import axios from "axios";
import Swal from "sweetalert2";
// import UserDashboardNavbar from "../../Component/UserDashboardNavbar";
import VendorFooter from "./VendorFooter";
import VendorDashboardNavbar from "./VendorDashboardNavbar";
import { BACKEND_BASE_URL } from "../../Utility/GlobalVariable/GlobalVariables";

const VendorContent = () => {
  const [toggleNav, setToggleNav] = useState(false);
  const [clickState, setClickState] = useState();

  const ToggleClass = (index) => {
    setClickState(index);
  };
  const navigate = useNavigate();
  const logoutVendor = () => {
    axios
      .get(`${BACKEND_BASE_URL}vendor/logout`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem(
            "vendor_access_token"
          )}`,
        },
      })
      .then((res) => {
        sessionStorage.removeItem("vendor_access_token");
        sessionStorage.removeItem("vendorId");
        sessionStorage.removeItem("LOGGED_IN_USER_NAME");
        Swal.fire({
          icon: "success",
          text: res.data.message,
          confirmButtonColor: "#5eba86",
        });
        navigate("/vendor/login");
      });
  };

  return (
    <div className="d-flex user__dashboard">
      <nav className={`user_sidebar ${toggleNav ? "close" : ""}`}>
        <header className="mt-4 mb-5">
          <Link to="/vendor" target="_blank" className="text-decoration-none">
            <div className="mx-4 image-text d-none d-md-flex align-items-center justify-content-between">
              <div className="text logo_img_wrapper">Vendor Dashboard</div>
            </div>
          </Link>

          <AiIcons.AiOutlineMenu
            className="bx bx-chevron-right toggle d-none d-md-block"
            onClick={() => setToggleNav(!toggleNav)}
          />
        </header>

        <div className="menu-bar">
          <div className="menu">
            <ul className="menu-links ps-0">
              {/* <li
                className={`nav-link border-bottom ${
                  clickState === 1 ? "activeLink" : ""
                }`}
              >
                <Link to="product" onClick={() => ToggleClass(1)}>
                  <TbIcons.TbBrandProducthunt className="icon" />
                  <span className="text nav-text text-uppercase">product</span>
                </Link>
              </li> */}

              <li className="nav-link">
                <Link to="" className="vendor_logout" onClick={logoutVendor}>
                  <BiIcons.BiLogOut className="icon" />
                  <span className="text nav-text text-uppercase">Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section className="rightbar">
        {/* <div className="topbar" /> */}
        <section style={{ marginBottom: "100px" }}>
          <VendorDashboardNavbar />
          <Outlet />
        </section>
      </section>
      <VendorFooter />
    </div>
  );
};

export default VendorContent;
