import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../Utility/GlobalVariable/GlobalVariables";
// import {
//   BACKEND_BASE_URL,
//   fileUrl,
// } from "../../Utility/GlobalVariable/GlobalVariables";

// import "../Pages/Admin/Dashboard/admindashboard.css";
import "../Admin/Dashboard/admindashboard.css";

const AdminDashboardNavbar = (props) => {
  const [show, setShow] = useState(false);
  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };
  // Initial profile data
  const [profileInfo, setProfileInfo] = useState(null);
  // Get profile Info
  const renderProfileInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/profile`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setProfileInfo(res.data?.data);
      });
  };
  const navigate = useNavigate();
  const logoutAdmin = () => {
    axios
      .get(`${BACKEND_BASE_URL}admin/logout`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        sessionStorage.removeItem("admin_access_token");
        sessionStorage.removeItem("LOGGED_IN_USER_ID");
        sessionStorage.removeItem("LOGGED_IN_USER_NAME");
        Swal.fire({
          icon: "success",
          text: res.data.message,
          confirmButtonColor: "#5eba86",
        });
        navigate("/admin/login");
      });
  };
  useEffect(() => {
    renderProfileInfo();
  }, []);

  return (
    <div className="topbar">
      <div className="row gx-0 justify-content-end searchbox ">
        <div className="col-md-10 col-xl-6">
          <div className="row gx-0 d-flex justify-content-end align-items-center">
            {/* <div className="col-md-7 col-xl-8">
              <Form>
                <Form.Group className="position-relative">
                  <Form.Control
                    type="text"
                    placeholder="Search for projects and items"
                    className="form_control"
                  />
                  <BsIcons.BsSearch size="1.2em" className="search_icon" />
                </Form.Group>
              </Form>
            </div> */}
            <div className="col-md-5 col-xl-4 flex_around">
              {/* <AiIcons.AiOutlineInfoCircle size="1.5em" />
              <BsIcons.BsGift size="1.5em" /> */}

              <NavDropdown
                onClick={() => setShow(!show)}
                className="admin_profile_dropdown position-relative"
                id="collasible-nav-dropdown"
                title={
                  <div className={`admin_profile_logo`}>
                    {files.length == 0 &&
                      (profileInfo != null &&
                      profileInfo?.profile_photo != null ? (
                        <img
                          width={50}
                          src={`${fileUrl}${profileInfo?.profile_photo}`}
                          alt=""
                          name="img"
                          className="img-fluid"
                        />
                      ) : (
                        ""
                      ))}
                  </div>
                }
              >
                <NavDropdown.Item
                  as={Link}
                  to="/admin/profile"
                  className=" t-fs-18 cl-333"
                >
                  Profile
                </NavDropdown.Item>
                <hr className="m-0" />

                <NavDropdown.Item
                  as={Link}
                  onClick={logoutAdmin}
                  to="/admin"
                  className="t-fs-18 cl-333"
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardNavbar;
