import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import "./userProfileDashboard.css";
import { AiFillHome, AiOutlineMenu } from "react-icons/ai";
import { BsPencilFill } from "react-icons/bs";
import { MdNotifications } from "react-icons/md";
import { BiTimeFive } from "react-icons/bi";
import { HiLockClosed } from "react-icons/hi";
import "./userProfileDashboard.css";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../../Utility/GlobalVariable/GlobalVariables";
import axios from "axios";
import { ToastSuccess } from "../../../Components/ToastAlert";
// import { BACKEND_BASE_URL } from "../Component/GlobalVariables";

const UserProfile2 = () => {
  document.title = "User Panel | Code Pixel";
  const [toggleNav, setToggleNav] = useState(false);

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  const [profileInfo, setProfileInfo] = useState([]);
  // Get user Info
  const renderUserInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}user/profile`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "user_access_token"
          )}`,
        },
      })
      .then((res) => {
        setProfileInfo(res.data?.data);
      });
  };

  const navigate = useNavigate();
  const logoutUser = () => {
    axios
      .get(`${BACKEND_BASE_URL}user/logout`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem(
            "user_access_token"
          )}`,
        },
      })
      .then((res) => {
        sessionStorage.removeItem("user_access_token");
        sessionStorage.removeItem("LOGGED_IN_USER_ID");
        sessionStorage.removeItem("LOGGED_IN_USER_NAME");
        ToastSuccess.fire({
          icon: "success",
          title: res.data?.message,
        });
        navigate("/user/login");
      });
  };
  useEffect(() => {
    renderUserInfo();
  }, []);

  return (
    <div style={{ backgroundColor: "#1c273b" }}>
      <div className="user_profile_navbar ">
        <Navbar expand="lg" className="custom_container">
          <Navbar.Brand href="/">
            <img
              src={require("../../../Assets/Common/logo_long.png")}
              alt=""
              className="img-fluid ms-2"
              width={185}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto text-white">
              <Nav.Link href="#home" className="me-3">
                Support
              </Nav.Link>
              <Nav.Link href="/user/downloads" className="me-3">
                <span>Downloads</span>
              </Nav.Link>
              <div className="user_profile_text logo_img_wrapper me-3">
                {profileInfo != null && profileInfo?.profile_photo != null ? (
                  <img
                    src={`${fileUrl}${profileInfo?.profile_photo}`}
                    alt="Profile"
                    className="img-fluid"
                  />
                ) : (
                  <img
                    src={require("../../../Assets/avatar.png")}
                    alt=""
                    className="w-100 h-100"
                  />
                )}
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="bg-transparent border-0"
                >
                  Profile
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item href="/user/favorites"> */}
                  <Dropdown.Item href="/user/">Profile</Dropdown.Item>
                  <Dropdown.Item href="/user/favorite-contents">
                    Favorites
                  </Dropdown.Item>
                  <Dropdown.Item href="/user/downloads">
                    Downloads
                  </Dropdown.Item>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <Dropdown.Item href="" onClick={logoutUser}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className="custom_container">
        <div className="d-flex user_profile_dashboard">
          <nav className={`user-profile-sidebar ${toggleNav ? "close" : ""}`}>
            <header className="my-5">
              <Link to="/" target="_blank" className="text-decoration-none">
                <div className="mx-4 d-flex justify-content-center align-items-center">
                  <div className="user_profile_text logo_img_wrapper">
                    {profileInfo != null &&
                    profileInfo?.profile_photo != null ? (
                      <img
                        src={`${fileUrl}${profileInfo?.profile_photo}`}
                        alt="Profile"
                        className="w-100 h-100"
                      />
                    ) : (
                      <img
                        src={require("../../../Assets/avatar.png")}
                        alt=""
                        className="w-100 h-100"
                      />
                    )}
                  </div>
                </div>
                <div className="flex_center mb-4"></div>
              </Link>

              <AiOutlineMenu
                className="bx bx-chevron-right toggle d-lg-none"
                onClick={() => setToggleNav(!toggleNav)}
              />
            </header>

            <div className="menu-bar">
              <div className="menu">
                <ul className="menu-links ps-0">
                  <NavLink to="/user/">
                    <li className={`nav-link`} title="Account Overview">
                      <AiFillHome className="user_profile_icon" />
                      <span className={`user_profile_text nav-text `}>
                        Account Overview
                      </span>
                    </li>
                  </NavLink>

                  <NavLink to="edit-profile">
                    <li className={`nav-link`} title="Edit Profile">
                      <BsPencilFill className="user_profile_icon" />
                      <span className={`user_profile_text nav-text `}>
                        Edit Profile
                      </span>
                    </li>
                  </NavLink>

                  <NavLink to="change-password" title="Change Password">
                    <li className={`nav-link`}>
                      <HiLockClosed className="user_profile_icon" />
                      <span className="user_profile_text  nav-text me-3">
                        Change Password
                      </span>
                    </li>
                  </NavLink>
                  <NavLink
                    to="notification-settings"
                    title="Notification Settings"
                  >
                    <li className={`nav-link`}>
                      <MdNotifications className="user_profile_icon" />
                      <span className="user_profile_text  nav-text me-3">
                        Notification Settings
                      </span>
                    </li>
                  </NavLink>

                  <NavLink to="receipts">
                    <li className={`nav-link`}>
                      <BiTimeFive className="user_profile_icon" />
                      <div className="d-none d-sm-block">
                        <span className="user_profile_text nav-text me-3">
                          Receipts
                        </span>
                      </div>
                    </li>
                  </NavLink>

                  {/* <Link to="" onClick={LogoutUser} className="user_logout">
                <li className="nav-link">
                  <BiLogOut className="user_profile_icon" />
                  <span className="user_profile_text nav-text">Logout</span>
                </li>
              </Link> */}
                </ul>
              </div>
            </div>
          </nav>
          <section className="user-profile-rightbar">
            <Outlet />
          </section>
        </div>
      </div>
    </div>
  );
};

export default UserProfile2;
