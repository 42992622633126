import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import * as FiIcons from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";

import Swal from "sweetalert2";
import { Button, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";

const VendorProductType = () => {
  const contentType_id = useRef();

  const [addInfo, setAddInfo] = useState([]);

  const renderAddInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}vendor/product/add-info`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "vendor_access_token"
          )}`,
        },
      })
      .then((res) => {
        setAddInfo(res?.data);
      });
  };

  const getValue = (e) => {
    sessionStorage.setItem("setSelectedContentTypeId", e.target.value);
    // console.log(e.target.value);
  };
  useEffect(() => {
    renderAddInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header text-center">Upload an Item</div>
                  {/* <Link to="/admin/product">
                    <Button variant="success" size="sm">
                      View All Products &nbsp;
                    </Button>
                  </Link> */}
                </div>

                <Form>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* File type */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Content Type
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              ref={contentType_id}
                              onChange={getValue}
                            >
                              <option value="">Select contentType</option>
                              {addInfo?.contentType?.map((data) => (
                                <option value={data?.id} key={data?.id}>
                                  {data.title}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <Link to="/vendor/content/upload/">
                              &nbsp; Next
                            </Link>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VendorProductType;
