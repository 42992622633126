import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../../Components/Common/Header/Header";
import Footer from "../../Components/Common/Footer/Footer";
import { useParams } from "react-router-dom";
import "../ProductDetails/ProductDetails.css";
import Parse from "html-react-parser";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../Utility/GlobalVariable/GlobalVariables";
import { DotLoader } from "../../Components/Custom Loader/CustomLoader";

const BlogDetails = () => {
  const { blogId } = useParams();
  //set single data
  const [singleData, setSingleData] = useState(null);
  // Get All graphics Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}blogs/show-single/${blogId}`)
      .then((res) => {
        setSingleData(res?.data?.data);
      });
  };
  // Image Preview
  // const [files, setFile] = useState([]);
  // const handleImgPreview = (e) => {
  //   let allfiles = [];
  //   for (let i = 0; i < e.target.files.length; i++) {
  //     allfiles.push(e.target.files[i]);
  //   }
  //   if (allfiles.length > 0) {
  //     setFile(allfiles);
  //   }
  // };
  useEffect(() => {
    window.scrollTo(0, 0);
    renderAllInfo();
  }, []);
  return (
    <>
      <Header />
      <div className="productDetails sesction_mt">
        <div className="custom_container container_fluid_md product_thumb">
          <Row className="">
            {/* left side product thumb & overview */}
            {singleData != null ? (
              <Col md={8} className="mb-4">
                <Row className="main_image sesction_mb px-3">
                  <div
                    className="border-0 rounded-0 w-100 single_team"
                    key={singleData?.id}
                  >
                    <div className="team_img ">
                      <img
                        className="w-100 border-0 rounded-1 mb-3"
                        src={`${fileUrl}${singleData?.image}`}
                        alt=""
                      />
                    </div>
                  </div>
                  <h2 className="" style={{ fontFamily: "sans-serif" }}>
                    {singleData?.title}
                  </h2>
                  <div
                    className="mt-4"
                    style={{ fontSize: "18px", fontFamily: "sans-serif" }}
                  >
                    {Parse(`${singleData?.desc}`)}
                  </div>
                </Row>
              </Col>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "80vh" }}
              >
                <DotLoader />
              </div>
            )}
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetails;
