import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Form,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

// import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";
import { ToastSuccess } from "../../../Components/ToastAlert";

const EmailVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let from = location.state?.from?.pathname || "/user/login";

  const UserEmail = useRef();
  const otp = useRef();

  const handleEmailVerification = (e) => {
    const formdata = new FormData();
    formdata.append("email", UserEmail.current.value);
    formdata.append("otp", otp.current.value);
    
    axios
      .post(`${BACKEND_BASE_URL}user-mail-verify`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then((response) => {
        if (response?.data?.status == 400) {
          ToastSuccess.fire({
            icon: "warning",
            title: response.data?.message,
          });
        } else {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          navigate(from, { replace: true });
        }
      });
    e.preventDefault();
  };
  return (
    <>
      {/* <Header /> */}
      <div className="form_wrapper" style={{ backgroundColor: "#f9fafb" }}>
        <Container className="container">
          <Form id="form" className="form" onSubmit={handleEmailVerification}>
            <h1>Email verification</h1>
            <p>Please check your email to see verification code!</p>

            {/* ================== Email =================== */}
            <Form.Group className="form_group">
              <Form.Label>
                Email <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                required
                type="email"
                id="email"
                placeholder="Enter email"
                name="email"
                ref={UserEmail}
              />
            </Form.Group>
            <Form.Group className="form_group">
              <Form.Label>
                Verification code <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                required
                type="number"
                id="otp"
                placeholder="Enter code which we have mailed you"
                name="otp"
                ref={otp}
              />
            </Form.Group>

            <Button type="submit" className="w-100 login_btn">
              Verify
            </Button>
          </Form>
        </Container>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default EmailVerification;
