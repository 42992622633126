import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";

const ContentType = () => {
  const contentType_id = useRef();
  const navigate = useNavigate();

  const [addInfo, setAddInfo] = useState([]);
  const renderAddInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/content/add-info`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setAddInfo(res?.data?.data);
      });
  };

  const [contentCat_id, setContentCat_id] = useState();
  const getValue = (e) => {
    setContentCat_id(e.target.value);
  };

  const submitForm = () => {
    if (contentCat_id == 1) {
      navigate(`/admin/content/graphics-template/upload/${contentCat_id}`);
    }
    if (contentCat_id == 2) {
      navigate(`/admin/content/web-template/upload/${contentCat_id}`);
    }
    if (contentCat_id == 3) {
      navigate(`/admin/content/video-template/upload/${contentCat_id}`);
    }
  };

  useEffect(() => {
    renderAddInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header text-center">Upload an Item</div>
                  {/* <Link to="/admin/product">
                    <Button variant="success" size="sm">
                      View All Products &nbsp;
                    </Button>
                  </Link> */}
                </div>

                <Form onSubmit={submitForm}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* File type */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Content Category
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              required
                              ref={contentType_id}
                              onChange={getValue}
                            >
                              <option value="">Select content Category</option>
                              {addInfo?.map((data) => (
                                <option value={data?.id} key={data?.id}>
                                  {data.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            &nbsp; Next
                          </Button>

                          {/* {sessionStorage.getItem("setSelectedContentTypeId") ==
                            1 && (
                            <Link to="/admin/content/graphics/upload/">
                              <Button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                &nbsp; Next
                              </Button>
                            </Link>
                          )}
                          {sessionStorage.getItem("setSelectedContentTypeId") ==
                            2 && (
                            <Link to="/admin/content/webTemplate/upload/">
                              <Button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                &nbsp; Next
                              </Button>
                            </Link>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContentType;
