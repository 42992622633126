import React, { useState } from "react";
// import "./AllBlog.css";
import { Accordion, Col, Form, Pagination, Row } from "react-bootstrap";
import Footer from "../../Components/Common/Footer/Footer";
import Header2 from "../../Components/Common/Header/Header2";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../Utility/GlobalVariable/GlobalVariables";
import { useEffect } from "react";
import axios from "axios";

const SearchedProduct = () => {
  const { state } = useLocation();

  // const [isLoading, setIsLoading] = useState(false);
  // location.state;
  // console.log("sp", state);
  const [active, setActive] = useState(1);
  let items = [];
  for (let number = 1; number <= 8; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={() => setActive(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    // renderCategory();
  }, []);

  return (
    <>
      <Header2 />
      <div className="product">
        <div className="sesction_pt  sesction_pb">
          <div className="custom_container ">
            <h2 className="ms-4">Searched Content</h2>
            {state.length > 0 ? (
              <div className="grid font_size_21 font_oswald cl_dark_hover underline_none ">
                {/* jodi data pay */}
                {/* {blogData?.length > 0 && */}

                <>
                  {state?.map((state, i) => (
                    <div className="image" key={i}>
                      <Link to={`/content-details/${state.id}`}>
                        {files.length == 0 &&
                          (state != null && state?.thumbnail != null ? (
                            <img
                              style={{ height: "225px" }}
                              src={`${fileUrl}${state?.thumbnail}`}
                              alt="Sample photo"
                            />
                          ) : (
                            <img src="" alt="Sample photo" />
                          ))}
                      </Link>
                      {/* <p>{state?.title}</p> */}
                    </div>
                  ))}
                </>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <p className=""> No data</p>
              </div>
            )}
          </div>

          <div className=" d-flex justify-content-center mt-5">
            <Pagination className="paggination  py-3 px-5 flex-wrap">
              {items}
            </Pagination>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SearchedProduct;
