import { Link } from "react-router-dom";
import "./admindashboard.css";
import { TbLayoutNavbar } from "react-icons/tb";
import { BiCategory } from "react-icons/bi";
import { GiFiles } from "react-icons/gi";
import { FaProductHunt } from "react-icons/fa";
import { MdGroups, MdOutlineContentCopy } from "react-icons/md";
import { SiBloglovin } from "react-icons/si";
// import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";

const AdminDashboard = () => {
  return (
    <div className="">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}
        <div className="main_section">
          <div className="row py-3">
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-content">
                    {/* <h6 className="text-uppercase text widget-title">
                      <strong>Orders</strong>
                    </h6> */}
                    <p className="">
                      Start uploading your digital items and working with our
                      review team to make sure they are in great shape to start
                      selling.
                    </p>
                    <p>
                      <Link to="/admin/content/category" className="btn">
                        Upload Item
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div className="row py-3">
            {/* All Contents */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <MdOutlineContentCopy className="w-icon" />
                  </div>
                  <div className="widget-content mt-4">
                    <h6 className="text-uppercase text widget-title">
                      <strong>All Contents</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">1</h1>
                    <p>
                      <Link to="all-contents" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Content Category */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <BiCategory className="w-icon" />
                  </div>
                  <div className="widget-content mt-4">
                    <h6 className="text-uppercase text widget-title">
                      <strong> Content Category</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">2</h1>
                    <p>
                      <Link to="content-category" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Content Sub Category */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <BiCategory className="w-icon" />
                  </div>
                  <div className="widget-content mt-4">
                    <h6 className="text-uppercase text widget-title">
                      <strong> Content SubCategory</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">3</h1>
                    <p>
                      <Link to="content-sub-category" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Content Child Category */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <BiCategory className="w-icon" />
                  </div>
                  <div className="widget-content mt-4">
                    <h6 className="text-uppercase text widget-title">
                      <strong>Child Category</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">4</h1>
                    <p>
                      <Link to="content-child-category" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Content Blogs */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <SiBloglovin className="w-icon" />
                  </div>
                  <div className="widget-content mt-4">
                    <h6 className="text-uppercase text widget-title">
                      <strong>Blogs</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">5</h1>
                    <p>
                      <Link to="blogs" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Nav Items */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <TbLayoutNavbar className="w-icon" />
                  </div>
                  <div className="widget-content mt-4">
                    <h6 className="text-uppercase text widget-title">
                      <strong>Nav Items</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">6</h1>
                    <p>
                      <Link to="nav-items" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <BiCategory className="w-icon" />
                  </div>
                  <div className="widget-content mt-4">
                    <h6 className="text-uppercase text widget-title">
                      <strong>Category</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">3</h1>
                    <p>
                      <Link to="category" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}

            {/* FileType */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <GiFiles className="w-icon" />
                  </div>
                  <div className="widget-content mt-4">
                    <h6 className="text-uppercase text widget-title">
                      <strong>FileType</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">7</h1>
                    <p>
                      <Link to="file-type" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* All customer */}
            {/* <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <MdGroups className="w-icon" />
                  </div>
                  <div className="widget-content mt-4">
                    <h6 className="text-uppercase text widget-title">
                      <strong>All Customers</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">5</h1>
                    <p>
                      <Link to="all-customers" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
