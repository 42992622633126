import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import * as FiIcons from "react-icons/fi";

import Swal from "sweetalert2";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";
import { ToastSuccess } from "../../../Components/ToastAlert";

const AdminCategoryEdit = () => {
  const { catId } = useParams();
  const categoryName = useRef();
  const parentName = useRef();
  const [isactiveParentCategory, setIsactiveParentCategory] = useState(false);
  // Initial table data
  const [singleData, setSingleData] = useState([]);
  // Get Single Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/category/show-single/${catId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setSingleData(res.data?.data);
      });
  };

  // Initial table data
  const [catTableData, setCatTableData] = useState([]);
  // Get All Info
  const renderCatInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/category`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setCatTableData(res.data?.data);
      });
  };

  // Updated data to backend
  const updatedData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("name", categoryName.current.value);

    {
      isactiveParentCategory &&
        formdata.append("parent_id", parentName.current.value);
    }

    axios
      .post(
        `${BACKEND_BASE_URL}admin/category/update/${singleData?.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();
          renderAllInfo();
        } else {
          ToastSuccess.fire({
            icon: "warning",
            text: response.data.errors.name[0],
            title: response.data?.message,
          });
        }
      });

    e.preventDefault();
  };
  useEffect(() => {
    renderAllInfo();
    renderCatInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">Category Update</div>
                  <Link to="/admin/brand">
                    <Button variant="success" size="sm">
                      View All Category &nbsp;
                    </Button>
                  </Link>
                </div>
                <div className="">
                  <Form onSubmit={updatedData}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Check
                                onClick={() =>
                                  setIsactiveParentCategory(
                                    !isactiveParentCategory
                                  )
                                }
                                type="switch"
                                id="custom-switch"
                                label="Sub Category ?"
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Name
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                ref={categoryName}
                                defaultValue={singleData?.name}
                              />
                            </Form.Group>
                            {
                              // (singleData.parent) &&

                              isactiveParentCategory && (
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="validationCustom01"
                                  className="mb-3"
                                >
                                  <Form.Label className="label fw-bold">
                                    Parent Name
                                  </Form.Label>

                                  <Form.Select
                                    aria-label="Default select example"
                                    ref={parentName}
                                  >
                                    <option value="0">
                                      Select Parent Category
                                    </option>

                                    {catTableData.map(
                                      (data) =>
                                        !data.parent &&
                                        singleData.id !== data.id && (
                                          <option
                                            value={data?.id}
                                            key={data.id}
                                            selected={
                                              data.id == singleData?.parent?.id
                                                ? "selected"
                                                : ""
                                            }
                                          >
                                            {data.name}
                                          </option>
                                        )
                                    )}
                                  </Form.Select>
                                </Form.Group>
                              )
                            }
                          </div>

                          <div className="form-group mb-3">
                            <div className="col-md-6">
                              <Button
                                type="submit"
                                className="btn btn-success mt-5 rounded-3 border-0"
                              >
                                <FiIcons.FiSave /> &nbsp; Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminCategoryEdit;
