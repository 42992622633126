import axios from "axios";
import React, { useRef, useState } from "react";
import {
  Button,
  Container,
  Form,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";
// import { BACKEND_BASE_URL } from "../../Utils/GlobalVariables";
// import "./VendorLogin.css";

const CreateShop = () => {
  const shopName = useRef();
  const shopDescription = useRef();

  const navigate = useNavigate();
  const location = useLocation();
  let from = location.state?.from?.pathname || "/vendor";

  const handleSubmit = (e) => {
    let vendorId;
    vendorId = sessionStorage.getItem("vendorId");
    const formdata = new FormData();
    formdata.append("name", shopName.current.value);
    formdata.append("vendorId", vendorId);
    formdata.append("description", shopDescription.current.value);
    axios
      .post(`${BACKEND_BASE_URL}vendor/shop/create`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem(
            "vendor_access_token"
          )}`,
        },
      })
      .then((response) => {
        if (response?.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response?.data?.message,
            confirmButtonColor: "#5eba86",
          });
          navigate(from, { replace: true });
          e.target.reset();
        } else {
          Swal.fire({
            icon: "warning",
            text: response?.data?.message,
            confirmButtonColor: "#5eba86",
          });
          navigate(from, { replace: true });
        }
      });
    e.preventDefault();
  };
  return (
    <div className="form_wrapper" style={{ backgroundColor: "#f9fafb" }}>
      <Container className="container">
        <Form id="form" className="form" onSubmit={handleSubmit}>
          <h1>Create Shop</h1>

          <Form.Group className="form_group">
            <Form.Label>
              Shop Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              id="email"
              placeholder="Enter shop name"
              name="shop"
              ref={shopName}
            />
          </Form.Group>

          <Form.Group className="form_group">
            <Form.Label>
              Description <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              id="email"
              placeholder="Enter shop description"
              name="shopDesc"
              ref={shopDescription}
            />
          </Form.Group>

          <Button type="submit" className="w-100 login_btn">
            Create
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default CreateShop;
