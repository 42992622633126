import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import Swal from "sweetalert2";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../Utility/GlobalVariable/GlobalVariables";
// import { BACKEND_BASE_URL, fileUrl } from "../../../Utils/GlobalVariables";

const VendorProfile = () => {
  const name = useRef();
  // const email = useRef();
  const phone = useRef();
  const current_password = useRef();
  const new_password = useRef();
  const confirm_password = useRef();
  const address = useRef();
  const profile_photo = useRef();

  // Initial table data
  const [profileInfo, setProfileInfo] = useState([]);
  const [errors, setErrors] = useState([]);
  const [message, setMessage] = useState([]);

  // console.log(errors);

  // Image Preview
  const [files, setFile] = useState([]);

  const [files2, setFile2] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  const handleImgPreview2 = (e) => {
    let allfiles2 = [];
    // console.log("allfiles2", allfiles2);
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles2.push(e.target.files[i]);
    }
    if (allfiles2.length > 0) {
      setFile2(allfiles2);
    }
  };

  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}vendor/profile`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "vendor_access_token"
          )}`,
        },
      })
      .then((res) => {
        setProfileInfo(res.data?.data);
      });
  };
  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();

    formdata.append("name", name.current.value);
    formdata.append("phone", phone.current.value);
    formdata.append("current_password", current_password.current.value);
    formdata.append("new_password", new_password.current.value);
    formdata.append("confirm_password", confirm_password.current.value);
    formdata.append("address", address.current.value);

    if (profile_photo.current.files[0]) {
      formdata.append("profile_photo", profile_photo.current.files[0]);
    }

    //admin profile update api
    axios
      .post(`${BACKEND_BASE_URL}vendor/profile-update`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem(
            "vendor_access_token"
          )}`,
        },
      })

      .then((response) => {
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
          renderAllInfo();
        } else {
          setErrors(response.data?.errors);
          setMessage(response.data?.message);
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    renderAllInfo();
    // renderAddInfo();
  }, []);

  // console.log(singleData);

  return (
    <div className="main__container">
      <div className="body-wrapper ">
        {/* <AdminDashboardNavbar /> */}
        <Container className="p-4">
          <div
            className="bg-white p-3 content-wrapper"
            style={{ borderRadius: "8px" }}
          >
            <Form onSubmit={storeData}>
              <h2 className="my-4 text-center">Vendor Profile</h2>

              <Row className="mb-4 flex_center">
                <Col md={3}>
                  <Form.Group>
                    <Form.Label className="mb-0">
                      Name: <span className="text-danger">*</span>
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={9}>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    defaultValue={profileInfo.name}
                    ref={name}
                  />
                </Col>
              </Row>
              <Row className="mb-4 flex_center">
                <Col md={3}>
                  <Form.Group>
                    <Form.Label className="mb-0">Email:</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={9}>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    disabled
                    defaultValue={profileInfo?.email}
                  />
                </Col>
              </Row>

              <div className="">
                <Row className="mb-4 flex_center">
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label className="mb-0">
                        Current Password: <span className="text-danger">*</span>
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="password"
                      placeholder="password"
                      // defaultValue={profileInfo?.password}
                      // disabled
                      ref={current_password}
                    />
                    <p className="text-danger">{errors?.current_password}</p>
                    <p className="text-danger">{message}</p>
                  </Col>
                </Row>
                <Row className="mb-4 flex_center">
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label className="mb-0">
                        New Password: <span className="text-danger">*</span>
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="password"
                      placeholder="new password"
                      defaultValue=""
                      ref={new_password}
                      // disabled
                    />
                    <p className="text-danger">{errors?.new_password}</p>
                  </Col>
                </Row>
                <Row className="mb-4 flex_center">
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label className="mb-0">
                        Confirm Password: <span className="text-danger">*</span>
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="password"
                      placeholder="confirm password"
                      defaultValue=""
                      ref={confirm_password}
                      // disabled
                    />
                    <p className="text-danger">{errors?.confirm_password}</p>
                  </Col>
                </Row>
                <Row className="mb-4 flex_center">
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label className="mb-0">Profile Photo</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="file"
                      ref={profile_photo}
                      onChange={handleImgPreview}
                    />
                    {files.map((file, key) => {
                      return (
                        <div key={key} className="Row">
                          <span className="Filename">
                            <img
                              width={80}
                              height={50}
                              src={URL.createObjectURL(file)}
                              alt={file.name}
                            />
                          </span>
                        </div>
                      );
                    })}
                    {files.length == 0 && (
                      <img
                        width={80}
                        src={`${fileUrl}${profileInfo?.profile_photo}`}
                        alt="Profile picture"
                        name="img"
                        className="img-thumbnail me-2 my-2"
                      />
                    )}
                  </Col>
                </Row>
                <Row className="mb-4 flex_center">
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label className="mb-0">
                        Phone Number: <span className="text-danger">*</span>
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={9}>
                    <InputGroup className="">
                      <InputGroup.Text id="basic-addon1">+880</InputGroup.Text>
                      <Form.Control
                        placeholder="Phone"
                        aria-label="Phone"
                        defaultValue={profileInfo?.phone}
                        ref={phone}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </div>

              {/* <h2 className="my-4">Street Information</h2> */}

              <Row className="mb-4">
                <Col md={3}>
                  <Form.Group>
                    <Form.Label className="mb-0">
                      Address: <span className="text-danger">*</span>
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={9}>
                  <Form.Control
                    as="textarea"
                    type="textarea"
                    rows={3}
                    placeholder="Enter your address"
                    className="mb-2"
                    defaultValue={profileInfo?.address}
                    ref={address}
                  />
                </Col>
              </Row>

              <Button
                type="submit"
                className="btn-submit mt-5 rounded-3 border-0 d-flex justify-content-center"
              >
                Save
              </Button>
            </Form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default VendorProfile;
