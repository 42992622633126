import React, { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import AdminFooter from "./AdminFooter";
import "./AdminContent.css";
import AdminDashboardNavbar from "./AdminDashboardNavbar";
import { BACKEND_BASE_URL } from "../../Utility/GlobalVariable/GlobalVariables";
import axios from "axios";
import Swal from "sweetalert2";
import { AiOutlineMenu } from "react-icons/ai";
import { GiFiles } from "react-icons/gi";
import { MdDashboard, MdOutlineContentCopy } from "react-icons/md";
import { TbBrandProducthunt, TbLayoutNavbar } from "react-icons/tb";
import { BiCategory, BiLogOut } from "react-icons/bi";
import { SiBloglovin } from "react-icons/si";

const AdminContent = () => {
  const [toggleNav, setToggleNav] = useState(false);
  const [clickState, setClickState] = useState();

  const ToggleClass = (index) => {
    setClickState(index);
  };
  const navigate = useNavigate();
  const logoutAdmin = () => {
    axios
      .get(`${BACKEND_BASE_URL}admin/logout`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        sessionStorage.removeItem("admin_access_token");
        sessionStorage.removeItem("LOGGED_IN_USER_ID");
        sessionStorage.removeItem("LOGGED_IN_USER_NAME");
        Swal.fire({
          icon: "success",
          text: res.data.message,
          confirmButtonColor: "#5eba86",
        });
        navigate("/admin/login");
      });
  };

  return (
    <div className="d-flex admin__dashboard">
      <nav className={`admin_sidebar ${toggleNav ? "close" : ""}`}>
        <header className="mt-4 mb-5">
          <Link to="/" target="_blank" className="text-decoration-none">
            <div className="mx-4 image-text d-none d-md-flex align-items-center justify-content-between">
              <div className="text logo_img_wrapper">Admin Dashboard</div>
            </div>
          </Link>
          <AiOutlineMenu
            className="bx bx-chevron-right toggle d-none d-md-block"
            onClick={() => setToggleNav(!toggleNav)}
          />
        </header>

        <div className="menu-bar">
          <div className="menu">
            <ul className="menu-links ps-0">
              <li
                className={`nav-link border-bottom ${
                  clickState == 1 ? "activeLink" : ""
                }`}
              >
                <Link to="/admin" onClick={() => ToggleClass(1)}>
                  <MdDashboard className="icon" />
                  <span className="text nav-text text-uppercase">
                    Dashboard
                  </span>
                </Link>
              </li>
              <li
                className={`nav-link border-bottom ${
                  clickState == 2 ? "activeLink" : ""
                }`}
              >
                <Link to="blogs" onClick={() => ToggleClass(2)}>
                  <SiBloglovin className="icon" />
                  <span className="text nav-text text-uppercase">Blogs</span>
                </Link>
              </li>
              <li
                className={`nav-link border-bottom ${
                  clickState == 7 ? "activeLink" : ""
                }`}
              >
                <Link to="content-category" onClick={() => ToggleClass(7)}>
                  <BiCategory className="icon" />
                  <span className="text nav-text text-uppercase">
                    Content Category
                  </span>
                </Link>
              </li>
              <li
                className={`nav-link border-bottom ${
                  clickState == 8 ? "activeLink" : ""
                }`}
              >
                <Link to="content-sub-category" onClick={() => ToggleClass(8)}>
                  <BiCategory className="icon" />
                  <span className="text nav-text text-uppercase">
                    Content SubCategory
                  </span>
                </Link>
              </li>
              <li
                className={`nav-link border-bottom ${
                  clickState == 9 ? "activeLink" : ""
                }`}
              >
                <Link
                  to="content-child-category"
                  onClick={() => ToggleClass(9)}
                >
                  <BiCategory className="icon" />
                  <span className="text nav-text text-uppercase">
                    Content Child Category
                  </span>
                </Link>
              </li>

              <li
                className={`nav-link border-bottom ${
                  clickState == 3 ? "activeLink" : ""
                }`}
              >
                <Link to="nav-items" onClick={() => ToggleClass(3)}>
                  <TbLayoutNavbar className="icon" />
                  <span className="text nav-text text-uppercase">Navbar</span>
                </Link>
              </li>

              {/* <li
                className={`nav-link border-bottom ${
                  clickState == 4 ? "activeLink" : ""
                }`}
              >
                <Link to="category" onClick={() => ToggleClass(4)}>
                  <BiCategory className="icon" />
                  <span className="text nav-text text-uppercase">Category</span>
                </Link>
              </li> */}
              <li
                className={`nav-link border-bottom ${
                  clickState == 5 ? "activeLink" : ""
                }`}
              >
                <Link to="file-type" onClick={() => ToggleClass(5)}>
                  <GiFiles className="icon" />
                  <span className="text nav-text text-uppercase">
                    File Type
                  </span>
                </Link>
              </li>
              <li
                className={`nav-link border-bottom ${
                  clickState == 6 ? "activeLink" : ""
                }`}
              >
                <Link to="all-contents" onClick={() => ToggleClass(6)}>
                  <MdOutlineContentCopy className="icon" />
                  <span className="text nav-text text-uppercase">
                    All Contents
                  </span>
                </Link>
              </li>

              <li className="nav-link">
                <Link to="" className="user_logout" onClick={logoutAdmin}>
                  <BiLogOut className="icon" />
                  <span className="text nav-text text-uppercase">Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section className="rightbar">
        {/* <div className="topbar" /> */}
        <section style={{ marginBottom: "100px" }}>
          <AdminDashboardNavbar />
          <Outlet />
        </section>
      </section>
      {/* <AdminFooter /> */}
    </div>
  );
};

export default AdminContent;
