import React from "react";

const AdminFooter = () => {
  return (
    <div
      className="copyright d-flex align-items-center justify-content-center"
      style={{
        background: "#e8e8e8",
      }}
    >
      <span className="p-3">
        Copyright &copy; 2022 TRODAD. All rights reserved.
      </span>
    </div>
  );
};

export default AdminFooter;
