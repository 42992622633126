import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { Button, Col, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../../Utility/GlobalVariable/GlobalVariables";
import { FiSave } from "react-icons/fi";
import { ToastSuccess } from "../../../Components/ToastAlert";

const ContentChildCategoryEdit = () => {
  const { childCatId } = useParams();
  const name = useRef();
  const category_id = useRef();
  const sub_category_id = useRef();
  const image = useRef();

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  // Initial add info
  const [addInfo, setAddInfo] = useState([]);
  // const [addInfo1, setAddInfo1] = useState([]);
  // Get add Info
  const renderAddInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/content-child-category/store-info`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setAddInfo(res.data?.data);
        // setAddInfo1(res.data?.data1);
      });
  };

  const [subCategoryValue, setSubcategoryValue] = useState([]);
  const SubCat = (e) => {
    for (let i = 0; i < addInfo?.length; i++) {
      if (addInfo[i]?.id == e.target.value) {
        setSubcategoryValue(addInfo[i]);
      }
    }
  };

  // Initial table data
  const [singleData, setSingleData] = useState(null);
  const [subCat, setSubCat] = useState();
  // Get Single Info
  const renderAllInfo = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}admin/content-child-category/show-single/${childCatId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${sessionStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((res) => {
        setSingleData(res.data?.data);
        setSubCat(res.data?.subCat);
      });
  };
  // Updated data to backend
  const updatedData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("name", name.current.value);
    formdata.append("category_id", category_id.current.value);
    formdata.append("sub_category_id", sub_category_id.current.value);

    if (image.current.files[0]) {
      formdata.append("image", image.current.files[0]);
    }
    axios
      .post(
        `${BACKEND_BASE_URL}admin/content-child-category/update/${singleData?.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${sessionStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();
          setFile([]);
          renderAllInfo();
        } else {
          ToastSuccess.fire({
            icon: "warning",
            text: response.data.errors.name[0],
            title: response.data?.message,
          });
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    renderAllInfo();
    renderAddInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">
                    Content ChildCategory Update
                  </div>
                  <Link to="/admin/content-child-category">
                    <Button variant="success" size="sm">
                      View All &nbsp;
                    </Button>
                  </Link>
                </div>

                <div className="">
                  <Form onSubmit={updatedData}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/* name  */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Name
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                ref={name}
                                defaultValue={singleData?.name}
                              />
                            </Form.Group>
                            {/*  Category */}
                            <Form.Group
                              as={Col}
                              md="3"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Category
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                ref={category_id}
                                onChange={SubCat}
                              >
                                {addInfo?.map((data) => (
                                  <option
                                    value={data?.id}
                                    key={data.id}
                                    selected={
                                      data?.id ==
                                      singleData?.content_categories?.id
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {data?.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                            {/* Sub Category */}
                            {subCategoryValue?.content_sub_cat?.length > 0 ? (
                              <Form.Group
                                as={Col}
                                md="3"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Sub Category
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  ref={sub_category_id}
                                >
                                  <option value="">Select Sub Category</option>
                                  {subCategoryValue?.content_sub_cat?.map(
                                    (data) => (
                                      <option
                                        value={data?.id}
                                        key={data.id}
                                        selected={
                                          data?.id ==
                                          singleData?.content_sub_categories?.id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {data?.name}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                              </Form.Group>
                            ) : (
                              <Form.Group
                                as={Col}
                                md="3"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Sub Category
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  ref={sub_category_id}
                                >
                                  {subCat?.map((data) => (
                                    <option
                                      value={data?.id}
                                      key={data.id}
                                      selected={
                                        data?.id ==
                                        singleData?.content_sub_categories?.id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {data?.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            )}
                          </div>

                          {/* image */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Image &nbsp;{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Control
                              type="file"
                              ref={image}
                              onChange={handleImgPreview}
                            />

                            {files.map((file, key) => {
                              return (
                                <div key={key} className="Row">
                                  <span className="Filename">
                                    <img
                                      width={80}
                                      height={50}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                    />
                                  </span>
                                </div>
                              );
                            })}
                            {files.length == 0 &&
                              (singleData != null ? (
                                <img
                                  className="img-thumbnail mt-1"
                                  width={80}
                                  height={50}
                                  src={`${fileUrl}${singleData?.image}`}
                                  alt=""
                                  name="img"
                                />
                              ) : (
                                ""
                              ))}

                            <Form.Control.Feedback type="invalid">
                              Please choose an image
                            </Form.Control.Feedback>
                          </Form.Group>

                          <div className="form-group mb-3">
                            <div className="col-md-6">
                              <Button
                                type="submit"
                                className="btn btn-success mt-5 rounded-3 border-0"
                              >
                                <FiSave /> &nbsp; Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContentChildCategoryEdit;
