import React from "react";
import { Badge, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";
import footerLogo from "../../../Assets/Common/logo2_footer.png";
import card from "../../../Assets/Common/card.png";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { HiChevronDoubleUp } from "react-icons/hi";

const Footer = () => {
  const today = new Date();
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="footer footer_bg" id="contact">
      <div className="text-center pt-3">
        <HiChevronDoubleUp
          size={40}
          className="cl_light_grey pointer"
          onClick={goToTop}
        />
      </div>
      <div className="custom_container sesction_pt">
        <Row className="justify-content-between sesction_pb">

          <Col xl={3} lg={4} md={4} sm={6} className="mb-5">
            <div className="single_footer">
              <div className="title">
                <Form>
                  <Form.Select
                    aria-label="Default select example"
                    className="mb-4"
                  >
                    <option value="1">English</option>
                    <option value="2">Bangla</option>
                    <option value="2">Indian</option>
                    <option value="3"></option>
                  </Form.Select>
                  <Form.Select
                    aria-label="Default select example"
                    className="mb-4"
                  >
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Form>
                <Link to="">
                  <img
                    className="mb-3 img-fluid pointer card_logo"
                    src={card}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </Col>
          <Col xl={3} lg={4} md={4} sm={6} className="mb-5">
            <div className="single_footer d-flex justify-content-md-center justify-content-sm-center">
              <div>
                <div className="title">
                  <h4 className="mb-4 cl_white font_size_20">Discover</h4>
                  <ul className="p-0 ls_none">
                    <li className="cl_light_grey mb-2">
                      <Link
                        to=""
                        className="transition_3 cl_light_grey underline_none"
                      >
                        Royalty-Free License
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to=""
                        className="transition_3 cl_light_grey underline_none"
                      >
                        User Guide
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to=""
                        className="transition_3 cl_light_grey underline_none"
                      >
                        Pricing
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to=""
                        className="transition_3 cl_light_grey underline_none"
                      >
                        Subscription
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to=""
                        className="transition_3 cl_light_grey underline_none"
                      >
                        Tutorials
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="title">
                  <h4 className="mb-4 cl_white font_size_20">Free</h4>
                  <ul className="p-0 ls_none">
                    <li className="cl_light_grey mb-2">
                      <Link
                        to=""
                        className="transition_3 cl_light_grey underline_none"
                      >
                        Free Downloads
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to=""
                        className="transition_3 cl_light_grey underline_none"
                      >
                        Free Banner Genarator
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to=""
                        className="transition_3 cl_light_grey underline_none"
                      >
                        Free Video Converter
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={3} lg={4} md={4} sm={6} className="mb-5">
            <div className="single_footer  d-flex justify-content-md-center">
              <div className="title">
                <h4 className="mb-4 cl_white font_size_20">
                  Contribute & Earn
                </h4>
                <ul className="p-0 ls_none">
                  <li className="cl_light_grey mb-2">
                    <Link
                      to=""
                      className="transition_3 cl_light_grey underline_none"
                    >
                      Sell Your Creation
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      to=""
                      className="transition_3 cl_light_grey underline_none"
                    >
                      Affiliate Program
                      <Badge className="ms-1" bg="warning" text="dark">
                        30%
                      </Badge>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xl={3} lg={4} md={4} sm={6} className="mb-5">
            <div className="single_footer  d-flex justify-content-xl-end justify-content-md-start justify-content-sm-center">
              <div className="title">
                <Link to="">
                  <img className="mb-3 img-fluid" src={footerLogo} alt="" />
                </Link>
                <ul className="p-0 ls_none">
                  <li className="cl_light_grey mb-2">
                    <Link
                      to=""
                      className="transition_3 cl_light_grey underline_none"
                    >
                      About
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      to=""
                      className="transition_3 cl_light_grey underline_none"
                    >
                      BLog
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      to=""
                      className="transition_3 cl_light_grey underline_none"
                    >
                      Carrers{" "}
                      <Badge className="ms-1" bg="info" text="dark">
                        We're hiring
                      </Badge>
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      to=""
                      className="transition_3 cl_light_grey underline_none"
                    >
                      Help Center
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      to=""
                      className="transition_3 cl_light_grey underline_none"
                    >
                      Partners
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      to=""
                      className="transition_3 cl_light_grey underline_none"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      to=""
                      className="transition_3 cl_light_grey underline_none"
                    >
                      Terms of Use
                    </Link>
                  </li>
                </ul>
                <ul className="social_icon d-flex flex-wrap ls_none px-0">
                  <li className="me-3">
                    <FaFacebookF className="cl_white" size={20} />
                  </li>
                  <li className="me-3">
                    <FaInstagram className="cl_white" size={20} />
                  </li>
                  <li className="me-3">
                    <FaLinkedinIn className="cl_white" size={20} />
                  </li>
                  <li className="me-3">
                    <FaYoutube className="cl_white" size={20} />
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          
        </Row>
        <Row>
          <Col>
            <div className="footer-border" />
          </Col>
          <Col xs={12}>
            {/* <div className="footer_copyright cl_white py-3 text-center">
                            Copyright ©2022 All rights reserved
                        </div> */}
            <div className="d-flex flex-wrap justify-content-between cl_white py-3">
              <p className="me-3 mb-0 font_size_14">
                Copyright © {today.getFullYear()} Template. All rights reserved.
              </p>
              <p className="mb-0 font_size_14">
                DEVELOPED BY
                <a
                  className="ms-2 cl_white_hover underline_none fw_700"
                  href="https://sarfaa.com/"
                  target="_blank"
                >
                  SARFAA
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
