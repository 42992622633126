import React, { useState } from "react";
import "./CatWiseProduct.css";
import {
  Accordion,
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Footer from "../../Components/Common/Footer/Footer";
import Slider from "react-slick";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { AiFillHeart, AiOutlineDownload } from "react-icons/ai";
import { MdTune } from "react-icons/md";
import Header2 from "../../Components/Common/Header/Header2";
import { Link, useParams } from "react-router-dom";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../Utility/GlobalVariable/GlobalVariables";
import { useEffect } from "react";
import axios from "axios";
import {
  DotLoader,
  PulseLoader,
} from "../../Components/Custom Loader/CustomLoader";
import { Pagination } from "@mui/material";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FaChevronRight
      className={className}
      style={{
        ...style,
        // display: "block",
        // background: "#ffffff59",
        // color: "black",
        // width: 35,
        // height: 50,
        // zIndex: 9,
        // right: -15,
        // borderRadius: "10px",
        // padding: 12,
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FaChevronLeft
      className={className}
      style={{
        ...style,
        // display: "block",
        // background: "#ffffff59",
        // color: "black",
        // width: 35,
        // height: 50,
        // zIndex: 9,
        // left: -15,
        // borderRadius: "10px",
        // padding: 12,
      }}
      onClick={onClick}
    />
  );
}

const CatWiseProduct = () => {
  document.title = "Contents | Code Pixel";
  const { slug } = useParams();
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipe: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          swipe: true,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const [firstPage, setFirstPage] = useState([]);
  const [lastPage, setLastPage] = useState([]);
  const [page, setPage] = useState(1);

  const [fliterActive, setFliterActive] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  //all catSubCatData data
  const [catSubCatData, setCatSubCatData] = useState([]);
  // Get All catSubCatData Info
  const renderAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${BACKEND_BASE_URL}product/cat_subCat_Contents/${slug}?page=${page}`
      )
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setCatSubCatData(res.data?.data?.data);

        setFirstPage(res.data?.data?.from);
        setLastPage(res.data?.data?.last_page);
      });
  };
  const [categoryData, setCategory] = useState([]);
  // Get category Info
  const renderCategory = async () => {
    await axios.get(`${BACKEND_BASE_URL}product/category`).then((res) => {
      setCategory(res.data?.category);
    });
  };
  //category wise data filter
  const filterData = async (e) => {
    await axios
      .get(
        `${BACKEND_BASE_URL}product/cat-wise/${e.target.value}/${slug}?page=${page}`
      )
      .then((res) => {
        setCatSubCatData(res.data?.data?.data);

        setFirstPage(res.data?.data?.from);
        setLastPage(res.data?.data?.last_page);
      });
    e.preventDefault();
  };
  const [popularData, setPopularData] = useState([]);
  const renderPopularData = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}product/cat-wise-popular-data/${0}/${slug}`)
      .then((res) => {
        setPopularData(res.data?.data);
      });
  };
  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  const [bigFilterData, setBigFilterData] = useState([]);
  const handleBigFilter = (e) => {
    if (e.target.checked) {
      setBigFilterData([...bigFilterData, e.target.value]);
    } else {
      setBigFilterData(bigFilterData.filter((id) => id !== e.target.value));
    }
  };
  // console.log("big filter", bigFilterData);

  useEffect(() => {
    window.scrollTo(0, 0);
    renderAllInfo();
    renderCategory();
    renderPopularData();
  }, [slug, page]);

  return (
    <>
      <Header2 />
      <div className="catProduct">
        <div className="product">
          {catSubCatData?.length > 1 && (
            <div className="filter py-4">
              <div className="custom_container ">
                <Row className="justify-content-between align-items-center container">
                  <Col xl={3} lg={4} md={4} xs={6} className="">
                    <div
                      className="filter_icon pointer"
                      onClick={() => setFliterActive(!fliterActive)}
                    >
                      <MdTune />
                    </div>
                  </Col>
                  <Col xl={2} lg={4} md={4} xs={6} className="">
                    <div className="select_filter">
                      <Form.Select
                        aria-label="Default select example"
                        onChange={filterData}
                      >
                        <option value="" disabled selected>
                          Sort by
                        </option>
                        <option value="0">Popular</option>
                        <option value="1">Most Recents</option>
                        <option value="2">Most Downloads</option>
                        <option value="3">Featured</option>
                      </Form.Select>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {catSubCatData?.length > 1 && fliterActive && (
            <div className="custom_container">
              {/* <div className="filter_wrapper py-3"> */}
              <div className="content_filter_wrapper py-3">
                <div className="content">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Price Range</Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-md-6">
                            <ul className="ls_none ps-0">
                              <li>
                                <Form.Check
                                  value="50"
                                  name="price"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="$1 - $50"
                                />
                              </li>
                              <li>
                                <Form.Check
                                  value="100"
                                  name="price"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="$51 - $100"
                                />
                              </li>
                              <li>
                                <Form.Check
                                  value="200"
                                  name="price"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="$101 - $200"
                                />
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="ls_none ps-0">
                              <li>
                                <Form.Check
                                  value="300"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="$201 - $300"
                                />
                              </li>
                              <li>
                                <Form.Check
                                  value="400"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="$301 - $400"
                                />
                              </li>
                              <li>
                                <Form.Check
                                  value="above"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="$401 - $Above"
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="content">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>File Type</Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-md-6">
                            <ul className="ls_none ps-0">
                              <li>
                                <Form.Check
                                  value=".psd"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label=".PSD"
                                />
                              </li>
                              <li>
                                <Form.Check
                                  value=".eps"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label=".EPS"
                                />
                              </li>
                              <li>
                                <Form.Check
                                  value=".ai"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label=".AI"
                                />
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="ls_none ps-0">
                              <li>
                                <Form.Check
                                  value=".zip"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label=".Zip"
                                />
                              </li>
                              <li>
                                <Form.Check
                                  value=".png"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label=".PNG"
                                />
                              </li>
                              <li>
                                <Form.Check
                                  value=".jpg"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label=".JPG"
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="content">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Software Type</Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-md-6">
                            <ul className="ls_none ps-0">
                              <li>
                                <Form.Check
                                  value="Illustrator"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="Illustrator"
                                />
                              </li>
                              <li>
                                <Form.Check
                                  value="PhotoShop"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="PhotoShop"
                                />
                              </li>
                              <li>
                                <Form.Check
                                  value="after effect"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="After Effect"
                                />
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="ls_none ps-0">
                              <li>
                                <Form.Check
                                  value="premire"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="Premire pro"
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="content">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Browser</Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-md-6">
                            <ul className="ls_none ps-0">
                              <li>
                                <Form.Check
                                  value="Chrome"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="Chrome"
                                />
                              </li>
                              <li>
                                <Form.Check
                                  value="Mozila"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="Mozila"
                                />
                              </li>
                              <li>
                                <Form.Check
                                  value="Safari"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="Safari"
                                />
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="ls_none ps-0">
                              <li>
                                <Form.Check
                                  value="explorer"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="IE"
                                />
                              </li>
                              <li>
                                <Form.Check
                                  value="Edge"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="Edge"
                                />
                              </li>
                              <li>
                                <Form.Check
                                  value="Opera"
                                  onChange={handleBigFilter}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="Opera"
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="content">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Category</Accordion.Header>
                      <Accordion.Body>
                        <ul className="ls_none ps-0">
                          <li>
                            <Form.Check
                              value="graphics template"
                              onChange={handleBigFilter}
                              type="checkbox"
                              id="default-checkbox"
                              label="Graphics"
                            />
                          </li>
                          <li>
                            <Form.Check
                              value="Website template"
                              onChange={handleBigFilter}
                              type="checkbox"
                              id="default-checkbox"
                              label="Website"
                            />
                          </li>
                          <li>
                            <Form.Check
                              value="video template"
                              onChange={handleBigFilter}
                              type="checkbox"
                              id="default-checkbox"
                              label="video"
                            />
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          )}

          <div className="sesction_pt  sesction_pb bg-white">
            <div className="">
              <div className="custom_container ">
                {/* jodi data pay */}
                {!isLoading && !error && catSubCatData?.length > 0 && (
                  <div className="grid ">
                    {catSubCatData?.map((catSubCatData, i) => (
                      <div className="image" key={catSubCatData?.id}>
                        <Link to={`/content-details/${catSubCatData.id}`}>
                          {files.length === 0 &&
                            (catSubCatData != null &&
                            catSubCatData?.thumbnail != null ? (
                              <div className="catwise_product position-relative">
                                <div className="hover_icon_box">
                                  
                                  <AiFillHeart type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="save to favourite" size="1.1em" className="me-1" />
                                  <AiOutlineDownload data-bs-toggle="tooltip" data-bs-placement="top" title="download preview" size="1.1em" />
                                </div>
                                <p className="title mb-0">
                                  {catSubCatData?.title.slice(0, 20)}...
                                </p>
                                <img
                                  style={{
                                    height: "162.5px",
                                    width: "244px",
                                    objectFit: "cover",
                                  }}
                                  className=""
                                  src={`${fileUrl}${catSubCatData?.thumbnail}`}
                                  alt=""
                                />
                              </div>
                            ) : (
                              <img
                                className="me-2 cl_medium_grey"
                                // style={{ width: "300px", height: "200px" }}
                                src=""
                                alt=""
                              />
                            ))}

                          {/* {catSubCatData?.price} */}
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
                {/* jodi data na pay */}
                {isLoading && !error && (
                  <>
                    <div className="d-flex justify-content-center pb-5">
                      {/* <PulseLoader /> */}
                      <DotLoader />
                    </div>
                  </>
                )}

                {!isLoading && !error && catSubCatData?.length < 1 && (
                  <>
                    <div className="text-center text-danger pb-5">
                      <h1>No data Found</h1>
                    </div>
                  </>
                )}
              </div>

              {/* paginate */}
              {!isLoading && !error && catSubCatData?.length > 0 && (
                <>
                  <div className="pagination_box d-flex justify-content-center mt-5">
                    <Pagination
                      className="paggination  py-3 px-5 flex-wrap"
                      count={lastPage}
                      defaultPage={page}
                      selected={page}
                      siblingCount={1}
                      onChange={(e, value) => setPage(value)}
                    />
                  </div>
                </>
              )}

              <div className="popular_template">
                <div className="custom_container ">
                  {popularData?.length > 0 ? (
                    <div className="text-center title mb-5 sesction_mt">
                      <h2 className="fw_600 font_size_30">
                        Browse popular templates
                      </h2>
                      <p className="cl_medium_grey  font_size_24 fw_600 ">
                        Find the themes you need, pre-sized and ready to roll
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="content px-1">
                    <Slider {...settings}>
                      {popularData?.map((popu, i) => (
                        <div
                          className="border-0 rounded-0 w-100 single_team"
                          key={i}
                        >
                          <div className="team_img ">
                            {files.length === 0 &&
                            popu != null &&
                            popu?.thumbnail != null ? (
                              <Link to={`/content-details/${popu.id}`}>
                                <img
                                  style={{
                                    height: "140.5px",
                                    width: "244px",
                                    objectFit: "cover",
                                  }}
                                  className="w-100 border-0 rounded-1 mb-3"
                                  src={`${fileUrl}${popu?.thumbnail}`}
                                  alt=""
                                />
                              </Link>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CatWiseProduct;
