import React, { useState } from "react";
import "./AllBlog.css";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import Footer from "../../Components/Common/Footer/Footer";
import Header2 from "../../Components/Common/Header/Header2";
import { Link, useParams } from "react-router-dom";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../Utility/GlobalVariable/GlobalVariables";
import { useEffect } from "react";
import axios from "axios";
import { Pagination } from "@mui/material";
import { DotLoader } from "../../Components/Custom Loader/CustomLoader";

const AllBlog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [page, setPage] = useState(1);
  const [firstPage, setFirstPage] = useState([]);
  const [lastPage, setLastPage] = useState([]);

  // Initial Home data
  const [blogData, setBlogData] = useState([]);
  // Get All Info
  const renderBlogInfo = async () => {
    setIsLoading(true);
    await axios.get(`${BACKEND_BASE_URL}blogs/all?page=${page}`).then((res) => {
      setIsLoading(false);
      setError(false);
      setBlogData(res?.data?.data?.data);

      setFirstPage(res.data?.data?.from);
      setLastPage(res.data?.data?.last_page);
    });
  };
  //   console.log("vum", blogData);

  useEffect(() => {
    window.scrollTo(0, 0);
    renderBlogInfo();
    // renderCategory();
  }, [page]);

  return (
    <>
      <Header2 />
      <div className="all_Blog">
        <div className="sesction_pt  sesction_pb">
          <div className="custom_container ">
            {!isLoading && !error && blogData?.length > 0 && (
              <>
                {blogData.length > 0 && (
                  <h2 className="ms-0 font_size_25">Blogs</h2>
                )}
                <div className="grid font_size_16">
                  {blogData?.length > 0 &&
                    blogData?.map((blogData, i) => (
                      <div className="image" key={blogData?.id}>
                        <Link to={`/blog-details/${blogData.id}`}>
                          <img
                            style={{ height: "225px", objectFit: "cover" }}
                            src={`${fileUrl}${blogData?.image}`}
                            alt=""
                          />
                          <h3 className="font_size_16">{blogData?.title}</h3>
                        </Link>
                      </div>
                    ))}
                </div>
              </>
            )}
            {/* jodi data na pay */}
            {isLoading && !error && (
              <>
                <div className="d-flex justify-content-center pb-5">
                  {/* <PulseLoader /> */}
                  <DotLoader />
                </div>
              </>
            )}

            {!isLoading && !error && blogData?.length < 1 && (
              <>
                <div className="text-center text-danger pb-5">
                  <h1>No data Found</h1>
                </div>
              </>
            )}
          </div>

          {/* paginate */}
          {!isLoading && !error && blogData?.length > 0 && (
            <div className="pagination_box d-flex justify-content-center mt-5">
              <Pagination
                className="paggination  py-3 px-5 flex-wrap"
                count={lastPage}
                defaultPage={page}
                selected={page}
                siblingCount={1}
                onChange={(e, value) => setPage(value)}
              />
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AllBlog;
