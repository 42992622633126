import axios from "axios";
import React, { useRef, useState } from "react";
import {
  Button,
  Container,
  Form,
  Modal,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastSuccess } from "../../../Components/ToastAlert";
import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";
import "./UserLogin.css";

const UserLogin = () => {
  const UserEmail = useRef();
  const UserPassword = useRef();
  const [error, setError] = useState([]);
  const verification_code = useRef();

  const new_password = useRef();
  const confirm_password = useRef();

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [codeValidationStatus, setCodeValidationStatus] = useState("");

  // console.log("codeError", codeValidationStatus);

  const navigate = useNavigate();
  const location = useLocation();

  let from = location.state?.from?.pathname || "/user";

  const handleSubmit = (e) => {
    const formdata = new FormData();
    formdata.append("email", UserEmail.current.value);
    formdata.append("password", UserPassword.current.value);

    axios
      .post(`${BACKEND_BASE_URL}user-login`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      .then((response) => {
        if (response.status == 200) {
          // sessionStorage.setItem(
          //   "user_access_token",
          //   response.data?.user_access_token
          // );
          // sessionStorage.setItem("LOGGED_IN_USER_ID", response.data?.data?.id);
          // sessionStorage.setItem(
          //   "LOGGED_IN_USER_NAME",
          //   response.data?.data?.name
          // );

          document.cookie = "user_access_token=" +  response.data?.user_access_token;
          document.cookie = "LOGGED_IN_USER_ID="+ response.data?.data?.id;
          document.cookie="LOGGED_IN_USER_NAME="+ response.data?.data?.name;

          setTimeout(() => {
            navigate(from, { replace: true });
          }, 1000);

          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });

          e.target.reset();
        }
      })
      .catch((error) => {
        setMessage(error.response?.data?.message);
        setShow(true);
      });

    e.preventDefault();
  };

  const [passType, setPassType] = useState("password");
  const handlePasswordType = () => {
    if (passType == "password") {
      setPassType("text");
    }
    if (passType == "text") {
      setPassType("password");
    }
  };

  const [user_Email, setUserEmail] = useState("");
  // console.log("user_email", user_Email);

  // Modal Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");
  // Add new
  const addNewData = (modalValue) => {
    setModalSize("medium");
    setModalData(modalValue);
    setModalShow(true);
  };
  const verificationCode = (modalValue, data) => {
    setModalSize("");
    setModalSize("medium");
    setModalData(modalValue);
    setModalShow(true);
  };

  const newPass = (modalValue, data) => {
    setModalSize("");
    setModalSize("medium");
    setModalData(modalValue);
    setModalShow(true);
  };

  const submitEmail = (e) => {
    const formdata = new FormData();
    formdata.append("email", UserEmail.current.value);
    // formdata.append("password", UserPassword.current.value);
    axios
      .post(
        `${BACKEND_BASE_URL}user-forgot-password-verification-code`,
        formdata,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        if (response.data?.status == 200) {
          setTimeout(() => {
            setUserEmail(response?.data?.data);
          }, 1000);
        }
        ToastSuccess.fire({
          icon: "success",
          title: response.data?.message,
        });
        e.target.reset();
        verificationCode("Verification Code");
        // setModalShow(false);
      });
    e.preventDefault();
  };
  const submitCode = (e) => {
    const formdata = new FormData();
    formdata.append("email", user_Email);
    formdata.append("forgotPassCode", verification_code.current.value);
    axios
      .post(
        `${BACKEND_BASE_URL}user-forgot-password-verification-code-match`,
        formdata,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        if (response.data?.status == 200) {
          setCodeValidationStatus(response.data?.status);
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();

          newPass("Password Reset");
        } else {
          ToastSuccess.fire({
            icon: "warning",
            title: response.data?.message,
          });
          setCodeValidationStatus(response.data?.status);
        }

        // setModalShow(false);
      });
    e.preventDefault();
  };
  const submitNewPass = (e) => {
    const formdata = new FormData();
    formdata.append("email", user_Email);
    formdata.append("new_password", new_password.current.value);
    formdata.append("confirm_password", confirm_password.current.value);
    axios
      .post(`${BACKEND_BASE_URL}user-forgot-password`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response.data?.status == 400) {
          setError(response.data?.errors);
          // console.log("ddd", response?.data?.errors);
        } else {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();
          setModalShow(false);
          window.location.reload();
        }
      });
    e.preventDefault();
  };

  return (
    <div
      className="form_wrapper user_login"
      style={{ backgroundColor: "#f9fafb" }}>
      <Container className="container">
        <ToastContainer position="top-center" className="p-3 toast_msg">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            bg="danger"
            autohide>
            <Toast.Body>
              <strong className="ms-3">{message ? message : ""}</strong>
            </Toast.Body>
          </Toast>
        </ToastContainer>

        <Form id="form" className="form" onSubmit={handleSubmit}>
          <h1>User Login</h1>

          {/* ================== Email =================== */}
          <Form.Group className="form_group">
            <Form.Label>
              Email <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="email"
              id="email"
              placeholder="Enter email"
              name="email"
              ref={UserEmail}
            />
          </Form.Group>

          {/* ============== Password ===================== */}
          <Form.Group className="form_group pass_word position-relative">
            <Form.Label>
              Password <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type={passType}
              id="password"
              placeholder="Enter password"
              name="password"
              ref={UserPassword}
            />
            <div className="eye_icon" onClick={handlePasswordType}>
              <AiFillEye size="1.2em" />
            </div>
          </Form.Group>

          <div className="forgot_password">
            <Link>
              <h6
                onClick={() => addNewData("Forgot Password")}
                className="mt-0 mb-5"
                style={{ color: "black", underline: "none" }}>
                Forgot Password?
              </h6>
            </Link>
          </div>

          <Button type="submit" className="w-100 login_btn rounded">
            Log In
          </Button>
          <h6 className="my-3 text-center">
            <Link to="/user-registration" className={{ fontSize: "12px" }}>
              Create New Account
            </Link>
          </h6>
        </Form>
        {/* forgot pass code sent to mail modal */}
        <Modal
          show={modalShow}
          size={modalSize}
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header>
            <Modal.Title className="" id="contained-modal-title-vcenter">
              {modalData}
            </Modal.Title>
            <Button
              className="p-0 border-0 bg-danger"
              onClick={() => setModalShow(false)}>
              <CgClose
                style={{
                  fontSize: "26px",
                }}
              />
            </Button>
          </Modal.Header>
          <Modal.Body>
            {modalData === "Forgot Password" && (
              <div className="">
                <Form onSubmit={submitEmail}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3 ">
                          {message && (
                            <p className="text-danger text-center">{message}</p>
                          )}
                          <div className="login_modal ">
                            {/* ================== Email =================== */}
                            <Form.Group className="form_group">
                              <Form.Label>
                                Email <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type="email"
                                id="email"
                                placeholder="Enter email"
                                name="email"
                                ref={UserEmail}
                                // style={{ width: "500px" }}
                              />
                            </Form.Group>
                            <p className="text-center">
                              Enter your email to send verification code.
                            </p>
                            <div className="text-center p-2">
                              <Button
                                type="submit"
                                className="w-80 center login_btn rounded ps-5 pe-5 mb-5">
                                Send
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            )}

            {modalData === "Verification Code" && (
              <div className="">
                <Form onSubmit={submitCode}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3 ">
                          {message && (
                            <p className="text-danger text-center">{message}</p>
                          )}
                          <div className="login_modal ">
                            {/* ================== Email =================== */}
                            <Form.Group className="form_group">
                              <Form.Label>
                                Verification Code{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                id="VerificationCode"
                                placeholder="Enter Verification Code"
                                name="VerificationCode"
                                ref={verification_code}
                                // style={{ width: "500px" }}
                              />
                            </Form.Group>
                            <p className="text-center">
                              Enter verification code.
                            </p>
                            <div className="text-center p-2">
                              <Button
                                type="submit"
                                className="w-80 center login_btn rounded ps-5 pe-5 mb-5">
                                Send
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            )}

            {modalData === "Password Reset" && (
              <div className="">
                <Form onSubmit={submitNewPass}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3 ">
                          {message && (
                            <p className="text-danger text-center">{message}</p>
                          )}
                          <div className="login_modal ">
                            {/* ================== password =================== */}
                            <Form.Group className="form_group pass_word position-relative">
                              <Form.Label>
                                New Password{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type={passType}
                                id="new_password"
                                placeholder="Enter password"
                                name="password"
                                ref={new_password}
                              />
                              <div
                                className="eye_icon"
                                onClick={handlePasswordType}>
                                <AiFillEye size="1.2em" />
                              </div>
                            </Form.Group>
                            <div className="mb-2">
                              <small className="text-danger">
                                {error?.new_password}
                              </small>
                            </div>

                            <Form.Group className="form_group pass_word position-relative">
                              <Form.Label>
                                Confirm Password{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type={passType}
                                id="confirm_password"
                                placeholder="Enter password"
                                name="password"
                                ref={confirm_password}
                              />
                              <div
                                className="eye_icon"
                                onClick={handlePasswordType}>
                                <AiFillEye size="1.2em" />
                              </div>
                            </Form.Group>
                            <div className="mb-2">
                              <small className="text-danger">
                                {error?.confirm_password}
                              </small>
                            </div>

                            <div className="text-center p-2">
                              <Button
                                type="submit"
                                className="w-80 center login_btn rounded ps-5 pe-5">
                                Send
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

export default UserLogin;
