import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";
import { Button, Col, Form } from "react-bootstrap";
import { BACKEND_BASE_URL } from "../../../Utility/GlobalVariable/GlobalVariables";
import { Link } from "react-router-dom";

const Category = () => {
  const categoryName = useRef();
  const parentName = useRef();
  // Initial table data
  const [catTableData, setCatTableData] = useState([]);
  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/category`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setCatTableData(res.data?.data);
      });
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}admin/category/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(
              "admin_access_token"
            )}`,
          },
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  const statusUpdate = (id) => {
    axios
      .get(`${BACKEND_BASE_URL}admin/category/status-update/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: res.data?.message,
          confirmButtonColor: "#5eba86",
        });
        renderAllInfo();
      });
  };

  return (
    <div className="main__container">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="card-header">
                  <Link to="/admin/category/add">
                    <Button variant="success" size="sm">
                      Add new category &nbsp;
                      <span>
                        <AiOutlinePlusCircle />
                      </span>
                    </Button>
                  </Link>
                </div>

                <div className="table-responsive py-5">
                  <table className="table table-hover">
                    <thead>
                      <tr className="text-center">
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Parent Category Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Handle</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {catTableData?.map((data, i) => (
                        <tr key={data.id}>
                          <td> {i + 1}</td>
                          <td>{data?.name}</td>
                          {data?.parent ? (
                            <td>{data.parent.name}</td>
                          ) : (
                            <td>{}</td>
                          )}

                          <td onClick={() => statusUpdate(data.id)}>
                            {data?.status == 1 ? (
                              <AiOutlineCheckSquare
                                className="text-success pointer"
                                size={24}
                              />
                            ) : (
                              <AiOutlineCloseSquare
                                className="text-danger pointer"
                                size={24}
                              />
                            )}
                          </td>

                          <td>
                            {/* view button */}
                            {/* <button
                                                            onClick={() => showSingleData("View", data)}
                                                            className="py-1 px-2 bg-info border-0 rounded-3 me-1 mb-1"
                                                        >
                                                            <FaIcons.FaEye
                                                                style={{
                                                                    color: "white",
                                                                }}
                                                                title="View"
                                                                size="1.5em"
                                                            />
                                                        </button> */}

                            {/* edit button */}
                            <Link to={`/admin/category/edit/${data?.id}`}>
                              <button className="py-1 px-2 bg-warning border-0 rounded-3 me-1 mb-1">
                                <BiEdit
                                  style={{
                                    color: "white",
                                  }}
                                  title="Edit"
                                  size="1.5em"
                                />
                              </button>
                            </Link>

                            {/* delete button */}
                            <button
                              onClick={() => deleteData(data.id)}
                              className="py-1 px-2 bg-danger border-0 rounded-3 me-1 mb-1"
                            >
                              <MdDeleteForever
                                style={{
                                  color: "white",
                                }}
                                title="Delete"
                                size="1.5em"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
