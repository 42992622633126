import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
// import * as AiIcons from "react-icons/ai";
// import * as BiIcons from "react-icons/bi";
// import * as MdIcons from "react-icons/md";

import Swal from "sweetalert2";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../../Utility/GlobalVariable/GlobalVariables";
import {
  AiOutlineCheckSquare,
  AiOutlineCloseSquare,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";

const AdminNavbar = () => {
  // Initial table data
  const [tableData, setTableData] = useState([]);
  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/navbar-items`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.data);
      });
  };
  //status update
  const statusUpdate = (id) => {
    axios
      .get(`${BACKEND_BASE_URL}admin/navbar-items/status-update/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "admin_access_token"
          )}`,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: res.data?.message,
          confirmButtonColor: "#5eba86",
        });
        renderAllInfo();
      });
  };
  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}admin/navbar-items/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(
              "admin_access_token"
            )}`,
          },
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="card-header">
                  <Link to="/admin/nav-items/add">
                    <Button variant="success" size="sm">
                      Add New Nav Item &nbsp;
                      <span>
                        <AiOutlinePlusCircle />
                      </span>
                    </Button>
                  </Link>
                </div>

                <div className="table-responsive py-5">
                  <table className="table table-hover">
                    <thead>
                      <tr className="text-center">
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Image</th>
                        <th scope="col">Status</th>
                        <th scope="col">Handle</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {tableData?.map((data, i) => (
                        <tr key={data.id}>
                          <td> {i + 1}</td>
                          <td>{data?.name}</td>
                          <td>
                            <img
                              className="img-thumbnail"
                              width={80}
                              height={50}
                              src={`${fileUrl}${data?.image}`}
                            />
                          </td>
                          {/* status update */}
                          <td onClick={() => statusUpdate(data.id)}>
                            {data?.status == 1 ? (
                              <AiOutlineCheckSquare
                                className="text-success pointer"
                                size={24}
                              />
                            ) : (
                              <AiOutlineCloseSquare
                                className="text-danger pointer"
                                size={24}
                              />
                            )}
                          </td>

                          <td>
                            {/* edit button */}
                            <Link to={`/admin/nav-items/edit/${data?.id}`}>
                              <button className="py-1 px-2 bg-warning border-0 rounded-3 me-1 mb-1">
                                <BiEdit
                                  style={{
                                    color: "white",
                                  }}
                                  title="Edit"
                                  size="1.5em"
                                />
                              </button>
                            </Link>

                            {/* delete button */}
                            <button
                              onClick={() => deleteData(data.id)}
                              className="py-1 px-2 bg-danger border-0 rounded-3 me-1 mb-1"
                            >
                              <MdDeleteForever
                                style={{
                                  color: "white",
                                }}
                                title="Delete"
                                size="1.5em"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
