import React, { Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loader from "./Utility/Loader/Loader";
import ProductDetails from "./Pages/ProductDetails/ProductDetails";
import AdminLogin from "./Pages/Admin/AdminLogin/AdminLogin";
import AdminPrivateRoute from "./Pages/PrivateRoute/AdminPrivateRoute";
// import Admin from "./Pages/Admin/admin";
import Admin from "./Pages/Admin/Admin";
import AdminDashboard from "./Pages/Admin/Dashboard/AdminDashboard";
import Profile from "./Pages/Admin/Profile";
import VendorRegistration from "./Pages/Vendor/VendorLogin/VendorRegistration";
import CreateShop from "./Pages/Vendor/CreateShop/CreateShop";
import VendorPrivateRoute from "./Pages/PrivateRoute/VendorPrivateRoute";
import Vendor from "./Pages/Vendor/Vendor";
import VendorLogin from "./Pages/Vendor/VendorLogin/VendorLogin";
import UserRegistration from "./Pages/User/UserLogin/UserRegistration";
import UserLogin from "./Pages/User/UserLogin/UserLogin";
import UserPrivateRoute from "./Pages/PrivateRoute/UserPrivateRoute";
import User from "./Pages/User/User";
import VendorProfile from "./Pages/Vendor/VendorProfile";
import UserProfile from "./Pages/User/UserProfile";
import Category from "./Pages/Admin/Category/Category";
import AdminCategoryAdd from "./Pages/Admin/Category/AdminCategoryAdd";
import AdminCategoryEdit from "./Pages/Admin/Category/AdminCategoryEdit";
import AdminFileType from "./Pages/Admin/FileType/AdminFileType";
import AdminFileTypeAdd from "./Pages/Admin/FileType/AdminFileTypeAdd";
import AdminProduct from "./Pages/Admin/Product/AdminProduct";
import AdminProductAdd from "./Pages/Admin/Product/AdminProductAdd";
import AdminProductEdit from "./Pages/Admin/Product/AdminProductEdit";
import AdminProductView from "./Pages/Admin/Product/AdminProductView";
import VandorDashboard from "./Pages/Vendor/VendorDashboard/VandorDashboard";
import VendorProductAdd from "./Pages/Vendor/VendorProduct/VendorProductAdd";
import CatWiseProduct from "./Pages/Product/CatWiseProduct";
import VendorProductType from "./Pages/Vendor/VendorProduct/VendorProductType";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Wishlist from "./Pages/Wishlist/Wishlist";
import AdminNavbar from "./Pages/Admin/Navbar/AdminNavbar";
import AdminNavbarItemAdd from "./Pages/Admin/Navbar/AdminNavbarItemAdd";
import NavItemEdit from "./Pages/Admin/Navbar/NavItemEdit";
import AdminBlog from "./Pages/Admin/Blog/AdminBlog";
import AdminBlogAdd from "./Pages/Admin/Blog/AdminBlogAdd";
import AllBlog from "./Pages/HomeBlog/AllBlog";
import BlogDetails from "./Pages/HomeBlog/BlogDetails";
import SigninForDownloadFile from "./Pages/ProductDetails/SigninForDownloadFile";
import SearchedProduct from "./Pages/SearchedProduct/SearchedProduct";
import ContentCategory from "./Pages/Admin/ContentCategory/ContentCategory";
import ContentCategoryAdd from "./Pages/Admin/ContentCategory/ContentCategoryAdd";
import ContentCategoryEdit from "./Pages/Admin/ContentCategory/ContentCategoryEdit";
import ContentSubCategory from "./Pages/Admin/ContentSubCategory/ContentSubCategory";
import ContentSubCategoryAdd from "./Pages/Admin/ContentSubCategory/ContentSubCategoryAdd";
import ContentSubCategoryEdit from "./Pages/Admin/ContentSubCategory/ContentSubCategoryEdit";
import ContentChildCategory from "./Pages/Admin/ContentChildCategory/ContentChildCategory";
import ContentChildCategoryAdd from "./Pages/Admin/ContentChildCategory/ContentChildCategoryAdd";
import ContentChildCategoryEdit from "./Pages/Admin/ContentChildCategory/ContentChildCategoryEdit";
import ContentType from "./Pages/Admin/Content/ContentType";
import GraphicsTemplateAdd from "./Pages/Admin/Content/GraphicsTemplateAdd";
import WebTemplateAdd from "./Pages/Admin/Content/WebTemplateAdd";
import VideoTemplateAdd from "./Pages/Admin/Content/VideoTemplateAdd";
import AdminBlogEdit from "./Pages/Admin/Blog/AdminBlogEdit";
import GraphicsTemplateEdit from "./Pages/Admin/Content/GraphicsTemplateEdit";
import WebTemplateEdit from "./Pages/Admin/Content/WebTemplateEdit";
import AllContent from "./Pages/Admin/Content/AllContent";
import VideoTemplateEdit from "./Pages/Admin/Content/VideoTemplateEdit";
import AllSubCategory from "./Pages/AllSubCategory/AllSubCategory";
import UserProfileDashboard from "./Pages/User/User Profile Dashboard/UserProfileDashboard";
import UserProfile2 from "./Pages/User/User Profile Dashboard/UserProfile";
import UserProfileEdit from "./Pages/User/User Profile Dashboard/UserProfileEdit";
import UserPassChange from "./Pages/User/User Profile Dashboard/UserPassChange";
import NotificationSettings from "./Pages/User/User Profile Dashboard/NotificationSettings";
import UserReceipts from "./Pages/User/User Profile Dashboard/UserReceipts";
import ReceiptDetails from "./Pages/User/User Profile Dashboard/ReceiptDetails";
import UserFavoriteContents from "./Pages/User/User Profile Dashboard/UserFavoriteContents";
import UsersDownloads from "./Pages/User/User Profile Dashboard/UsersDownloads";
import EmailVerification from "./Pages/User/UserLogin/EmailVerification";

const Home = React.lazy(() => import("./Pages/Home/Home"));
const Product = React.lazy(() => import("./Pages/Product/Product"));

function App() {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/searched-product" element={<SearchedProduct />} />

          <Route path="/subcategory-list" element={<AllSubCategory />} />

          <Route path="/product" element={<Product />} />
          <Route path="/content/:slug" element={<CatWiseProduct />} />
          <Route
            path="/content-details/:content_id"
            element={<ProductDetails />}
          />
          <Route
            path="/sign-in-for-download-file"
            element={<SigninForDownloadFile />}
          />

          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/blogs" element={<AllBlog />} />
          <Route path="/blog-details/:blogId" element={<BlogDetails />} />

          {/*===================== Admin Dashboard ===========================*/}
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route
            path="admin"
            element={
              <AdminPrivateRoute>
                <Admin />
              </AdminPrivateRoute>
            }
          >
            <Route index element={<AdminDashboard />} />
            <Route path="profile" element={<Profile />} />

            <Route path="blogs" element={<AdminBlog />} />
            <Route path="blogs/add" element={<AdminBlogAdd />} />
            <Route path="blogs/edit/:blogId" element={<AdminBlogEdit />} />

            <Route path="nav-items" element={<AdminNavbar />} />
            <Route path="nav-items/add" element={<AdminNavbarItemAdd />} />
            <Route path="nav-items/edit/:navItemId" element={<NavItemEdit />} />

            <Route path="content-category" element={<ContentCategory />} />
            <Route
              path="content-category/add"
              element={<ContentCategoryAdd />}
            />
            <Route
              path="content-category/edit/:catId"
              element={<ContentCategoryEdit />}
            />
            <Route
              path="content-sub-category"
              element={<ContentSubCategory />}
            />
            <Route
              path="content-sub-category/add"
              element={<ContentSubCategoryAdd />}
            />
            <Route
              path="content-sub-category/edit/:subCatId"
              element={<ContentSubCategoryEdit />}
            />

            <Route
              path="content-child-category"
              element={<ContentChildCategory />}
            />
            <Route
              path="content-child-category/add"
              element={<ContentChildCategoryAdd />}
            />
            <Route
              path="content-child-category/edit/:childCatId"
              element={<ContentChildCategoryEdit />}
            />

            <Route path="content/category" element={<ContentType />} />
            <Route
              path="content/graphics-template/upload/:content_cat_id"
              element={<GraphicsTemplateAdd />}
            />
            <Route
              path="content/graphics-template/update/:contentId/:content_cat_id"
              element={<GraphicsTemplateEdit />}
            />

            <Route
              path="content/web-template/upload/:content_cat_id"
              element={<WebTemplateAdd />}
            />
            <Route
              path="content/web-template/update/:contentId/:content_cat_id"
              element={<WebTemplateEdit />}
            />
            <Route
              path="content/video-template/upload/:content_cat_id"
              element={<VideoTemplateAdd />}
            />
            <Route
              path="content/video-template/update/:contentId/:content_cat_id"
              element={<VideoTemplateEdit />}
            />

            <Route path="category" element={<Category />} />
            <Route path="category/add" element={<AdminCategoryAdd />} />
            <Route
              path="category/edit/:catId"
              element={<AdminCategoryEdit />}
            />
            {/* <Route path="all-contents" element={<AdminProduct />} /> */}
            <Route path="all-contents" element={<AllContent />} />
            <Route path="product/add" element={<AdminProductAdd />} />
            <Route
              path="product/view/:content_id"
              element={<AdminProductView />}
            />
            <Route
              path="product/edit/:content_id"
              element={<AdminProductEdit />}
            />

            <Route path="file-type" element={<AdminFileType />} />
            <Route path="file-type/add" element={<AdminFileTypeAdd />} />
          </Route>

          {/*===================== Vendor Dashboard ===========================*/}
          <Route path="/vendor-registration" element={<VendorRegistration />} />
          <Route path="/vendor/login" element={<VendorLogin />} />
          <Route path="/vendor/create-shop" element={<CreateShop />} />
          <Route
            path="vendor"
            element={
              <VendorPrivateRoute>
                <Vendor />{" "}
              </VendorPrivateRoute>
            }
          >
            <Route index element={<VandorDashboard />} />

            <Route path="content/type" element={<VendorProductType />} />
            <Route path="content/upload/" element={<VendorProductAdd />} />
            <Route path="profile" element={<VendorProfile />} />
          </Route>

          {/*===================== user Dashboard ===========================*/}

          <Route path="/user-registration" element={<UserRegistration />} />
          <Route
            path="/user-email-verification"
            element={<EmailVerification />}
          />
          <Route path="/user/login" element={<UserLogin />} />
          <Route
            path="/user/"
            element={
              <UserPrivateRoute>
                <User />{" "}
              </UserPrivateRoute>
            }
          >
            <Route index element={<UserProfileDashboard />} />
            <Route path="edit-profile" element={<UserProfileEdit />} />
            <Route path="change-password" element={<UserPassChange />} />
            <Route
              path="notification-settings"
              element={<NotificationSettings />}
            />
            <Route path="receipts" element={<UserReceipts />} />
            <Route path="receipts/:receiptId" element={<ReceiptDetails />} />
          </Route>
          <Route
            path="user/favorite-contents"
            element={<UserFavoriteContents />}
          />
          <Route path="user/downloads" element={<UsersDownloads />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
