// import { CircularProgress } from "@mui/material";
import axios from "axios";
import "./userProfileDashboard.css";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DotLoader } from "../../../Components/Custom Loader/CustomLoader";
import {
  BACKEND_BASE_URL,
  fileUrl,
} from "../../../Utility/GlobalVariable/GlobalVariables";
import getCookie from "../../../Components/Cookie/Get.Cookie";
// import { DotLoader } from "../Component/Custom Loader/CustomLoader";
// import { BACKEND_BASE_URL } from "../Component/GlobalVariables";

const UserProfileDashboard = () => {
  const [userInfo, setUserInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  const [profileInfo, setProfileInfo] = useState([]);
  // Get user Info
  const renderUserInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}user/profile`, {
        headers: {
          Authorization: `Bearer ${getCookie(
            "user_access_token"
          )}`,
        },
      })
      .then((res) => {
        setProfileInfo(res.data?.data);
      });
  };
  useEffect(() => {
    renderUserInfo();
  }, []);

  return (
    <div className="user_dashboard_content p-4 p-lg-5">
      <h1 className="mb-5 ms-3 ms-lg-0">Account Overview</h1>
      <h3 className="mb-4">Profile</h3>

      <div>
        {!isLoading && (
          <>
            <Form.Group className="d-flex align-items-center form_group">
              <Form.Label className="w-50 mb-0">Username</Form.Label>
              <Form.Control
                readOnly
                className="w-50"
                type="text"
                placeholder="Username"
                defaultValue={profileInfo?.name}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center form_group">
              <Form.Label className="w-50 mb-0">Email</Form.Label>
              <Form.Control
                readOnly
                className="w-50"
                type="text"
                placeholder="Email"
                defaultValue={profileInfo?.email}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center form_group">
              <Form.Label className="w-50 mb-0">Profile Photo</Form.Label>
              <div>
                {files.length === 0 &&
                profileInfo != null &&
                profileInfo?.profile_photo != null ? (
                  <img
                    src={`${fileUrl}${profileInfo?.profile_photo}`}
                    alt="Profile"
                    className="img-fluid"
                    width={80}
                  />
                ) : (
                  <img
                    src={require("../../../Assets/avatar.png")}
                    alt=""
                    className="img-fluid"
                    width={80}
                  />
                )}
              </div>
            </Form.Group>
            <Form.Group className="d-flex align-items-center form_group">
              <Form.Label className="w-50 mb-0">Phone Number</Form.Label>
              <Form.Control
                readOnly
                className="w-50"
                type="text"
                placeholder="phone"
                defaultValue={profileInfo?.phone}
              />
            </Form.Group>
          </>
        )}
      </div>

      {isLoading && (
        <div className="flex_center" style={{ minHeight: "365px" }}>
          <DotLoader />
        </div>
      )}

      <div className="edit_profile_btn mt-4 mb-5">
        <button>
          <Link to="edit-profile">Edit Profile</Link>
        </button>
      </div>

      {/* <h3 className="mb-4">Sign out everywhere</h3>
      <div className="note mb-4">
        Note: It can take up to 1 hour for sign out to take effect on the web
        player.
      </div>
      <div className="signout_everywhere_btn">
        <button>Sign out everywhere</button>
      </div> */}
    </div>
  );
};

export default UserProfileDashboard;
